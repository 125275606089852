<volvo-contact-skeleton *ngIf="isLoading"></volvo-contact-skeleton>
<section class="truckCentre">
    <div class="truckCentre">
        <img [src]="assetService.getAssetUrl('assets/pages/contactus/Enfield.webp')" [hidden]="isLoading" (load)="onImageLoad()" (error)="onError()" class="truckCentre-centreImg">
    </div>
</section>
<div [hidden]="isLoading">
    <section class="banburryCentre">
        <div class="banburryCentre-title">
            <h1>
                {{"uk/pages/contact/enfieldTruckCentre" | lowercase | translate}}
            </h1>
        </div>
        <div class="banburryCentre-text">
            {{"uk/pages/contact/enfieldText" | lowercase | translate}}
        </div>
    </section>
    <section class="banburryCentre">
        <div class="banburryCentre-flex">
            <div class="banburryCentre-salesTeam">
                <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
            </div>
            <div class="banburryCentre-map">
                <volvo-map-centre [centre]="mapCentre"></volvo-map-centre>
            </div>
        </div>
    </section>
</div>
    <volvo-footer></volvo-footer>
