<div *ngIf="truck" [class.isNotPage]="!isPage">
  <section class="truckPage" *ngIf="truck">
    <div layout="column">
      <section class="StockItemA">
        <div></div>
      </section>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
        <section class="StockItemB">
          <div fxFlex="20%" class="flexTruckWidth">
            <div class="ContentWp-left print-image" id="ContentWp-left">
              <volvo-thumb-slider *ngIf="truck.pictures" [offer]="truck?.advertisementCategory"
                [slides]="truck.pictures" [videos]="truck.videos" [truckStatus]="truck.isDeleted">
              </volvo-thumb-slider>
            </div>
            <div layout="column">
              <div class="approvedContent">
                <div class="BestTruck-wrap" *ngIf="
                          truck?.specification.vehicleHighlights?.selectedPlus?.selectedPlusHeading ||
                          truck?.specification.vehicleHighlights?.financing?.financingHeading ||
                          truck?.specification.vehicleHighlights?.lowMileage?.lowMileageHeading ||
                          truck?.specification.vehicleHighlights?.warranty?.warrantyHeading
                        ">
                  <div class="BestTruck-items">
                    <drag-scroll #slider>
                      <div drag-scroll-item dragitem>
                        <div class="Info">
                          <div *ngIf="truck?.specification.vehicleHighlights?.selectedPlus?.selectedPlusHeading"
                            class="Info-Item">
                            <div class="Info-Icon">
                              <img src="assets/svg/icons/Volvo Selected Pro.png" matTooltip="{{
                                        (truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusText | lookup).length != 0
                                          ? (truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusText | lookup)
                                          : truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusText.engPhrase
                                      }}" />
                            </div>
                            <div class="Info-Text">
                              <div class="Info-Title">
                                {{ (truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusHeading |
                                lookup).length != 0
                                ? (truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusHeading
                                | lookup) :
                                truck?.specification.vehicleHighlights?.selectedPlus.selectedPlusHeading.engPhrase }}
                              </div>
                            </div>
                          </div>
                          <div *ngIf="truck?.specification.vehicleHighlights?.financing?.financingHeading"
                            class="Info-Item">
                            <div class="Info-Icon">
                              <img src="assets/svg/icons/Financing.png" matTooltip="{{
                                        (truck?.specification.vehicleHighlights?.financing.financingText | lookup).length != 0
                                          ? (truck?.specification.vehicleHighlights?.financing.financingText | lookup)
                                          : truck?.specification.vehicleHighlights?.financing.financingText.engPhrase
                                      }}" />
                            </div>
                            <div class="Info-Text">
                              <div class="Info-Title">
                                {{ (truck?.specification.vehicleHighlights?.financing.financingHeading | lookup).length
                                != 0 ?
                                (truck?.specification.vehicleHighlights?.financing.financingHeading
                                | lookup) : truck?.specification.vehicleHighlights?.financing.financingHeading.engPhrase
                                }}
                              </div>
                            </div>
                          </div>
                          <div *ngIf="truck?.specification.vehicleHighlights?.lowMileage?.lowMileageHeading"
                            class="Info-Item">
                            <div class="Info-Icon">
                              <img src="assets/svg/icons/Low Mileage.png" matTooltip="{{
                                        (truck?.specification.vehicleHighlights?.lowMileage.lowMileageText | lookup).length != 0
                                          ? (truck?.specification.vehicleHighlights?.lowMileage.lowMileageText | lookup)
                                          : truck?.specification.vehicleHighlights?.lowMileage.lowMileageText.engPhrase
                                      }}" />
                            </div>
                            <div class="Info-Text">
                              <div class="Info-Title">
                                {{ (truck?.specification.vehicleHighlights?.lowMileage.lowMileageHeading |
                                lookup).length != 0 ?
                                (truck?.specification.vehicleHighlights?.lowMileage.lowMileageHeading
                                | lookup) :
                                truck?.specification.vehicleHighlights?.lowMileage.lowMileageHeading.engPhrase }}
                              </div>
                            </div>
                          </div>
                          <div *ngIf="truck?.specification.vehicleHighlights?.warranty?.warrantyHeading"
                            class="Info-Item">
                            <div class="Info-Icon">
                              <img src="assets/svg/icons/Warranty.png" matTooltip="{{
                                        (truck?.specification.vehicleHighlights?.warranty.warrantyText | lookup).length != 0
                                          ? (truck?.specification.vehicleHighlights?.warranty.warrantyText | lookup)
                                          : truck?.specification.vehicleHighlights?.warranty.warrantyText.engPhrase
                                      }}" />
                            </div>
                            <div class="Info-Text">
                              <div class="Info-Title">
                                {{ (truck?.specification.vehicleHighlights?.warranty.warrantyHeading | lookup).length !=
                                0 ?
                                (truck?.specification.vehicleHighlights?.warranty.warrantyHeading
                                | lookup) : truck?.specification.vehicleHighlights?.warranty.warrantyHeading.engPhrase
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </drag-scroll>
                  </div>

                </div>

              </div>
              <div class="approvedContent" *ngIf="isSelectedSite">
                <div class="approvedOffer-left" *ngIf="!disableVFS">
                  <volvo-truck-page-lease [truck]="truck"
                    (sliderValueChange)="onSliderChange($event)"></volvo-truck-page-lease>
                </div>
                <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMap"
                  *ngIf="!disableVFS"></volvo-truck-page-dealership>
                <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMapFull"
                  *ngIf="disableVFS"></volvo-truck-page-dealership>
              </div>
              <!--UK,NS,TX code starts here for Showing offer details,VFS,Blue contract and Map-->
              <div layout="column" *ngIf="!disableVFS  && !isSelectedSite">
                <div class="approvedContent" *ngIf="region!==regionEnum.TX">
                  <div class="approvedOffer">
                    <div *ngIf="truck?.advertisementCategory=='Approved'" class="Table-cell" data-cy="truckpage-horsepower">
                      <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-12-icon.webp')" alt="" />
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/approvedoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem4' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem7' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem8' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem9' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                        
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/approvedoffertext' | lowercase | translate }}
                      </span>
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Selected'" class="Table-cell" data-cy="truckpage-horsepower">
                      <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-24-icon.webp')" alt="" />
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/selectedoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem4' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem7' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem8' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem9' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/selectedoffertext' | lowercase | translate }}
                      </span>
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Remaining Warranty'" class="Table-cell"
                      data-cy="truckpage-horsepower">
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/remainingoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 3rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem3' | lowercase | translate }}</li>
                        <!-- <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem4' | lowercase | translate }}</li> -->
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem7' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem8' | lowercase | translate }}</li>
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/remainingoffertext' | lowercase | translate }}
                      </span>
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Economy'" class="Table-cell" data-cy="truckpage-horsepower">
                      <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-6-icon.webp')" alt="" />
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/economyoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem4' | lowercase | translate }} </li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/economyoffertext' | lowercase | translate }}
                      </span>
                    </div>
                  </div>
                  <div class="approvedOffer-right">
                    <volvo-truck-page-lease [truck]="truck" (sliderValueChange)="onSliderChange($event)"></volvo-truck-page-lease>
                  </div>
                </div>
                <div class="approvedContent" [ngClass]="region!==regionEnum.TX?'':'displayblock'">
                  <div class="approvedOffer-blueContact" *ngIf="region!==regionEnum.TX">
                    <h6 class="approvedOffer-subHeader" style="color: #ffffff;">{{ 'uk/truck/bluecontract' | lowercase | translate }}</h6>
                    <ul>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem1' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem2' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem3' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem4' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem5' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem6' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem7' | lowercase | translate }}</li>
                    </ul>
                    <mat-divider class="approvedOffer-matDivider"></mat-divider>
                    <div class="Table-box isEvenWithBg">
                      <div class="Table-row priceColor" style="margin-bottom: 3rem;">
                        <div class="Table-cell">{{ 'uk/truck/bluecontract/weeklyprice' | lowercase | translate }}:</div>
                        <div class="Table-cell">
                          £ 85.00 *
                        </div>
                      </div>
                    </div>
                    <span class="approvedOffer-subText">
                      * {{ 'uk/truck/bluecontract/indicativeprice' | lowercase | translate }}
                    </span>
                  </div>
                  <volvo-truck-page-dealership [truck]="truck" id="location" [ngClass]="region === regionEnum.TX? 'txMap' : 'dealershipMap'"></volvo-truck-page-dealership>
  
                </div>
              </div>
              <!-- without vfs -->
              <div layout="column" *ngIf="disableVFS  && !isSelectedSite">
                <div class="approvedContent" *ngIf="region!==regionEnum.TX">
                  <div class="approvedOffer">
                    <div *ngIf="truck?.advertisementCategory=='Approved'" class="Table-cell" data-cy="truckpage-horsepower">
                      <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-12-icon.webp')" alt="" />
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/approvedoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem4' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem7' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem8' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem9' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/approvedoffertext' | lowercase | translate }}
                      </span>
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Selected'" class="Table-cell" data-cy="truckpage-horsepower">
                      <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-24-icon.webp')" alt="" />
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/selectedoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem4' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem7' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem8' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem9' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/selectedoffertext' | lowercase | translate }}
                      </span>
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Remaining Warranty'" class="Table-cell"
                      data-cy="truckpage-horsepower">
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/remainingoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 3rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem3' | lowercase | translate }}</li>
                        <!-- <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem4' | lowercase | translate }}</li> -->
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem7' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem8' | lowercase | translate }}</li>
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/remainingoffertext' | lowercase | translate }}
                      </span>
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Economy'" class="Table-cell" data-cy="truckpage-horsepower">
                      <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-6-icon.webp')" alt="" />
                      <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/economyoffer' | lowercase | translate }}</h6>
                      <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem4' | lowercase | translate }} </li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                      </ul>
                      <span class="approvedOffer-subTextofferContract">
                        {{ 'uk/truck/offer/economyoffertext' | lowercase | translate }}
                      </span>
                    </div>
                  </div>
                  <div class="approvedOffer-blueContact" style="margin-left: 1rem;">
                    <h6 class="approvedOffer-subHeader" style="color: #ffffff;">{{ 'uk/truck/bluecontract' | lowercase | translate }}</h6>
                    <ul>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem1' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem2' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem3' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem4' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem5' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem6' | lowercase | translate }}</li>
                      <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem7' | lowercase | translate }}</li>
                    </ul>
                    <mat-divider class="approvedOffer-matDivider"></mat-divider>
                    <div class="Table-box isEvenWithBg">
                      <div class="Table-row priceColor" style="margin-bottom: 3rem;">
                        <div class="Table-cell">{{ 'uk/truck/bluecontract/weeklyprice' | lowercase | translate }}:</div>
                        <div class="Table-cell">
                          £ 85.00 *
                        </div>
                      </div>
                    </div>
                    <span class="approvedOffer-subText">
                      * {{ 'uk/truck/bluecontract/indicativeprice' | lowercase | translate }}
                    </span>
                  </div>
                </div>
                <div class="approvedContent" style="margin-top: 2rem;">
                  <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMap" style="width: 100%; margin-left:0rem;"></volvo-truck-page-dealership>
                </div>
              </div>

              <!--UK,NS,TX code starts here for Showing offer details,VFS,Blue contract and Map-->
            </div>
          </div>
          <div fxFlex="20%" class="flexTruckDetails">
            <div class="ContentWp-right StockItemB-right">
              <div class="StockItemB-info">
                <div class="StockItemB-infoWp">
                  <div class="StockItemB-infoHeader">
                    <div class="StockItemB-infoHeaderTitle">
                      {{ truck.specification.make | hasValue }} {{ truck.specification.model | hasValue }}
                    </div>
                    <div *ngIf="!isPhone" class="StockItemA-btns">
                      <img (click)="onPrintPage()" class="iconSize" [src]="assetService.getAssetUrl('assets/svg/icons/Print.svg')" />
                      <img (click)="onShareTruck()" class="iconShare" [src]="assetService.getAssetUrl('assets/svg/icons/Share.svg')" />

                    </div>
                  </div>
                  
                  <h6 *ngIf="truck.specification.advertisementTitle" class="StockItemB-infoSubHeader">
                    {{ truck.specification.advertisementTitle | hasValue }}
                  </h6>
                  <div class="StockItemB-infoBox" *ngIf="isSelectedSite">
                    <div>
                      <div *ngIf="!truck.isDeleted" class="StockItemB-infoPrice">
                        <span *ngIf="truck?.stockLocation?.region === 'ZA'; else others">
                          <span *ngIf="truck.ShowPrice && this.curLang.id != 'de'">{{ truck.pricing?.priceExclVat?.value
                            | number: '1.0-0':'de' }} R
                          </span>

                          <span *ngIf="truck.ShowPrice && this.curLang.id == 'de'">{{ truck.pricing?.priceExclVat?.value
                            | number: '1.0-0':'de' }} R
                          </span>
                        </span>
                        <ng-template #others>
                          <span *ngIf="truck.ShowPrice && this.curLang.id != 'de'">{{ truck.pricing?.priceExclVatEuro |
                            number: '1.0-0':'de' }} €
                            <span class="volvofont">
                              <i matTooltip="The price can alternate depending the daily rate."
                                class="fas fa-info-circle"></i>
                            </span>
                          </span>

                          <span *ngIf="truck.ShowPrice && this.curLang.id == 'de'">{{ truck.pricing?.priceExclVatEuro |
                            number: '1.0-0':'de' }} €
                            <span class="volvofont">
                              <i matTooltip="The price can alternate depending the daily rate."
                                class="fas fa-info-circle"></i>
                            </span>
                          </span>
                        </ng-template>
                        <span *ngIf="!truck.ShowPrice" class="askprice">{{ 'truck/askPrice' | lowercase | translate }}
                        </span>
                        <span *ngIf="truck.ShowPrice" class="StockItemB-infoVat">{{ 'truck/vat' | lowercase | translate
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      *ngIf="truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR && truck.ShowPrice && !truck.isDeleted && isPhone"
                      class="StockItemB-localPrice StockItemB-infoLocation">
                      <span>{{ truck.pricing?.priceExclVat?.value | number: '1.0-0':'de' }}
                        {{
                        truck.pricing?.priceExclVat?.currencyIsoCode3
                        }}</span>
                    </div>
                    <div *ngIf="!isPhone" volvoScrollTo="location" class="StockItemB-infoLocation">
                      <i class="fas fa-map-marker-alt"></i>
                      <div class="StockItemB-infoLocationTitle">
                        <span>{{ truck?.stockLocation?.countryCodeISO2 ? (truck?.stockLocation?.countryCodeISO2 |
                          lookupcountry) :
                          (truck?.stockLocation?.country | hasValue) }} - {{ truck?.stockLocation.city | hasValue }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="isSelectedSite && truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR && truck.ShowPrice && !truck.isDeleted && !isPhone"
                    class="StockItemB-localPrice">
                    <span>Price in local currency: {{ truck.pricing?.priceExclVat?.value | number: '1.0-0':'de' }}
                      {{
                      truck.pricing?.priceExclVat?.currencyIsoCode3
                      }}</span>
                  </div>
                   <!--UK, NS and TX advertisement warranty starts here-->
                  <div class="Table-row selectedTableRow" *ngIf="!isSelectedSite">
                    <div *ngIf="truck.advertisementCategory=='Remaining Warranty'" class="Table-cell selectedHeading">
                      <h6 *ngIf="!truck.specification.advertisementTitle" class="StockItemB-infoSubHeader">
                      {{ truck?.specification.axleConfiguration | lookup }} {{ truck?.specification.horsePower | hasValue
                      }} {{ truck?.specification.category | hasValue }}
                      </h6>
                      <p *ngIf="region!==regionEnum.TX">{{truck?.advertisementCategory}}</p>
                    </div>
                    <div *ngIf="truck.advertisementCategory!='Remaining Warranty'" class="Table-cell selectedHeading"><h6 *ngIf="!truck.specification.advertisementTitle" class="StockItemB-infoSubHeader">
                      {{ truck?.specification.axleConfiguration | lookup }} {{ truck?.specification.horsePower | hasValue
                      }} {{ truck?.specification.category | hasValue }}
                    </h6><p *ngIf="region!==regionEnum.TX">Volvo
                      {{truck?.advertisementCategory}}</p></div>
                  </div>
                  <!--UK and TX advertisement warranty contact ends here-->  
                </div>
                
                <!--UK and TX sales person contact starts here-->
                <div class="blueContactWp" *ngIf="region===regionEnum.UK || region===regionEnum.TX">
                  <a [href]="region===regionEnum.UK? truck.advertisementCategory === 'Economy'? 'tel:0844 417 9533' :'tel:0844 417 9522':'tel:+44 844 579 1222'"class="blueContactButton whiteContactButton" (click)="togglePhone()">
                    <i class="fas fa-phone-alt Colordark phonePadding contactIconSize"></i>
                    <div id="phId" class="itemPhone hideNumber" *ngIf="!isPhone">{{region===regionEnum.UK? truck.advertisementCategory === 'Economy'? ("0844 417 9533") : ("0844 417 9522"):("+44 844 579 1222") }}</div>
                    <p *ngIf="isPhone">{{'uk/form/call' | lowercase | translate}}</p>
                  </a>
                  <div (click)="onContactsClick()" class="blueContactButton hoverEffects">
                    <i class="fal fa-envelope CPrimary contactIconSize"></i><p>{{'uk/form/sendMessage' | lowercase | translate}}</p>
                  </div>
                </div>
                <!--UK and TX sales person contact ends here-->
                <div *ngIf="isPhone && isSelectedSite" volvoScrollTo="location" class="StockItemB-infoLocation">
                  <i class="fas fa-map-marker-alt"></i>
                  <div class="StockItemB-infoLocationTitle">
                    <span>{{ truck?.stockLocation?.countryCodeISO2 ? (truck?.stockLocation?.countryCodeISO2 |
                      lookupcountry) :
                      (truck?.stockLocation?.country | hasValue) }} - {{ truck?.stockLocation.city | hasValue }}
                    </span>
                  </div>
                </div>
                <div style="display:flex;justify-content: end;">
                  <div class="shareIcons" *ngIf="isPhone && !isShow">
                    <div>
                      <a href="mailto:?subject=You might be interested in this truck&body={{truckUrl}}">
                        <img [src]="assetService.getAssetUrl('assets/pages/popups/Mail.svg')" alt="Mail" class="shareImage"/>
                      </a>
                    </div>
                    <div (click)="shareFaceBook()">
                      <img [src]="assetService.getAssetUrl('assets/pages/popups/Facebook.svg')" alt="Facebook" class="shareImage"/>
                    </div>
                    <div (click)="shareTwitter()">
                      <img [src]="assetService.getAssetUrl('assets/pages/popups/Twitter.svg')" alt="Twitter" class="shareImage"/>
                    </div>
                    <div (click)="shareWhatsApp()">
                      <img [src]="assetService.getAssetUrl('assets/pages/popups/WhatsApp.svg')" alt="WhatsApp" class="shareImage"/>
                    </div>
                    <div (click)="shareLinkedIn()">
                      <img [src]="assetService.getAssetUrl('assets/pages/popups/LinkedIn.svg')" alt="LinkedIn" class="shareImage"/>
                    </div>
                    <div> <img [src]="assetService.getAssetUrl('assets/pages/popups/Close.svg')" (click)="onShareTruckMobile()" alt="Close"/></div>
                  </div>
                  <div class="StockItemB-contactLinks">
                    <img  *ngIf="isPhone && isShow" (click)="onShareTruckMobile()" [src]="assetService.getAssetUrl('assets/pages/popups/Share.svg')"/>
                    <!-- <i *ngIf="isPhone && isShow" (click)="onShareTruckMobile()" class="fal fa-share-alt closeImg StockItemB-circle"></i>&nbsp; -->
                  </div>
                  <div class="StockItemB-contactLinks setMargin">
                    <!-- <i *ngIf="isPhone" (click)="onPrintPage()" class="fal fa-print iconSize;"></i> -->
                    <img *ngIf="isPhone" (click)="onPrintPage()" [src]="assetService.getAssetUrl('assets/pages/popups/Print.svg')"/>
                  </div>
                </div>
                <mat-divider></mat-divider>
                <div *ngIf="isTrailer" class="Table-box isEvenWithBg">
                  <div class="Table-row">
                    <div class="Table-cell tal">{{ 'specification/trailerId' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-modelyear">
                      {{ truck?.specification.licensePlate | hasValue }}
                    </div>
                  </div>
                  <div class="Table-row">
                    <div class="Table-cell tal">{{ 'specification/make' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-horsepower">
                      {{ truck?.specification.make | hasValue }}
                    </div>
                  </div>
                  <div class="Table-row">
                    <div class="Table-cell tal">{{ 'specification/model' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-horsepower">
                      {{ truck?.specification.model | hasValue }}
                    </div>
                  </div>
                  <div class="salesPadding">
                    <volvo-sales-slider #salesSlider [users]="salesPersons" [truck]="truck"></volvo-sales-slider>
                  </div>
                </div>
                <!-- not trailer -->
                <div *ngIf="!isTrailer" class="Table-box isEvenWithBg">
                  <div class="Table-row" *ngIf="!isOtherCategory">
                    <div class="Table-cell tal">{{ 'specification/truckId' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-modelyear">
                      {{ truck.specification.licensePlate | hasValue }}
                    </div>
                  </div>
                  <div class="Table-row" *ngIf="isOtherCategory">
                    <div class="Table-cell tal">{{ 'specification/idnumber' | lowercase | translate }}:</div> <!-- for other category vehicle-->
                    <div class="Table-cell" data-cy="truckpage-modelyear">
                      {{ truck.specification.idNumber | hasValue }}
                    </div>
                  </div>
                  <div class="Table-row" *ngIf="!isOtherCategory">
                    <div class="Table-cell tal">{{ 'specification/firstRegDate' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-modelyear">
                      {{ truck?.specification.dateFirstRegistration | date: 'yyyy-MM-dd' | hasValue }}
                    </div>
                  </div>
                  <!-- <div class="Table-row">
                    <div class="Table-cell">{{ 'specification/trailerId' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-modelyear">
                      {{ truck?.specification.licensePlate | hasValue }}
                    </div>
                  </div> -->
                  <div class="Table-row" *ngIf="truck.specification.isElectric">
                    <div class="Table-cell tal">{{ 'specification/model' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-horsepower">
                      {{ truck.specification.make | hasValue }} {{ truck?.specification.model | hasValue }}
                    </div>
                  </div>
                  <div class="Table-row" *ngIf="!truck.specification.isElectric">
                    <div class="Table-cell tal">{{ 'specification/model' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-horsepower" *ngIf="!isOtherCategory">
                      {{ truck.specification.make | hasValue }} {{ truck?.specification.model | hasValue }} {{
                      truck?.specification.horsePower | hasValue }}
                    </div>
                    <div class="Table-cell" data-cy="truckpage-horsepower" *ngIf="isOtherCategory">
                      {{ truck?.specification.model | hasValue }}
                    </div>
                  </div>
                  <div *ngIf="truck.specification.modelYear" class="Table-row">
                    <div class="Table-cell tal">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-modelyear">
                      {{ truck.specification.modelYear | hasValue }}
                    </div>
                  </div>
                  <div class="Table-row" *ngIf="!isOtherCategory">
                    <div class="Table-cell tal">{{ 'specification/cabin' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-horsepower">
                      {{ truck?.specification.cabType | lookup }}
                    </div>
                  </div>
                  <div class="Table-row" *ngIf="!isOtherCategory">
                    <div class="Table-cell tal">{{ 'specification/mileage' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-horsepower">
                      {{ truck?.specification.mileageKm?.toLocaleString('en-GB') | hasValue: 'km' }}
                    </div>
                  </div>
                  <div class="Table-row" *ngIf="!isOtherCategory">
                    <div class="Table-cell tal">{{ 'specification/service' | lowercase | translate }}:</div>
                    <div class="Table-cell" data-cy="truckpage-horsepower">
                      {{ truck.specification.serviceRepairHistory | lookup }}
                    </div>
                  </div>
                  <!-- UK and NS warranty code starts-->
                  <div class="Table-row" *ngIf="!isSelectedSite && region !==regionEnum.TX">
                    <div class="Table-cell tal">Warranty:</div>
                    <div *ngIf="truck?.advertisementCategory=='Remaining Warranty'" class="Table-cell"
                      data-cy="truckpage-horsepower">
                      Remaining factory warranty
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Selected'" class="Table-cell" data-cy="truckpage-horsepower">
                      24 months
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Approved'" class="Table-cell" data-cy="truckpage-horsepower">
                      12 months
                    </div>
                    <div *ngIf="truck?.advertisementCategory=='Economy'" class="Table-cell" data-cy="truckpage-horsepower">
                      6 months
                    </div>
                  </div>
                  <!-- UK and NS warranty ends starts-->
                  <div class="salesPadding" *ngIf="isSelectedSite || region===regionEnum.NS">
                    <volvo-sales-slider #salesSlider [users]="salesPersons" [truck]="truck"></volvo-sales-slider>
                  </div>
                </div>
              </div>
            </div>
            <section class="StockItemB-list">
              <div class="StockItemB-list ContentWp-right">
                <mat-divider *ngIf="truck?.specification.vehicleHighlights?.selectedPlus?.selectedPlusHeading ||
            truck?.specification.vehicleHighlights?.financing?.financingHeading ||
            truck?.specification.vehicleHighlights?.lowMileage?.lowMileageHeading ||
            truck?.specification.vehicleHighlights?.warranty?.warrantyHeading"></mat-divider>
                <mat-accordion [multi]="true" class="VolvoAccordion StockItemC-accordion">
                  <!-- <mat-expansion-panel *ngIf="isTablet" [expanded]="true">
                    <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
                      <mat-panel-title class="volvofontmedium CPrimary">{{ 'truck/accordion/itemA/title' | lowercase |
                        translate
                        }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="StockItemC-accordionBody CPrimary">
                      <div *ngIf="isTrailer" class="Table">
                        <div class="Table-box isEvenWithBg">
                          <div *ngIf="truck.specification.modelYear" class="Table-row">
                            <div class="Table-cell">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                            <div class="Table-cell">
                              {{ truck.specification.modelYear | hasValue }}
                            </div>
                          </div>
                          <div class="Table-row" data-cy="truckpage-make">
                            <h5 class="Table-cell">{{ 'specification/make' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck.specification.make | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/details' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck.specification.model | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/model' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck.specification.superstructure?.type | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/modelYear' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck.specification.modelYear | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/firsRegistration' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck?.specification.dateFirstRegistration | date: 'yyyy-MM-dd' | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/superstructure' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck?.specification?.superstructure?.superstructure | lookup }}
                            </h5>
                          </div>
                        </div>
                      </div>

                      <div *ngIf="!isTrailer" class="Table CPrimary">
                        <div class="Table-box isEvenWithBg">
                          <div *ngIf="truck.specification.modelYear" class="Table-row">
                            <div class="Table-cell">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                            <div class="Table-cell">
                              {{ truck.specification.modelYear | hasValue }}
                            </div>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/make' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell" data-cy="truckpage-make">
                              {{ truck.specification.make | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/model' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell" data-cy="truckpage-model">
                              {{ truck.specification.model | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/regDate' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell" data-cy="truckpage-firstregdate">
                              {{ truck?.specification.dateFirstRegistration | date: 'yyyy-MM-dd' | hasValue }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/mileage' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell" data-cy="truckpage-mileage">
                              {{ truck.specification.mileageKm?.toLocaleString('de-DE') | hasValue: 'km' }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/service' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck.specification.serviceRepairHistory | lookup }}
                            </h5>
                          </div>
                          <div class="Table-row">
                            <h5 class="Table-cell">{{ 'specification/cabin' | lowercase | translate }}:</h5>
                            <h5 class="Table-cell">
                              {{ truck.specification.cabType | lookup }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel> 
                  <mat-divider></mat-divider>-->
                  <mat-expansion-panel [expanded]="true" *ngIf="!isOtherCategory; else other">
                    <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
                      <mat-panel-title class="volvofontmedium CPrimary">{{ 'truck/accordion/itemB/title' | lowercase |
                        translate
                        }}
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="StockItemC-accordionBody Table CPrimary">
                      <div class="Table-box isEvenWithBg">
                        <div *ngIf="truck.specification.trailer?.type" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/trailer/type' | lowercase | translate }}:</div>
                          <div class="Table-cell">
                            {{ truck.specification.trailer?.type | lookup}}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.modelYear" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/modelYear' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-modelyear">
                            {{ truck.specification.modelYear | hasValue }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.horsePower" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/engine' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-horsepower">
                            {{ truck.specification.horsePower | hasValue: 'hp' }}
                          </div>
                        </div>
                        <div *ngIf="
                                  truck.specification.fuelType && truck.specification.fuelType && truck.specification.engineVolumeLitres
                                " class="Table-row">
                          <div class="Table-cell tableCellLeft tal">{{ 'specification/engineType' | lowercase | translate }}:</div>
                          <div class="Table-cell tableCellRight">
                            {{ truck.specification.fuelType | lookup }} - {{ truck.specification.engineVolumeLitres |
                            hasValue:
                            'L' }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.emission" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/euronorm' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-emission">
                            {{ truck.specification.emission | hasValue }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.batteryCapacityKWh && truck.specification.isElectric"
                          class="Table-row">
                          <div *ngIf="!isPhone || isTablet" class="Table-cell tableCellLeft isDesktopTab">{{
                            'specification/batterycapacitydisclaimer' | lowercase |
                            translate }}:
                          </div>
                          <div *ngIf="isPhone" class="isMobile">
                            <div class="Table-cell tal">{{ 'specification/batterycapacity' | lowercase | translate }}:</div>
                            <div class="Table-cell isMobile-fontSize">{{ 'specification/batterycapacity/disclaimer' |
                              lowercase |
                              translate }}</div>
                          </div>
                          <div class="Table-cell tableCellRight" data-cy="truckpage-batteryCapacityKWh">
                            {{ truck.specification.batteryCapacityKWh.engPhrase | hasValue: 'kWh' }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.electricMotor && truck.specification.isElectric"
                          class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/electricMotor' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-emission">
                            {{ truck.specification.electricMotor.engPhrase | hasValue: '' }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.fuelCapacity" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/fuelcapacity' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-fuelcapacity">
                            {{ truck.specification.fuelCapacity | hasValue: 'L' }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.numberOfTanks" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/numberTanks' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-nooftanks">
                            {{ truck.specification.numberOfTanks | hasValue }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.gearBox" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/gearBox' | lowercase | translate }}:</div>
                          <div class="Table-cell tableCellRight" data-cy="truckpage-gearbox">
                            {{ truck.specification.gearBox | hasValue }}
                          </div>
                        </div>
                      </div>

                      <div class="Table-box isEvenWithBg">
                        <div *ngIf="truck.specification.hasDiffLocks && !isTrailer" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/difLocks' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-hasdifflocks">
                            {{ truck.specification.hasDiffLocks | hasValue }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.brakes" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/brakes' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-brakes">
                            {{ truck.specification.brakes | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.additionalBrakes" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/additionalBrakes' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-additionalbrakes">
                            {{ truck.specification.additionalBrakes | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.chassisHeight" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/chassisHeight' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-chassisHeight">
                            {{ truck.specification.chassisHeight | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.frontSuspension" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/frontSuspension' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-frontsuspension">
                            {{ truck.specification.frontSuspension | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.rearSuspension" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/rearSuspension' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-rearsuspension">
                            {{ truck.specification.rearSuspension | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.cabHeater" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/cabHeater' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-cabheater">
                            {{ truck.specification.cabHeater | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.airCondition" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/airCondition' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-aircondition">
                            {{ truck.specification.airCondition | lookup }}
                          </div>
                        </div>
                        <!--superstructure changes-->
                        <div *ngIf="truck" class="PopupOLD CPrimary">
                          <mat-panel-title>
                            <h3 class="truckDetails-title volvofontmedium CPrimary">
                              {{ 'specification/superstructure' | lowercase | translate }}
                            </h3>
                          </mat-panel-title>

                          <!-- TODO Is this really true? superstructure.make and the rest does not exist -->
                          <div *ngIf="
                                    truck.specification.superstructure?.make ||
                                    truck.specification.superstructure?.type ||
                                    truck.specification.superstructure?.model ||
                                    truck.specification.superstructure?.loadHandling ||
                                    truck.specification.superstructure?.numberOfCompartments ||
                                    truck.specification.superstructure?.numberOfPallets ||
                                    truck.specification.superstructure?.rearDoors ||
                                    truck.specification.superstructure?.sideDoors ||
                                    truck.specification.superstructure?.superstructure
                                  " class="Table">
                            <div class="Table-box">
                              <div *ngIf="truck.specification.superstructure?.type" class="Table-row">
                                <div>{{ 'specification/type' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure?.type }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.superstructure?.superstructure" class="Table-row">
                                <div>{{ 'specification/superstructure' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure?.superstructure | lookup }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.superstructure.make" class="Table-row">
                                <div>{{ 'specification/make' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure?.make }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.superstructure.model" class="Table-row">
                                <div>{{ 'specification/model' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure.model }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.superstructure.numberOfPallets" class="Table-row">
                                <div>{{ 'superstructure/loadHandlingPallets' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure.numberOfPallets }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.superstructure.numberOfCompartments" class="Table-row">
                                <div>{{ 'superstructure/loadHandlingCompartment' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure.numberOfCompartments }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.superstructure.loadHandling" class="Table-row">
                                <div>{{ 'superstructure/loadHandling' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure.loadHandling | lookup }}
                                </div>
                              </div>
                              <div *ngIf="truck.specification.superstructure.rearDoors" class="Table-row">
                                <div>{{ 'superstructure/loadHandlingRear' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure.rearDoors | lookup }}
                                </div>
                              </div>

                              <div *ngIf="truck.specification.superstructure.sideDoors" class="Table-row">
                                <div>{{ 'superstructure/loadHandlingSide' | lowercase | translate }}:</div>
                                <div>
                                  {{ truck.specification.superstructure.sideDoors | lookup }}
                                </div>
                              </div>
                            </div>
                            <div *ngIf="
                                      truck.specification.superstructure.measurements &&
                                      (truck.specification.superstructure.measurements.internalLengthMm ||
                                        truck.specification.superstructure.measurements.internalWidthMm ||
                                        truck.specification.superstructure.measurements.internalHeightMm)
                                    ">
                              <mat-panel-title>
                                <h3 class="truckDetails-title volvofontmedium CPrimary">
                                  {{ 'specification/measurements' | lowercase | translate }}
                                </h3>
                              </mat-panel-title>

                              <div class="Table CPrimary">
                                <div class="Table-box">
                                  <div *ngIf="truck.specification.superstructure.measurements.internalLengthMm"
                                    class="Table-row">
                                    <div>{{ 'specification/internalLength' | lowercase | translate }} (mm):</div>
                                    <div>
                                      {{ truck.specification.superstructure.measurements.internalLengthMm }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.measurements.internalWidthMm"
                                    class="Table-row">
                                    <div>{{ 'specification/internalWidth' | lowercase | translate }} (mm):</div>
                                    <div>
                                      {{ truck.specification.superstructure.measurements.internalWidthMm }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.measurements.internalHeightMm"
                                    class="Table-row">
                                    <div>{{ 'specification/internalHeight' | lowercase | translate }} (mm):</div>
                                    <div>
                                      {{ truck.specification.superstructure.measurements.internalHeightMm }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.measurements.externalLengthMm"
                                    class="Table-row">
                                    <div>{{ 'superstructure/measurementsExternalLength' | lowercase | translate }} (mm):
                                    </div>
                                    <div>
                                      {{ truck.specification.superstructure.measurements.externalLengthMm }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.measurements.externalWidthMm"
                                    class="Table-row">
                                    <div>{{ 'superstructure/measurementsExternalWidth' | lowercase | translate }} (mm):
                                    </div>
                                    <div>
                                      {{ truck.specification.superstructure.measurements.externalWidthMm }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.measurements.externalHeightMm"
                                    class="Table-row">
                                    <div>{{ 'superstructure/measurementsExternalHeight' | lowercase | translate }} (mm):
                                    </div>
                                    <div>
                                      {{ truck.specification.superstructure.measurements.externalHeightMm }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="
                                      truck.specification.superstructure.crane?.dateLegalInspection ||
                                      truck.specification.superstructure.crane?.extensions ||
                                      truck.specification.superstructure.crane?.make ||
                                      truck.specification.superstructure.crane?.other ||
                                      truck.specification.superstructure.crane?.model ||
                                      truck.specification.superstructure.crane?.modelYear ||
                                      truck.specification.superstructure.crane?.capacity
                                    ">
                              <h3 class="truckDetails-title volvofontmedium">
                                {{ 'superstructure/crane' | lowercase | translate }}
                              </h3>

                              <div class="Table">
                                <div class="Table-box">
                                  <div *ngIf="truck.specification.superstructure.crane?.make" class="Table-row">
                                    <div>{{ 'superstructure/make' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.crane?.make.engPhrase }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.crane?.model" class="Table-row">
                                    <div>{{ 'superstructure/model' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.crane?.model }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.crane?.modelYear" class="Table-row">
                                    <div>{{ 'specification/modelYear' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.crane?.modelYear }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.crane?.capacity" class="Table-row">
                                    <div>{{ 'specification/capacity' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.crane?.capacity.engPhrase }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.crane.extensions" class="Table-row">
                                    <div>{{ 'superstructure/craneExtensions' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.crane.extensions.engPhrase }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.crane.other" class="Table-row">
                                    <div>{{ 'superstructure/craneOther' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.crane.other | lookup }}
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="truck.specification.superstructure.crane.dateLegalInspection!=='0001-01-01T00:00:00'"
                                    class="Table-row">
                                    <div>
                                      {{ 'superstructure/craneDate' | lowercase | translate }}:
                                    </div>
                                    <div>
                                      {{ truck.specification.superstructure.crane.dateLegalInspection | date }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="
                                      truck.specification.superstructure.tailLift?.make ||
                                      truck.specification.superstructure.tailLift?.capacity ||
                                      truck.specification.superstructure.tailLift?.modelYear ||
                                      truck.specification.superstructure.tailLift?.position ||
                                      truck.specification.superstructure.tailLift?.dateLegalInspection
                                    ">
                              <h3 class="truckDetails-title volvofontmedium">
                                {{ 'specification/tailLift' | lowercase | translate }}
                              </h3>

                              <div class="Table">
                                <div class="Table-box">
                                  <div *ngIf="truck.specification.superstructure.tailLift?.make" class="Table-row">
                                    <div>{{ 'specification/make' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.tailLift?.make.engPhrase}}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.tailLift.modelYear" class="Table-row">
                                    <div>{{ 'specification/modelYear' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.tailLift.modelYear }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.tailLift.position" class="Table-row">
                                    <div>{{ 'superstructure/position' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.tailLift.position | lookup }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.tailLift.capacity" class="Table-row">
                                    <div>{{ 'specification/capacity' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.tailLift.capacity | lookup }}
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="truck.specification.superstructure.tailLift.dateLegalInspection!=='0001-01-01T00:00:00'"
                                    class="Table-row">
                                    <div>
                                      {{ 'superstructure/craneDate' | lowercase | translate }}:
                                    </div>
                                    <div>
                                      {{ truck.specification.superstructure.tailLift.dateLegalInspection | date }}:
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="
                                      truck.specification.superstructure.temperatureControl &&
                                      (truck.specification.superstructure.temperatureControl?.aggregateType ||
                                        truck.specification.superstructure.temperatureControl?.dateLegalInspection ||
                                        truck.specification.superstructure.temperatureControl?.make ||
                                        truck.specification.superstructure.temperatureControl?.modelYear ||
                                        truck.specification.superstructure.temperatureControl?.position ||
                                        truck.specification.superstructure.temperatureControl?.description)
                                    ">
                              <h3 class="truckDetails-title volvofontmedium">
                                {{ 'superstructure/TempControl' | lowercase | translate }}
                              </h3>

                              <div class="Table">
                                <div class="Table-box">
                                  <div *ngIf="truck.specification.superstructure.temperatureControl?.make"
                                    class="Table-row">
                                    <div>{{ 'superstructure/TempControlMake' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.temperatureControl?.make.engPhrase }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.temperatureControl.modelYear"
                                    class="Table-row">
                                    <div>{{ 'superstructure/TempControlYear' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.temperatureControl.modelYear }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.temperatureControl.position"
                                    class="Table-row">
                                    <div>{{ 'superstructure/position' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.temperatureControl.position | lookup }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.temperatureControl.aggregateType"
                                    class="Table-row">
                                    <div>
                                      {{ 'superstructure/temperaturecontrol/aggregatetype' | lowercase | translate }}:
                                    </div>
                                    <div>
                                      {{ truck.specification.superstructure.temperatureControl.aggregateType | lookup }}
                                    </div>
                                  </div>
                                  <div *ngIf="truck.specification.superstructure.temperatureControl.description"
                                    class="Table-row">
                                    <div>{{ 'superstructure/description' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.temperatureControl.description | lookup }}
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="truck.specification.superstructure.temperatureControl.dateLegalInspection !=='0001-01-01T00:00:00'"
                                    class="Table-row">
                                    <div>{{ 'superstructure/craneDate' | lowercase | translate }}:</div>
                                    <div>
                                      {{ truck.specification.superstructure.temperatureControl.dateLegalInspection |
                                      date }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!--End superstructure changes -->
                      </div>
                    </div>
                    <mat-panel-title class="mat-expansion-panel-header-title">
                      <h3 class="truckDetails-title volvofontmedium CPrimary"> {{ 'truck/accordion/itemC/title' |
                        lowercase |
                        translate
                        }}</h3>
                    </mat-panel-title>

                    <div class="StockItemC-accordionBody Table WithImage CPrimary">
                      <div class="Table-box">
                        <div *ngIf="truck.specification.axleConfiguration" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/configuration' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-axleconfig">
                            {{ truck?.specification.axleConfiguration | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.wheelBaseMm" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/wheelbase' | lowercase | translate }} (mm):</div>
                          <div class="Table-cell">
                            {{ truck.specification.wheelBaseMm | hasValue }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.rearAxleType" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/rearAxleType' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-rearaxletype">
                            {{ truck.specification.rearAxleType | lookup }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.rearAxleRatio" class="Table-row">
                          <div class="Table-cell tal">{{ 'specification/rearAxleRatio' | lowercase | translate }}:</div>
                          <div class="Table-cell" data-cy="truckpage-rearaxleratio">
                            {{ truck.specification.rearAxleRatio | hasValue }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.axleConfigurationLayout" class="Table-row CPrimary">
                          Tyre details
                        </div>
                        <div style="display: flex; justify-content: space-between;">
                          <ng-container *ngIf="!hasEmptyDimensions; else emptyDimensions">
                            <div style="flex: 1;">
                              <div *ngFor="let item of truck.specification.tyreTread; index as i">
                                <div *ngIf="truck.specification.axleConfigurationLayout" class="Table-row"
                                  style="justify-content: unset; margin: 20px 0;">
                                  <div class="Table-cell axleMobile">
                                    Axle {{ i + 1 }}: Tyre size {{ item.TyreWidthMm }}/{{ item.TyreProfile }}R{{
                                    item.TyreDimension }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-container>
                          <ng-template #emptyDimensions>
                            <div class="Table-row tal" style="display: flex; justify-content: space-between;">
                              {{ 'specification/asksalespersontyre' | lowercase | translate }}
                            </div>
                          </ng-template>
                          <div style="flex: 1;">
                            <volvo-axle [truck]="truck"
                              *ngIf="truck?.specification.axleConfigurationLayout"></volvo-axle>
                          </div>
                        </div>
                      </div>
                    </div>
                    <mat-panel-title
                      *ngIf="truck.specification.frontAxleLoadKg || truck.specification.vehicleWeightWithoutLoadKg"
                      class="mat-expansion-panel-header-title">
                      <h3 class="truckDetails-title volvofontmedium CPrimary">{{ 'truck/accordion/itemg/title' |
                        lowercase |
                        translate
                        }}</h3>
                    </mat-panel-title>
                    <div *ngIf="truck.specification.frontAxleLoadKg || truck.specification.vehicleWeightWithoutLoadKg"
                      class="StockItemC-accordionBody Table CPrimary">
                      <div class="Table-box isEvenWithBg">
                        <div *ngIf="truck.specification.vehicleWeightWithoutLoadKg" class="Table-row">
                          <div class="Table-cell tal">{{ 'truck/vehicleweight/vehicleWeightWithoutLoadKg' | lowercase |
                            translate }}:
                          </div>
                          <div class="Table-cell">
                            {{ truck.specification.vehicleWeightWithoutLoadKg }}
                          </div>
                        </div>
                        <div *ngIf="truck.specification.frontAxleLoadKg" class="Table-row">
                          <div class="Table-cell tal">{{ 'truck/vehicleweight/frontAxleLoadKg' | lowercase | translate }}:
                          </div>
                          <div class="Table-cell">
                            {{ truck.specification.frontAxleLoadKg }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <mat-panel-title id="PrintEquipment" *ngIf="truck.vehicleAdditionalEquipment"
                      class="mat-expansion-panel-header-title">
                      <h3 class="truckDetails-title volvofontmedium CPrimary">{{ 'truck/accordion/itemD/title' |
                        lowercase |
                        translate
                        }}</h3>
                    </mat-panel-title>

                    <div id="PrintEquipment" *ngIf="truck.vehicleAdditionalEquipment"
                      class="StockItemC-accordionBody Table CPrimary">
                      <div class="Table-box">
                        <div *ngFor="let item of truck.vehicleAdditionalEquipment" id="PrintEquipment"
                          class="Table-row">
                          {{ item | lookup }}
                        </div>
                      </div>
                    </div>
                    <mat-panel-title *ngIf="truck.specification.comment" class="mat-expansion-panel-header-title">
                      <h3 class="truckDetails-title volvofontmedium CPrimary">{{
                        'truck/accordion/itemE/title' | lowercase |
                        translate
                        }}</h3>
                    </mat-panel-title>

                    <ng-container *ngFor="let comment of comments; let i = index" [ngTemplateOutlet]="commentTemplate" [ngTemplateOutletContext]="{ comment: comment, index: i }"></ng-container>
                  </mat-expansion-panel>
                  <ng-template #other *ngIf="truck.specification.comment">
                    <mat-divider style="border: 1px solid #E1DFDD;"></mat-divider>
                    <h3 class="truckDetails-title volvofontmedium CPrimary">{{
                      'truck/accordion/itemE/title' | lowercase |
                      translate
                      }}</h3>
                      <ng-container *ngFor="let comment of comments; let i = index" [ngTemplateOutlet]="commentTemplate" [ngTemplateOutletContext]="{ comment: comment, index: i }"></ng-container>
                  </ng-template>
                </mat-accordion>
              </div>
              <!-- section below slider -->

              <!-- <div class="StockItemC-right ContentWp-right CPrimary">
                <div class="StockItemC-item">
                  <volvo-truck-page-lease [truck]="truck"></volvo-truck-page-lease>
                </div>
                <volvo-truck-page-dealership [truck]="truck" id="location"></volvo-truck-page-dealership>
              </div> -->
            </section>
            <div *ngIf="haschassisNumber">
              <mat-divider style="border: 1px solid #E1DFDD;"></mat-divider>
              <div class="driverguide">
                <div class="driverguide-guideText">
                  <div class="driverguide-label">{{ 'truck/driverGuide/title' | lowercase | translate }} </div>
                    <a class="driverguide-text"
                      href="https://driverguide.volvotrucks.com/chassi/{{ truck.specification.chassisNumber }}/home"
                      target="_blank" (click)="openedDriversGuide()">
                      <button class="navigation-buttonFact driverguide-navigation">
                        <div>
                          <div class="displayFlex">
                            <span class="navigation-button-text driverguide-navigationSpan"
                              [innerHTML]="'truck/driverGuide/link' | lowercase | translate"></span>
                            <i class="fal fa-chevron-right driverguide-navImage"></i>
                          </div>
                        </div>
                      </button>
                    </a>
                  <div class="driverguide-disclaimer">{{ 'truck/driverGuide/disclaimer' | lowercase | translate }}
                  </div>
                </div>
              </div>
            </div>
            <mat-divider style="margin-bottom: 10px;"></mat-divider>
            <div class="disclaimer" *ngIf="!isPhone">
              <div class=" disclaimer-text">{{ 'truck/specification/disclaimer' | lowercase | translate }}</div>
            </div>

            <!-- mobile section -->
            <div layout="column">
              <div class="approvedContentMobile" *ngIf="isSelectedSite">
                <div class="approvedOffer-left" *ngIf="!disableVFS">
                  <volvo-truck-page-lease [truck]="truck"
                    (sliderValueChange)="onSliderChange($event)"></volvo-truck-page-lease>
                </div>
                <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMap"
                  *ngIf="!disableVFS"></volvo-truck-page-dealership>
                <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMapFull"
                  *ngIf="disableVFS"></volvo-truck-page-dealership>

                <!-- <div class="approvedOffer-left" *ngIf="!disableVFS">
                  <volvo-truck-page-lease [truck]="truck" (sliderValueChange)="onSliderChange($event)"></volvo-truck-page-lease>
                </div>
                <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMap"
                  *ngIf="!disableVFS"></volvo-truck-page-dealership>
                <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMapFull"
                  *ngIf="disableVFS"></volvo-truck-page-dealership> -->
              </div>
              <div *ngIf="!isSelectedSite">
                <!-- mobile section -->
                <div layout="column" *ngIf="!disableVFS">
                  <div class="approvedContentMobile" *ngIf="region!==regionEnum.TX">
                    <div class="approvedOffer">
                      <div *ngIf="truck?.advertisementCategory=='Approved'" class="Table-cell" data-cy="truckpage-horsepower">
                        <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-12-icon.webp')" alt="" />
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/approvedoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem3' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem4' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem7' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem8' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem9' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/approvedoffertext' | lowercase | translate }}
                        </span>
                      </div>
                      <div *ngIf="truck?.advertisementCategory=='Selected'" class="Table-cell" data-cy="truckpage-horsepower">
                        <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-24-icon.webp')" alt="" />
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/selectedoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem3' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem4' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem7' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem8' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem9' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/selectedoffertext' | lowercase | translate }}
                        </span>
                      </div>
                      <div *ngIf="truck?.advertisementCategory=='Remaining Warranty'" class="Table-cell"
                        data-cy="truckpage-horsepower">
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/remainingoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem3' | lowercase | translate }}</li>
                          <!-- <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem4' | lowercase | translate }}</li> -->
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem7' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem8' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/remainingoffertext' | lowercase | translate }}
                        </span>
                      </div>
                      <div *ngIf="truck?.advertisementCategory=='Economy'" class="Table-cell" data-cy="truckpage-horsepower">
                        <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-6-icon.webp')" alt="" />
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/economyoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem3' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem4' | lowercase | translate }} </li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/economyoffertext' | lowercase | translate }}
                        </span>
                      </div>
                    </div>
                    <div class="approvedOffer-right">
                      <volvo-truck-page-lease [truck]="truck" (sliderValueChange)="onSliderChange($event)"></volvo-truck-page-lease>
                    </div>
                  </div>
                  <div class="approvedContentMobile">
                    <div class="approvedOffer-blueContact" *ngIf="region!==regionEnum.TX">
                      <h6 class="approvedOffer-subHeader" style="color: #ffffff;">{{ 'uk/truck/bluecontract' | lowercase | translate }}</h6>
                      <ul>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem4' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem7' | lowercase | translate }}</li>
                      </ul>
                      <mat-divider class="approvedOffer-matDivider"></mat-divider>
                      <div class="Table-box isEvenWithBg">
                        <div class="Table-row priceColor" style="margin-bottom: 3rem;">
                          <div class="Table-cell">{{ 'uk/truck/bluecontract/weeklyprice' | lowercase | translate }}:</div>
                          <div class="Table-cell">
                            £ 85.00 *
                          </div>
                        </div>
                      </div>
                      <span class="approvedOffer-subText">
                        * {{ 'uk/truck/bluecontract/indicativeprice' | lowercase | translate }}
                      </span>
                    </div>
                    <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMap" [ngClass]="region!==regionEnum.TX?'':'displayWidth'"></volvo-truck-page-dealership>

                  </div>
                </div>
                <!-- without vfs -->
                <div layout="column" *ngIf="disableVFS">
                  <div class="approvedContentMobile" *ngIf="region!==regionEnum.TX">
                    <div class="approvedOffer">
                      <div *ngIf="truck?.advertisementCategory=='Approved'" class="Table-cell" data-cy="truckpage-horsepower">
                        <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-12-icon.webp')" alt="" />
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/approvedoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem3' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem4' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem7' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem8' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/approvedofferitem9' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/approvedoffertext' | lowercase | translate }}
                        </span>
                      </div>
                      <div *ngIf="truck?.advertisementCategory=='Selected'" class="Table-cell" data-cy="truckpage-horsepower">
                        <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-24-icon.webp')" alt="" />
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/selectedoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem3' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem4' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem7' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem8' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/selectedofferitem9' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/selectedoffertext' | lowercase | translate }}
                        </span>
                      </div>
                      <div *ngIf="truck?.advertisementCategory=='Remaining Warranty'" class="Table-cell"
                        data-cy="truckpage-horsepower">
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/remainingoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 3rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem3' | lowercase | translate }}</li>
                          <!-- <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem4' | lowercase | translate }}</li> -->
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem7' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/remainingofferitem8' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/remainingoffertext' | lowercase | translate }}
                        </span>
                      </div>
                      <div *ngIf="truck?.advertisementCategory=='Economy'" class="Table-cell" data-cy="truckpage-horsepower">
                        <img class="offerImg" [src]="assetService.getAssetUrl('assets/offer-6-icon.webp')" alt="" />
                        <h6 class="approvedOffer-subHeader">{{ 'uk/truck/offer/economyoffer' | lowercase | translate }}</h6>
                        <ul style="margin-bottom: 2rem; padding-left: 0;list-style: inside;">
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem1' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem2' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem3' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem4' | lowercase | translate }} </li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem5' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'uk/truck/offer/economyofferitem6' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/predelivery' | lowercase | translate }}</li>
                          <li class="approvedOffer-bulletItems"> {{ 'services/premiumquality/vospschedule' | lowercase | translate }}</li>
                        </ul>
                        <span class="approvedOffer-subTextofferContract">
                          {{ 'uk/truck/offer/economyoffertext' | lowercase | translate }}
                        </span>
                      </div>
                    </div>
                    <div class="approvedOffer-blueContact">
                      <h6 class="approvedOffer-subHeader" style="color: #ffffff;">{{ 'uk/truck/bluecontract' | lowercase | translate }}</h6>
                      <ul>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem1' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem2' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem3' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem4' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem5' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem6' | lowercase | translate }}</li>
                        <li class="approvedOffer-bulletItems"> {{ 'uk/truck/bluecontractitem7' | lowercase | translate }}</li>
                      </ul>
                      <mat-divider class="approvedOffer-matDivider"></mat-divider>
                      <div class="Table-box isEvenWithBg">
                        <div class="Table-row priceColor">
                          <div class="Table-cell">{{ 'uk/truck/bluecontract/weeklyprice' | lowercase | translate }}:</div>
                          <div class="Table-cell">
                            £ 85.00 *
                          </div>
                        </div>
                      </div>
                      <span class="approvedOffer-subText">
                        * {{ 'uk/truck/bluecontract/indicativeprice' | lowercase | translate }}
                      </span>
                    </div>
                  </div>
                  <div class="approvedContentMobile">
                    <volvo-truck-page-dealership [truck]="truck" id="location" class="dealershipMap" style="width: 100%; margin-left:0rem;"></volvo-truck-page-dealership>
                  </div>
                </div>
              </div>
              <div class="disclaimer" *ngIf="isPhone">
                <div class=" disclaimer-text">{{ 'truck/specification/disclaimer' | lowercase | translate }}</div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </div>
  </section>
  <volvo-footer></volvo-footer>
</div>

<ng-template #commentTemplate let-comment="comment" let-i="index">
  <div class="StockItemC-accordionBody CPrimary">
    <div class="addInfo">
      <div class="addInfo-lang">
        <span>{{ comment.name }}</span>
      </div>
      <div *ngIf="i === 0">
        <pre *ngIf="!hasTranslate">{{ comment.comment | translate }}</pre>
        <pre *ngIf="hasTranslate">{{ translatedText | translate }}</pre>
        <div style="display:flex">
          <div class="addInfo-trans">
            <div class="blink" *ngIf="showProgress">
              {{ 'translating' | lowercase | translate }}
            </div>
            <div style="display:flex">
              <div>
                <mat-select class="select" [(value)]="selectedLangCode"
                  (selectionChange)="OnDropDownChange(comment.comment, comment.languageCodeIso3)">
                  <mat-option *ngFor="let item of langCodes" [value]="item.iso1">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </div>
              <div class="addInfo-progressbar" *ngIf="hasTranslate">
                <img src="assets/icons/undo.png"
                  matTooltip="{{'additionalinfo/comment' | lowercase | translate}}"
                  (click)="hasTranslate = false; selectedLangCode = comment.languageCodeIso3;" />
              </div>
            </div>
            <div class="addInfo-condition">
              <div class="addInfo-condition-sub">
                {{ 'additionInfo/condition' | lowercase | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="i !== 0" style="padding-bottom:10px">
        <pre>{{ comment.comment }}</pre>
      </div>
    </div>
  </div>
</ng-template>
