<section class="approved">
    <div class="approved-approvedDiv">
      <div>
        <img [src]="assetService.getAssetUrl('assets/pages/vehicleOffers/UTApproved.webp')" class="approved-approvedImg" />
      </div>
      <div class="approved-title">
        <span>{{ "uk/components/footer/volvoApproved" | lowercase | translate }}</span>
      </div>
      <div class="approved-text">
        <span>{{ "uk/pages/vehicleOffer/approved/Text1" | lowercase | translate }}</span>
      </div>
      <div class="approved-text">
        <span class="approved-text-medium">{{ "uk/pages/vehicleOffer/approved/Text2" | lowercase | translate }}</span
        >{{ "uk/pages/vehicleOffer/approved/Text3" | lowercase | translate }}
      </div>
    </div>
    <div class="approved-list">
      <ul class="approved-list-title" *ngFor="let list of approvedList">
        {{
          list.type | lowercase | translate
        }}
        <li *ngFor="let label of list.label">
          {{ label.name | lowercase | translate }}
        </li>
      </ul>
    </div>
    <div >
        <span class="approved-disClaimer">{{"uk/pages/vehicleOffer/disclaimer" | lowercase  | translate}}</span>
      </div>
    <div class="volvoFactSheet" id="economy">
      <a href="assets/files/UT Volvo Approved Fact Sheet - April 2024.pdf" target="_blank">
        <button class="navigation-buttonFact" >
          <div>
            <div>
              <span class="navigation-button-text">{{
                "uk/pages/services/approvedfact" | lowercase | translate
              }}</span>
            </div>
            <div>
              <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" />
            </div>
          </div>
        </button>
      </a>
    </div>
  </section>
  <volvo-footer></volvo-footer>
