<mat-accordion [multi]="true" class="VolvoAccordion" data-cy="truck-filter" >
  <ng-container
    *ngFor="let keyValueObj of search.steps | keyvalue: originalOrder; let index = index; trackBy: trackByFn">
    <ng-container *ngIf="keyValueObj.value.isVisible && keyValueObj.value.title != 'Dealership Location'">
      <mat-expansion-panel [expanded]="keyValueObj.value.isExpanded"
        (opened)="onPanelOpened(keyValueObj.value, keyValueObj.key)">
        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
          <mat-panel-title>
            <span *ngIf="keyValueObj.value.title && regionalSettings.site==='volvoselected'" class="volvofontmedium CPrimary truckFilter"
              [attr.data-cy]="keyValueObj.value.title">
              {{ (keyValueObj.value.title === 'Make' ? ('specification/make' | lowercase | translate) : null)}}
              {{
              keyValueObj.value.title === 'Truck
              Location'
              ? ('specification/truckLocation' | lowercase | translate)
              : null
              }}
              {{ keyValueObj.value.title === 'Vehicle Type' ? ('globalMiss/vehicleType' | lowercase | translate) : null
              }}
              {{ keyValueObj.value.title === 'Superstructure' ? ('superstructure/superstructure' | lowercase |
              translate) :
              null }}
              {{ keyValueObj.value.title === 'Model' ? ('specification/model' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Price' ? ('globalMiss/price' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Engine HP' ? ('globalMiss/engineHP' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Mileage' ? ('specification/mileage' | lowercase | translate) : null }}
              {{
              keyValueObj.value.title === 'First Registration' ? ('specification/firsRegistration' | lowercase |
              translate) :
              null
              }}
              {{ keyValueObj.value.title === 'Axle Configuration' ? ('specification/axleConfig' | lowercase | translate)
              : null
              }}
              {{ keyValueObj.value.title === 'Euronorm' ? ('specification/euronorm' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Fuel Type' ? ('search/filter/fueltype' | lowercase | translate) : null }}
            </span>
            <span id=counter class="volvofontmedium truckFilter" *ngIf="search.steps[keyValueObj.key].value !== search.steps[keyValueObj.key].initValue
                          && regionalSettings.site==='volvoselected'
                          && search.steps[keyValueObj.key].value != ''
                          && (keyValueObj.key === 'make'
                          || keyValueObj.key === 'model'
                          || keyValueObj.key === 'axleConfiguration'
                          || keyValueObj.key === 'emission'
                          || keyValueObj.key === 'axleConfiguration'
                          || keyValueObj.key === 'superstructureType'
                          || keyValueObj.key === 'stockLocationCountry' 
                          || keyValueObj.key === 'fuelType')">
                          <div *ngIf="search.steps[keyValueObj.key].value?.split(';').length-1!==-1">({{search.steps[keyValueObj.key].value?.split(';').length}})</div>
              
            </span>
            <span id=counter class="volvofontmedium truckFilter" *ngIf="
                          regionalSettings.site==='volvoselected'
                          && (keyValueObj.key === 'dateFirstRegistration'
                          || keyValueObj.key === 'horsePower'
                          || keyValueObj.key === 'mileageKm'
                          || keyValueObj.key === 'priceExclVatEuro') &&
                          (search.steps[keyValueObj.key].value.min !== search.steps[keyValueObj.key].initValue.min
                          || search.steps[keyValueObj.key].value.max !== search.steps[keyValueObj.key].initValue.max)">
              (1)   
            </span>
            <span *ngIf="keyValueObj.value.title && regionalSettings.site==='TX'" class="volvofontmedium CPrimary truckFilter">
              {{ keyValueObj.value.title === 'Vehicle Type' ? ('globalMiss/vehicleType' | lowercase | translate) : null
              }}
              {{ keyValueObj.value.title === 'Superstructure' ? ('uk/superstructure/superstructure' | lowercase | translate) :
              null }}
              {{ keyValueObj.value.title === 'Model' ? ('uk/specification/model' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Cab' ? ('uk/specification/cab' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Price' ? ('globalMiss/price' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Engine HP' ? ('globalMiss/engineHP' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Mileage' ? ('uk/specification/mileage' | lowercase | translate) : null }}
              {{
              keyValueObj.value.title === 'First Registration' ? ('specification/firsRegistration' | lowercase | translate) :
              null
              }}
              {{ keyValueObj.value.title === 'Axle Configuration' ? ('uk/specification/axleConfig' | lowercase | translate)
              : null
              }}
            </span>
            <span id=counter class="volvofontmedium truckFilter" *ngIf="(search.steps[keyValueObj.key].value | asString) !== (search.steps[keyValueObj.key].initValue | asString)
                          && regionalSettings.site==='TX'              
                          && search.steps[keyValueObj.key].value != ''
                          && (keyValueObj.key === 'model'
                          || keyValueObj.key === 'axleConfiguration'
                          || keyValueObj.key === 'cabType'
                          || keyValueObj.key === 'superstructureType') ">
              ({{(search.steps[keyValueObj.key].value | asString).split(';').length}})
            </span>
            <span id=counter class="volvofontmedium truckFilter" *ngIf="
                          regionalSettings.site==='TX'
                          && (keyValueObj.key === 'dateFirstRegistration'
                          || keyValueObj.key === 'horsePower'
                          || keyValueObj.key === 'mileageKm'
                          || keyValueObj.key === 'priceExclVatEuro') ">
            </span>
            <span *ngIf="keyValueObj.value.title && regionalSettings.site!=='volvoselected' && regionalSettings.site!=='TX'" class="volvofontmedium CPrimary truckFilter">
              {{ (keyValueObj.value.title === 'Offer' ? ('specification/offer' | lowercase | translate) : null)}}
              {{ (keyValueObj.value.title === 'Warranty' ? ('uk/specification/warranty' | lowercase | translate) : null)}}
              {{
              keyValueObj.value.title === 'Truck
              Location'
              ? ('uk/specification/truckLocation' | lowercase | translate)
              : null
              }}
              {{ keyValueObj.value.title === 'Vehicle Type' ? ('globalMiss/vehicleType' | lowercase | translate) : null
              }}
              {{ keyValueObj.value.title === 'Offer and Warranty' ? ('uk/specification/offerAndWarranty' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Superstructure' ? ('uk/superstructure/superstructure' | lowercase | translate) :
              null }}
              {{ keyValueObj.value.title === 'Model' ? ('uk/specification/model' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Stocklocation City' ? ('uk/specification/location' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Cab' ? ('uk/specification/cab' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Price' ? ('globalMiss/price' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Engine HP' ? ('globalMiss/engineHP' | lowercase | translate) : null }}
              {{ keyValueObj.value.title === 'Mileage' ? ('uk/specification/mileage' | lowercase | translate) : null }}
              {{
              keyValueObj.value.title === 'First Registration' ? ('specification/firsRegistration' | lowercase | translate) :
              null
              }}
              {{ keyValueObj.value.title === 'Axle Configuration' ? ('uk/specification/axleConfig' | lowercase | translate)
              : null
              }}
            </span>
            <span id=counter class="volvofontmedium truckFilter" *ngIf="(search.steps[keyValueObj.key].value | asString) !== (search.steps[keyValueObj.key].initValue | asString)
                          && regionalSettings.site!=='volvoselected' && regionalSettings.site!=='TX'              
                          && search.steps[keyValueObj.key].value != ''
                          && (keyValueObj.key === 'model'
                          || keyValueObj.key === 'axleConfiguration'
                          || keyValueObj.key === 'stockLocationCity'
                          || keyValueObj.key === 'cabType'
                          || keyValueObj.key === 'offerAndWarranty'
                          || keyValueObj.key === 'superstructureType') ">
              ({{(search.steps[keyValueObj.key].value | asString).split(';').length}})
            </span>
            <span id=counter class="volvofontmedium truckFilter" *ngIf="
                          regionalSettings.site!=='volvoselected' && regionalSettings.site!=='TX'
                          && (keyValueObj.key === 'dateFirstRegistration'
                          || keyValueObj.key === 'horsePower'
                          || keyValueObj.key === 'mileageKm'
                          || keyValueObj.key === 'priceExclVatEuro') ">
            </span>
            <span id=counter class="volvofontmedium truckFilter" *ngIf="(keyValueObj.key === 'majorCategory' && (keyValueObj.value.value ==='Tractor'||keyValueObj.value.value ==='Truck'||keyValueObj.value.value ==='Trailer'))">
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <div *ngIf="aggregations" class="tac">
            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.stockLocationCountry && regionalSettings.site==='volvoselected'"
              [aggregationTypeName]="'stockLocationCountry'" [step]="keyValueObj.value" [aggregations]="aggregations"
              [searchFilter]="search" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>
            
            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.offerAndWarranty && regionalSettings.site!=='volvoselected'"
              [step]="keyValueObj.value" [aggregationTypeName]="'advertisementCategory'" [aggregations]="aggregations"
              [searchFilter]="search" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-type *ngIf="keyValueObj.key === SearchStepKeys.majorCategory" [step]="keyValueObj.value"
              [isSuperstructure]="false" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-type>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.superstructureType"
              [aggregationTypeName]="'superstructureType'" [aggregations]="aggregations" [step]="keyValueObj.value"
              [isSuperstructure]="true" [searchFilter]="search" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.make" [step]="keyValueObj.value"
              [aggregationTypeName]="'make'" [aggregations]="aggregations"
              (filterChange)="onFilterChange($event, keyValueObj.key)" [searchFilter]="search"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.model" [step]="keyValueObj.value"
              [aggregationTypeName]="'model'" [aggregations]="aggregations"
              (filterChange)="onFilterChange($event, keyValueObj.key)" [searchFilter]="search"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.axleConfiguration"
              [step]="keyValueObj.value" [aggregationTypeName]="'axleConfiguration'" [aggregations]="aggregations"
              [searchFilter]="search" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.emission" [step]="keyValueObj.value"
              [aggregationTypeName]="'emission'" [aggregations]="aggregations"
              (filterChange)="onFilterChange($event, keyValueObj.key)" [searchFilter]="search"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.fuelType"
              [step]="keyValueObj.value" [aggregationTypeName]="'fuelType'" [aggregations]="aggregations"
              [isFuelType]="true" [searchFilter]="search" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.stockLocationCity && regionalSettings.site!=='volvoselected'"
              [step]="keyValueObj.value" [aggregationTypeName]="'stockLocationCity'" [aggregations]="aggregations"
              [searchFilter]="search" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.cabType && regionalSettings.site!=='volvoselected'"
              [step]="keyValueObj.value" [aggregationTypeName]="'cabType'" [aggregations]="aggregations"
              [searchFilter]="search" (filterChange)="onFilterChange($event, keyValueObj.key)"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>

            <volvo-filter-radio-search *ngIf="keyValueObj.key === SearchStepKeys.advertisementCategory && regionalSettings.site!=='volvoselected'" [step]="keyValueObj.value"
              [aggregationTypeName]="'advertisementCategory'" [aggregations]="aggregations"
              (filterChange)="onFilterChange($event, keyValueObj.key)" [searchFilter]="search"
              [attr.data-testId]="keyValueObj.key">
            </volvo-filter-radio-search>
        
            <volvo-filter-slider *ngIf=
            "keyValueObj.key === SearchStepKeys.priceExclVatEuro ||
            keyValueObj.key === SearchStepKeys.horsePower ||
            keyValueObj.key === SearchStepKeys.mileageKm ||
            keyValueObj.key === SearchStepKeys.dateFirstRegistration"
            [aggregationTypeName]="keyValueObj.key"
            [step]="keyValueObj.value" [aggregations]="aggregations[keyValueObj.key]"
            (filterChange)="onFilterChange($event, keyValueObj.key)"
            (filterReset)="onFilterReset($event, keyValueObj.key)" [attr.data-testId]="keyValueObj.key">
          </volvo-filter-slider>
        </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</mat-accordion>
