<section class="buyersGuide">
  <div class="buyersGuide-div">
    <div class="buyersGuide-div-wrapper">
      <div class="buyersGuide-div-wrapper-divpadding">
        <img
          [src]="assetService.getAssetUrl('assets/pages/buyersGuide/volvoFH16.webp')"
          class="volvo-imgDiv-volvoImg"
        />
        <div class="buyersGuide-div-wrapper-title">
          <span>{{ "uk/pages/buyersGuide/volvoFH16" | lowercase | translate }}</span>
        </div>
        <div class="buyersGuide-div-wrapper-text">
          <span>{{ "uk/pages/buyersGuide/volvoFH16/text1" | lowercase | translate }}</span>
        </div>
        <div>
          <button
            (click)="routePage('volvoFH16');logTruckModelButtonClick('Volvo FH16')"
            class="buyersGuide-div-wrapper-button"
          >
            <span>
              <div>
                <div>
                  <span class="buyersGuide-div-wrapper-button-text"
                    >Read More</span
                  >
                </div>
                <div>
                  <i class="fal fa-chevron-right"></i>
                </div>
              </div>
            </span>
          </button>
        </div>
      </div>
      <div>
        <img
          src="assets/pages/buyersGuide/volvoFH.webp"
          class="volvo-imgDiv-volvoImg"
        />
        <div class="buyersGuide-div-wrapper-title">
          <span>{{ "uk/pages/buyersGuide/volvoFH" | lowercase | translate }}</span>
        </div>
        <div class="buyersGuide-div-wrapper-text">
          <span>{{ "uk/pages/buyersGuide/volvoFH/text" | lowercase | translate }}</span>
        </div>
        <div>
            <button
              (click)="routePage('volvoFH');logTruckModelButtonClick('Volvo FH')"
              class="buyersGuide-div-wrapper-button"
            >
              <span>
                <div>
                  <div>
                    <span class="buyersGuide-div-wrapper-button-text"
                      >Read More</span
                    >
                  </div>
                  <div>
                    <i class="fal fa-chevron-right"></i>
                  </div>
                </div>
              </span>
            </button>
          </div>
      </div>
    </div>
    <div class="buyersGuide-div-wrapper">
      <div class="buyersGuide-div-wrapper-divpadding">
        <img
          src="assets/pages/buyersGuide/volvoFM.webp"
          class="volvo-imgDiv-volvoImg"
        />
        <div class="buyersGuide-div-wrapper-title">
          <span>{{ "uk/pages/buyersGuide/volvoFM" | lowercase | translate }}</span>
        </div>
        <div class="buyersGuide-div-wrapper-text">
          <span>{{ "uk/pages/buyersGuide/volvoFM/text" | lowercase | translate }}</span>
        </div>
        <div class="buyersGuide-div-wrapper-buttonDiv">
            <button
              (click)="routePage('volvoFM');logTruckModelButtonClick('Volvo FM')"
              class="buyersGuide-div-wrapper-button"
            >
              <span>
                <div>
                  <div>
                    <span class="buyersGuide-div-wrapper-button-text"
                      >Read More</span
                    >
                  </div>
                  <div>
                    <i class="fal fa-chevron-right"></i>
                  </div>
                </div>
              </span>
            </button>
          </div>
      </div>
      <div>
        <img
          src="assets/pages/buyersGuide/volvoFMX.webp"
          class="volvo-imgDiv-volvoImg"
        />
        <div class="buyersGuide-div-wrapper-title">
          <span>{{ "uk/pages/buyersGuide/volvoFMX" | lowercase | translate }}</span>
        </div>
        <div class="buyersGuide-div-wrapper-text">
          <span>{{ "uk/pages/buyersGuide/volvoFMX/text" | lowercase | translate }}</span>
        </div>
        <div>
            <button
              (click)="routePage('volvoFMX');logTruckModelButtonClick('Volvo FMX')"
              class="buyersGuide-div-wrapper-button"
            >
              <span>
                <div>
                  <div>
                    <span class="buyersGuide-div-wrapper-button-text"
                      >Read More</span
                    >
                  </div>
                  <div>
                    <i class="fal fa-chevron-right"></i>
                  </div>
                </div>
              </span>
            </button>
          </div>
      </div>
    </div>
    <div class="buyersGuide-div-wrapper">
      <div class="buyersGuide-div-wrapper-divpadding">
        <img
          src="assets/pages/buyersGuide/volvoFE.webp"
          class="volvo-imgDiv-volvoImg"
        />
        <div class="buyersGuide-div-wrapper-title">
          <span>{{ "uk/pages/buyersGuide/volvoFE" | lowercase | translate }}</span>
        </div>
        <div class="buyersGuide-div-wrapper-text">
          <span>{{ "uk/pages/buyersGuide/volvoFE/text" | lowercase | translate }}</span>
        </div>
        <div>
            <button
              (click)="routePage('volvoFE');logTruckModelButtonClick('Volvo FE')"
              class="buyersGuide-div-wrapper-button"
            >
              <span>
                <div>
                  <div>
                    <span class="buyersGuide-div-wrapper-button-text"
                      >Read More</span
                    >
                  </div>
                  <div>
                    <i class="fal fa-chevron-right"></i>
                  </div>
                </div>
              </span>
            </button>
          </div>
      </div>
      <div>
        <img
          src="assets/pages/buyersGuide/volvoFL.webp"
          class="volvo-imgDiv-volvoImg"
        />
        <div class="buyersGuide-div-wrapper-title">
          <span>{{ "uk/pages/buyersGuide/volvoFL" | lowercase | translate }}</span>
        </div>
        <div class="buyersGuide-div-wrapper-text">
          <span>{{ "uk/pages/buyersGuide/volvoFL/text" | lowercase | translate }}</span>
        </div>
        <div>
            <button
              (click)="routePage('volvoFL');logTruckModelButtonClick('Volvo FL')"
              class="buyersGuide-div-wrapper-button"
            >
              <span>
                <div>
                  <div>
                    <span class="buyersGuide-div-wrapper-button-text"
                      >Read More</span
                    >
                  </div>
                  <div>
                    <i class="fal fa-chevron-right"></i>
                  </div>
                </div>
              </span>
            </button>
          </div>
      </div>
    </div>
  </div>
</section>

<volvo-footer></volvo-footer>
