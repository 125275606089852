import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Store } from '@ngxs/store';
import { combineLatest, Subscription } from 'rxjs';

import { ContactDealershipPopupComponent } from '~/popups/contact-dealership-popup/contact-dealership-popup.component';
import {
  DealerPlaceResult,
  GeocoderResult,
  GeocoderStatus,
  Map,
  UserLocation,
} from '~/core/store/dealerships/dealerships.model';
import { DealershipsService } from '~/core/store/dealerships/dealerships.service';
// import { BucketsService } from '~/core/store/buckets/buckets.service';
import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { SearchState } from '~/core/store/search/search.state';
import { LocalesStateCountryList } from '../../../core/store/locales/locales.model';

interface Place {
  hasOpenHours: boolean;
  isOpen: boolean;
  weekdayText: string[];
}
@AutoUnsubscribe()
@Component({
  selector: 'volvo-contact-page-dealership',
  templateUrl: './contact-page-dealership.component.html',
  styleUrls: ['./contact-page-dealership.component.scss'],
})
export class ContactPageDealershipComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('mapContainer') gmap: ElementRef;
  map: Map;
  searchInput: string;
  checkbox: any;
  isBrowser: boolean;
  userLocation: { lat: number; lng: number };
  dealershipLocations: DealerPlaceResult[] = null;
  dealershipLocationsDump: DealerPlaceResult[] = null;

  selectedCountry: string;

  geolocationWasCancelByUser = false;
  selectedFilterCountry: string = 'All Markets';
  autoSelectCountry: LocalesStateCountryList = null;
  private s0: Subscription;
  place: Place = {
    hasOpenHours: false,
    isOpen: false,
    weekdayText: [],
  };

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private mailApiService: MailApiService,
    private globalUtils: GlobalUtils,
    public dealershipsService: DealershipsService,
    private ref: ChangeDetectorRef,
    private searchState: SearchState
  ) {
    this.isBrowser = this.globalUtils.isBrowser();

    //Make dealership dropdown auto selected based on country selection from regional popup.
    this.autoSelectCountry = this.searchState.getCurrentCountry();
    this.getDealershipLocations();
    if (this.autoSelectCountry.id) { //id/country will be null Europe/English (Default)
      this.selectedFilterCountry = this.autoSelectCountry.label;
      this.filterByCountry(this.selectedFilterCountry);
    }
    else {
      this.setDefaultDealershipLocations();
    }
  }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.mapInitializer();
      this.initSubscriptions();
      this.ref.detectChanges(); // update component
    }
  }

  goToAddress(dealer): void {
    dealer.isExpanded = !dealer.isExpanded;
    if (dealer.isExpanded) {
      // move google map only when user click to expend
      this.map.setCenter(dealer.geometry.location);
      this.map.setZoom(10);
      //this.selectedFilterCountry = dealer.stockLocation.country;
    }
  }

  goToCountry(country, zoom): void {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ address: country }, (results: GeocoderResult[], status: GeocoderStatus) => {
      if (status == google.maps.GeocoderStatus.OK) {
        let county = results[0].geometry.location;
        this.map.setCenter(results[0].geometry.location);
        this.map.setZoom(zoom);
      }
    });
  }

  setDefaultDealershipLocations(): void {
    if(this.dealershipLocationsDump){
      this.dealershipLocations = this.dealershipLocationsDump.slice();
      this.dealershipLocations = this.dealershipLocations.sort((a: DealerPlaceResult, b: DealerPlaceResult) => {
        return (a.stockLocation.country > b.stockLocation.country ? 1 : a.stockLocation.country < b.stockLocation.country ? -1 : 0);
      });
    }
    this.selectedFilterCountry = 'All Markets';
  }

  filterByCountry(country: string): void {
    if (country === 'All Markets') {
      this.setDefaultDealershipLocations();
      this.goToCountry('europe', 4);
    } else {
      this.selectedCountry = country.toLowerCase();
      if(this.dealershipLocationsDump){
        this.dealershipLocations = this.dealershipLocationsDump.filter((item) =>
          Boolean(item.stockLocation.country.toLowerCase().indexOf(this.selectedCountry) >= 0)
        );
        //This check is needed when a country does not exists in dealership json that is selected from regional popup
        if (this.dealershipLocations.length === 0) {
          this.setDefaultDealershipLocations();
          this.goToCountry('europe', 4);
        }
        else
          this.goToCountry(this.selectedCountry, 6);
      }
    }
  }

  onFilterByCountry(opened: boolean): void {
    if (!opened && this.selectedFilterCountry) {
      this.filterByCountry(this.selectedFilterCountry);
    }
  }

  onSearch(): void {
    if (this.searchInput.length === 0) {
      this.dealershipLocations = [...this.dealershipLocationsDump];
    } else if (this.searchInput && this.dealershipLocationsDump) {
      const filterValue = this.searchInput.toLowerCase();
      this.dealershipLocations = this.dealershipLocationsDump.filter((item) => {
        const dealershipItem = (item.name + item.formatted_address).toLowerCase();
        // search by name and address
        return dealershipItem.indexOf(filterValue) >= 0;
      });
    }
  }

  onContactDealershipClick(item: DealershipsService): void {
    this.dialog.open(ContactDealershipPopupComponent, {
      panelClass: 'volvo-popup',
      data: item,
    });
  }

  private mapInitializer() {
    const goteborg = { lat: 57.7089, lng: 11.9746 };
    this.map = new google.maps.Map(this.gmap.nativeElement, {
      center: goteborg,
      zoom: 8,
      gestureHandling: 'cooperative',
    });
  }

  private onUserAllowGeolocation(userLocation: UserLocation): void {
    // this.userLocation = {
    //   lat: userLocation.lat,
    //   lng: userLocation.lng,
    // };

    // new google.maps.Marker({
    //   map: this.map,
    //   position: this.userLocation,
    //   icon: 'assets/svg/userMarker.svg',
    // });

    // // update map center if we have user position
    // this.map.setCenter(this.userLocation);
    // this.map.setZoom(4);

    // const geocoder = new google.maps.Geocoder();
    // geocoder.geocode({ location: this.userLocation }, (results: GeocoderResult[], status: GeocoderStatus) => {
    //   if (status === google.maps.GeocoderStatus.OK && results[0]) {
    //     const country = this.getCountry(results);
    //     if (country) {
    //       // sort dealerships by user country
    //       this.sortDealershipByUserCountry(country);
    //       //this.sortDealerShipByCountry(country)
    //       
    //     }
    //   }
    // });
  }

  private getCountry(results: GeocoderResult[]): string {
    for (const item of results) {
      if (item.types.indexOf('country') !== -1) {
        return item.formatted_address;
      }
    }
  }

  private sortDealershipByUserCountry(country: string): void {
    this.dealershipLocations.sort((a: DealerPlaceResult, b: DealerPlaceResult) => {
      const aIndex = a.formatted_address.indexOf(country);
      const bIndex = b.formatted_address.indexOf(country);

      const aRes = aIndex > 1 ? 1 : aIndex;
      const bRes = bIndex > 1 ? 1 : bIndex;

      if (aRes > bRes) {
        return -1;
      } else if (aRes < bRes) {
        return 1;
      }
      return 0;
    });
  }

  private sortDealerShipByCountry(country: string): void {
    this.dealershipLocations.sort((a: DealerPlaceResult, b: DealerPlaceResult) => {
      return a.stockLocation.country > b.stockLocation.country ? 1 : a.stockLocation.country < b.stockLocation.country ? -1 : 0;
    });
  }

  private addDealershipsToMap(dealerships: DealerPlaceResult[]): void {
    for (const dealership of dealerships) {
      // add marker to the map
      dealership.marker = new google.maps.Marker({
        map: this.map,
        position: dealership.geometry.location,
        icon: 'assets/svg/marker.svg',
      });

      // add listener on marker click
      google.maps.event.addListener(dealership.marker, 'click', () => {
        dealership.isExpanded = true;
        this.searchInput = dealership.formatted_address;
        //this.onSearch();
        this.selectedFilterCountry = dealership.stockLocation.country;
        this.filterByCountry(this.selectedFilterCountry);
      });

      if (this.geolocationWasCancelByUser) {
        // get first dealer from a list
        // and set it as map center

        this.map.setCenter(dealership.geometry.location);
        this.geolocationWasCancelByUser = false;
      }

      if (dealership.opening_hours) {
        this.place = {
          hasOpenHours: true,
          isOpen: true,
          weekdayText: dealership.opening_hours.weekday_text,
        };
      }
    }
  }

  private initSubscriptions(): void {
    this.dealershipsService.dealershipsCountries$.subscribe(d => {
      
    });

    this.s0 = combineLatest([this.dealershipsService.dealerships$, this.dealershipsService.userLocation$]).subscribe(
      ([dealershipLocations, userLocation]) => {
        if (dealershipLocations) {
          this.addDealershipsToMap(dealershipLocations);
          if (userLocation && userLocation.isAllowed) {
            this.onUserAllowGeolocation(userLocation);
          }
        }
      }
    );
  }

  private getDealershipLocations(): void {
    this.s0 = combineLatest([this.dealershipsService.dealerships$, this.dealershipsService.userLocation$]).subscribe(
      ([dealershipLocations, userLocation]) => {
        if (dealershipLocations) {
          this.dealershipLocationsDump = dealershipLocations;
          this.filterByCountry(this.selectedFilterCountry);
        }
      }
    );
  }
}
