<section class="whoweare">
    <div class="whoweare-mobile">
        <img src="{{imageUrl}}" class="whoweare-imageDiv" [ngClass]="region=== regionEnum.TX? 'whoweare-tximageDiv' : ''" />
    </div>
    <div class="whoweare-title">
        <span>{{ "uk/pages/aboutUs/whoweare" | lowercase | translate }}</span>
    </div>
    <div class="whoweare-text" *isUKSite>
        <span class="whoweare-text-medium">{{ "uk/pages/aboutUs/whowearetext" | lowercase | translate }}</span>
        <span>{{ "uk/pages/aboutUs/whowearetext1" | lowercase | translate }}</span>
    </div>
    <div class="whoweare-text" *isNSSite>
        <span class="whoweare-text-medium">{{ "uk/pages/aboutUs/whowearetextNS" | lowercase | translate }}</span>
        <span>{{ "uk/pages/aboutUs/whowearetextNS1" | lowercase | translate }}</span>
    </div>
    <div class="whoweare-text" *isTXSite>
        <span style="font-family:'Volvo Novum medium'">{{ "uk/pages/aboutus/whowearetext" | lowercase | translate }}</span>
        <span [innerHTML]="'uk/pages/aboutUs/whowearetext1'| lowercase | translate"></span>
    </div>
    <div class="whoweare-text" *isUKNSSite>
        <span *ngIf="region=== regionEnum.UK">{{ "uk/pages/aboutUs/whowearetext2" | lowercase | translate }}</span>
        <span *ngIf="region=== regionEnum.NS">{{ "uk/pages/aboutUs/whowearetextNS2" | lowercase | translate }}</span>
    </div>
    <div class="whoweare-bestchoice">
        <span>{{ "uk/pages/aboutUs/bestchoice" | lowercase | translate }}</span>
    </div>
    <div class="whoweare-sections">
        <div class="whoweare-desktop">
            <div style="display:flex;">
                <div class="whoweare-heading">
                    <div>
                        <img [src]="region=== regionEnum.UK || region=== regionEnum.NS?'assets/pages/aboutUs/Icon Shape1.webp':'assets/pages/aboutUs/Icon Shape4.webp'"
                            class="whoweare-heading-imageDiv" />
                    </div>
                    <div class="whoweare-heading-title">
                        <span>{{ "uk/pages/aboutUs/selection" | lowercase | translate }}</span>
                    </div>
                    <div class="whoweare-heading-text">
                        <span *ngIf="region=== regionEnum.NS">{{ "uk/pages/aboutUs/selectiontextNS" | lowercase | translate }}</span>
                        <span *ngIf="region=== regionEnum.UK">{{ "uk/pages/aboutUs/selectiontext" | lowercase | translate }}</span>
                        <span *ngIf="region=== regionEnum.TX">{{ "uk/pages/aboutUs/selectiontext" | lowercase | translate }}</span>
                    </div>
                </div>
                <div class="whoweare-heading">
                    <div class="whoweare-heading-images">
                        <img [src]="region=== regionEnum.UK || region=== regionEnum.NS?'assets/pages/aboutUs/Icon Shape2.webp':'assets/pages/aboutUs/Icon Shape5.webp'"
                            class="whoweare-heading-imageDiv" />
                    </div>
                    <div class="whoweare-heading-title">
                        <span>{{ "uk/pages/aboutUs/retaillocation" | lowercase | translate }}</span>
                    </div>
                    <div class="whoweare-heading-text">
                        <span *ngIf="region=== regionEnum.NS">{{ "uk/pages/aboutUs/retaillocationtextNS" | lowercase | translate }}</span>
                        <span *ngIf="region=== regionEnum.UK">{{ "uk/pages/aboutUs/retaillocationtext" | lowercase | translate }}</span>
                        <span *ngIf="region=== regionEnum.TX" [innerHTML]="'uk/pages/aboutUs/retaillocationtext'| lowercase | translate"></span>
                    </div>
                </div>
                <div class="whoweare-heading">
                    <div class="whoweare-heading-images">
                        <img [src]="region=== regionEnum.UK || region=== regionEnum.NS?'assets/pages/aboutUs/Icon Shape3.webp':'assets/pages/aboutUs/Icon Shape6.webp'"
                            class="whoweare-heading-imageDiv" />
                    </div>
                    <div class="whoweare-heading-title">
                        <span>{{ "uk/pages/aboutUs/quality" | lowercase | translate }}</span>
                    </div>
                    <div class="whoweare-heading-text">
                        <span [innerHTML]="'uk/pages/aboutUs/qualitytext'| lowercase | translate"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="whoweare-mobile">
            <div class="whoweare-heading" style="display:flex;">
                    <div>
                            <img [src]="region=== regionEnum.UK || region=== regionEnum.NS?'assets/pages/aboutUs/Icon Shape1.webp':'assets/pages/aboutUs/Icon Shape4.webp'"
                                class="whoweare-heading-imageDiv" />
                        </div>
                <div>
                    <div class="whoweare-heading-title">
                        <span>{{ "uk/pages/aboutUs/selection" | lowercase | translate }}</span>
                    </div>
                    <div class="whoweare-heading-text">
                        <span *ngIf="region=== regionEnum.NS">{{ "uk/pages/aboutUs/selectiontextNS" | lowercase | translate }}</span>
                        <span *ngIf="region=== regionEnum.UK">{{ "uk/pages/aboutUs/selectiontext" | lowercase | translate }}</span>
                        <span *ngIf="region=== regionEnum.TX">{{ "uk/pages/aboutUs/selectiontext" | lowercase | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="whoweare-heading" style="display:flex;">
                    <div class="whoweare-heading-images">
                            <img [src]="region=== regionEnum.UK || region=== regionEnum.NS?'assets/pages/aboutUs/Icon Shape2.webp':'assets/pages/aboutUs/Icon Shape5.webp'"
                                class="whoweare-heading-imageDiv" />
                        </div>
                <div>
                    <div class="whoweare-heading-title">
                        <span>{{ "uk/pages/aboutUs/retaillocation" | lowercase | translate }}</span>
                    </div>
                    <div class="whoweare-heading-text">
                        <span *ngIf="region=== regionEnum.NS">{{ "uk/pages/aboutUs/retaillocationtextNS" | lowercase | translate }}</span>
                        <span *ngIf="region===regionEnum.UK">{{ "uk/pages/aboutUs/retaillocationtext" | lowercase | translate }}</span>
                        <span *ngIf="region=== regionEnum.TX" [innerHTML]="'uk/pages/aboutUs/retaillocationtext'| lowercase | translate"></span>
                    </div>
                </div>
            </div>
            <div class="whoweare-heading" style="display:flex;">
                    <div class="whoweare-heading-images">
                            <img [src]="region=== regionEnum.UK || region=== regionEnum.NS?'assets/pages/aboutUs/Icon Shape3.webp':'assets/pages/aboutUs/Icon Shape6.webp'"
                                class="whoweare-heading-imageDiv" />
                        </div>
                <div>
                    <div class="whoweare-heading-title">
                        <span>{{ "uk/pages/aboutUs/quality" | lowercase | translate }}</span>
                    </div>
                    <div class="whoweare-heading-text">
                            <span [innerHTML]="'uk/pages/aboutUs/qualitytext'| lowercase | translate"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *isUKNSSite>
        <div class="whoweare-bestchoice">
            <span>{{ "uk/pages/aboutUs/truckpreparation" | lowercase | translate }}</span>
        </div>
        <div class="whoweare-text">
            <span>{{ "uk/pages/aboutUs/truckpreparationtext" | lowercase | translate }}</span>
        </div>
    </div>
    <div *isTXSite class="tx">
        <div class="tx-imgdiv">
           <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/vehiclepreparationsection.webp')">
           <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/T&E-usedtruck customisation.webp')" class="tx-divpadding">
        </div>
        <div class="whoweare-bestchoice" style="font-family: 'volvo novum medium';padding-left:0px">
            <span>{{ "uk/pages/aboutUs/truckpreparation" | lowercase | translate }}</span>
        </div>
        <div class="whoweare-heading-text">
            <span [innerHTML]="'uk/pages/aboutus/truckpreparationtext' | lowercase | translate"></span>
        </div>
    </div>
</section>
<div class="mobile">
    <volvo-footer ></volvo-footer>
</div>
