<div style="background-color: #F6F6F6;">
  <div class="VolvoLogo">
    <img alt="Volvo Selected Services" [src]="assetService.getAssetUrl('assets/logo.png')" arrowLazyImage />
    <i (click)="onClose()" class="PopupOLD-close material-icons paddingTop">close</i>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="PopupOLD-form">
    <div class="PopupOLD">
      <h3 class="PopupOLD-title" data-cy="contactpopup-title">
        {{ 'popups/contactDealership/title' | lowercase | translate }}
      </h3>
      <!--    DOTO: subtitle should be dealership name-->
      <h5 class="PopupOLD-subtitle" data-cy="contactpopup-subtitle">
        {{ data.stockLocation.stockLocationName }}
      </h5>
      <div class="PopupOLD-input">
        <h6 class="Vfs-input-title" data-cy="contactpopup-maintitle">
          {{ 'contact/form/subject/title' | lowercase | translate }}
        </h6>
        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch" data-cy="contactpopup-subject">
          <mat-select formControlName="subject" class="volvofont"
            [placeholder]="'contact/form/subject/placeholder' | lowercase | translate">
            <mat-option class="Option volvofont CPrimary" value="Sales and Product Inquiries"
              data-cy="contactpopup-subjectitem1">
              {{ 'contact/form/subject/SalesAndProduct' | lowercase | translate }}</mat-option>
            <mat-option class="Option volvofont CPrimary" value="Financing Service" data-cy="contactpopup-subjectitem2">
              {{ 'contact/form/subject/financingService' | lowercase | translate }}</mat-option>
            <mat-option class="Option volvofont CPrimary" value="Product and Service Support"
              data-cy="contactpopup-subjectitem3">
              {{ 'contact/form/subject/productAndServiceSupport' | lowercase | translate }}</mat-option>
            <mat-option class="Option volvofont CPrimary" value="Feedback" data-cy="contactpopup-subjectitem4">{{
              'contact/form/subject/feedback' | lowercase | translate
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="PopupOLD-input">
        <h6 class="Vfs-input-title">{{ 'form/fullName' | lowercase | translate }}</h6>
        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
          <input formControlName="fullName" matInput data-cy="contactpopup-fullname" />
        </mat-form-field>
      </div>
      <div class="PopupOLD-input">
        <h6 class="Vfs-input-title">
          {{ 'form/phoneNumber' | lowercase | translate }}
        </h6>
        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
          <input formControlName="phone" type="tel" matInput data-cy="contactpopup-phnumber" />
        </mat-form-field>
      </div>

      <div class="PopupOLD-input">
        <h6 class="Vfs-input-title">{{ 'form/email' | lowercase | translate }}</h6>
        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
          <input formControlName="email" type="email" matInput data-cy="contactpopup-email" />
        </mat-form-field>
      </div>

      <div class="PopupOLD-input">
        <h6 class="Vfs-input-title">
          {{ 'form/message' | lowercase | translate }}
          <span>({{ 'form/optional' | lowercase | translate }})</span>
        </h6>
        <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch">
          <textarea formControlName="message" matInput rows="5"></textarea>
        </mat-form-field>
      </div>

      <div class="MatInputSearchVfs checkboxPadding">
        <mat-checkbox formControlName="privacy" (click)="recaptcha()" class="big" data-cy="contactpage-privacychkbox">
          <div class="Vfs-privacy">
            {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
            <a class="privacyPolicy regularFont" href="{{ checkbox }}" target="_blank">
              <span>{{ 'form/privacyPolicy' | lowercase | translate }}</span>
            </a>
          </div>
        </mat-checkbox>
      </div>
      
      <div *ngIf="showRecaptcha" class="PopupOLD-input" style="margin-bottom: 15px;display: inline-block;">
        <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required></re-captcha>
      </div>

      <div  class="buttonCss">
        <button type="submit" 
           data-cy="contactpopup-submitbtn" [ngClass]="this.form.invalid || !reCaptchaRes?'buttonCss-btnDisbaleColor':''" >
          {{ 'form/sendMessage' | lowercase | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
