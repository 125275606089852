<div class="Stock-search">
  <volvo-stock-search class="Stock-stockSearchInput"></volvo-stock-search>

  <volvo-stock-options-mobile class="Stock-stockOptionsMobile"></volvo-stock-options-mobile>
  <!-- <h6 *ngIf="isAnySelected" [@toggleHeight] class="Stock-filtersTitle">
    <span (click)="resetAllFilters()" class="VolvoMatBadge">{{ 'globalMiss/resetFilter' | lowercase | translate
      }}</span>
  </h6> -->
</div>
<div class="notification-isMobile">
  <div>
    <volvo-search-chip [fromNotification]="false" class="notification-chips"></volvo-search-chip>
  </div>
  <!-- <div *ngIf="isAnySelected" class="StockSearchChip-reset hideMobile" (click)="resetAllFilters()">{{'popups/filter/clearFilters' | lowercase | translate}}</div> -->
</div>
<section class="Stock">
  <div class="Stock-wp">
    <div class="Stock-filters">
      <div class="Stock-stickyBox">
        <div *ngIf="notificationFeature" class="notification">
          <button (click)="onSetNotificationClick()">
            <img [src]="assetService.getAssetUrl('assets/icons/bell1.svg')" />{{ 'search/notification' | lowercase| translate }}
          </button>
        </div>
        <volvo-filter class="Stock-CustomPadding"></volvo-filter>
        <mat-divider class="Stock-CustomPadding"></mat-divider>
        <div *ngIf="isSelectedSite" (click)="onDealershipClick()" mat-button
          class="BtnAuto Stock-dealershipLocation volvofontmedium Stock-CustomPadding">
          <i class="fas fa-map-marker-alt CPrimary"></i>&nbsp;{{ 'popups/dealership/title' | lowercase | translate }}
          <span *ngIf="dealershipLocationCount > 0"> ({{ dealershipLocationCount }}) </span>
        </div>
      </div>
    </div>
    <div *ngIf="notSeenNotificationHighlight && highlightFeature" class="notification-banner">
      <div class="notification-banner-popup">
        <div class="notification-banner-popup-title">
          {{'search/notification/highlight' | lowercase| translate}}
        </div>
        <div>
          <i (click)="onHighlightNotificatinClose()" class="material-icons notification-banner-popup-close">close</i>
        </div>
      </div>
    </div>
    <div *ngIf="regionalSettings.site!=='ukwebportal'"class="Stock-trucksList" [class.isCards]="cardsType === TruckCardTypes.card">
      <div class="Stock-stockOptionSort">
        <volvo-stock-options [isVehicleFound]="false" class="Stock-stockOptions"></volvo-stock-options>
        <volvo-stock-toggle-view [cardsType]="cardsType" (typeChange)="onCardsType($event)"
          class="Stock-stockToggleView">
        </volvo-stock-toggle-view>
        <volvo-stock-options *ngIf="(searchService.grandTotalTrucks$ | async) > 0 && isLoaded" [isVehicleFound]="true"
          class="Stock-stockOptions"></volvo-stock-options>
      </div>

      <ng-container *ngIf="(searchService.loaded$ | async); else loading">
        <ng-container *ngIf="(searchService.totalTrucks$ | async) > 0 && otherTrucksLength==0">
          <volvo-truck @listItem *ngFor="
              let product of trucks
                | paginate
                  : { itemsPerPage: 12, currentPage: search.page, totalItems: searchService.totalTrucks$ | async };
              let index = index;
              trackBy: trackByFn
            " [truck]="product" [cardType]="cardsType" class="Stock-truck">
          </volvo-truck>
        </ng-container>
        <div class="otherVehicles" *ngIf="showNoStock" style="font-family: 'Volvo Novum Medium'">
          <br />
          <span>
            {{ 'globalmiss/novehiclestock' | lowercase | translate }}
          </span>
        </div>
        <ng-container *ngIf="(searchService.grandTotalTrucks$ | async) > 0; else emptyStock">
            <div class="truckDiv" *ngFor="
            let product of otherTrucks
              | paginate
                : { itemsPerPage: 12, currentPage: search.page, totalItems: searchService.grandTotalTrucks$ | async };
            let index = index;let first = first;
            trackBy: trackByFn
          ">
          <div class="otherVehicles" *ngIf="(((12*(selectedPageNo - 1) + index+1))===((searchService.totalTrucks$ | async))+1) || (((12*(selectedPageNo - 1) + index))>((searchService.totalTrucks$ | async)) && first) || (((searchService.totalTrucks$ | async)===0) && first)">
            <br />
            <span> {{ 'globalmiss/othervehiclesinstock' | lowercase | translate }}: </span>
            <br />
          </div>
          <volvo-truck @listItem  [truck]="product" [cardType]="cardsType" class="Stock-truck">
            </volvo-truck>
          </div>
        </ng-container>
        <div *ngIf="notificationFeature" class="notification-footer">
          <div>{{ 'search/notification/getnotifiedtext' | lowercase| translate }}</div>
          <div class="notification notification-padding">
            <button (click)="onSetNotificationClick()" style="width:auto !important;">
              <img [src]="assetService.getAssetUrl('assets/icons/bell1.svg')" />{{ 'search/notification' | lowercase| translate }}
            </button>
          </div>
        </div>
        <volvo-stock-pagination (loading)="onPageLoading($event)" class="Stock-pages"
          *ngIf="(searchService.grandTotalTrucks$ | async) > 12" [total]="searchService.grandTotalTrucks$ | async"
          [search]="search"></volvo-stock-pagination>
      </ng-container>
    </div>
    <!--UK start here-->
    <div *ngIf="regionalSettings.site==='ukwebportal'" class="Stock-trucksList" [class.isCards]="cardsType === TruckCardTypes.card">
      <div class="Stock-stockOptionSort">
        <volvo-stock-options *ngIf="currentCategory!=='Landing'" [isVehicleFound]="false" class="Stock-stockOptions"></volvo-stock-options>
        <div class="Stock-link" *ngIf="showCategoryButtons">
          <volvo-stock-link-page></volvo-stock-link-page>
        </div>
        <volvo-stock-options *ngIf="(searchService.totalTrucks$ | async) > 0 && isLoaded" [isVehicleFound]="true"
          class="Stock-stockOptions"></volvo-stock-options>
      </div>
      <h2 *ngIf="currentCategory==='Landing'" class="Stock-trucksList-title" data-cy="ourStock-trucktitle">
        {{ "home/newInStock" | lowercase | translate }}
      </h2>
      <div *ngIf="currentCategory">
        <div class="Stock-stockOptionSort" *ngIf="currentCategory==='Landing'">
          <volvo-truck *ngFor="let product of newTrucks" [truck]="product" [cardType]="cardsType"
            class="Stock-trucksList"></volvo-truck>
        </div>
        <ng-container *ngIf="(searchService.loaded$ | async); else loading">
          <div class="Stock-stockOption" *ngIf="currentCategory!=='Landing'">
            <volvo-truck @listItem *ngFor="
            let product of trucks
              | paginate
                : { itemsPerPage: 12, currentPage: search.page, totalItems: searchService.totalTrucks$ | async };
            let index = index;
            trackBy: trackByFn
          " [truck]="product" [cardType]="cardsType" class="Stock-trucksList">
            </volvo-truck>
          </div>
        </ng-container>
        <ng-template #loading>
            <div [class.isCards]="cardsType === TruckCardTypes.card" class="Stock-trucksList">
              <volvo-truck *ngFor="let product of [0, 1, 2, 3, 4, 5,6,7,8,9,10,11]" [isSkeleton]="true" [cardType]="cardsType"
                class="Stock-truck">
              </volvo-truck>
            </div>
          </ng-template>
          <div  class="notification-footer">
            <div>{{ 'search/notification/getnotifiedtext' | lowercase| translate }}</div>
            <div class="notification notification-padding">
              <button (click)="onSetNotificationClick()" style="width:auto !important;">
                <img [src]="assetService.getAssetUrl('assets/icons/bell1.svg')" />{{ 'search/notification' | lowercase| translate }}
              </button>
            </div>
          </div>
      </div>
      
      <volvo-stock-pagination class="Stock-pages" *ngIf="(searchService.totalTrucks$ | async) > 12 && currentCategory!=='Landing'"
          [total]="searchService.totalTrucks$ | async" [search]="search">
      </volvo-stock-pagination>
    </div>
    <!--UK ends here-->
    <ng-template #loading>
      <div [class.isCards]="cardsType === TruckCardTypes.card" class="Stock-trucksList">
        <volvo-truck *ngFor="let product of [0, 1, 2, 3, 4, 5,6,7,8,9,10,11]" [isSkeleton]="true" [cardType]="cardsType"
          class="Stock-truck">
        </volvo-truck>
      </div>
    </ng-template>

    <ng-template #emptyStock>
      <volvo-stock-empty [search]="search"></volvo-stock-empty>
    </ng-template>
    <ng-template #emptyStockSub>
      <!-- <volvo-subscribe style="display: block; width: 100%"></volvo-subscribe> -->
    </ng-template>
  </div>
</section>

<volvo-footer></volvo-footer>