<section class="selected">
  <div class="selected-selectedDiv">
    <div>
      <img [src]="assetService.getAssetUrl('assets/pages/vehicleOffers/UTSelected.webp')" class="selected-selectedImg" />
    </div>
    <div class="selected-title">
      <span>{{ "uk/components/footer/volvoSelected" | lowercase | translate }}</span>
    </div>
    <div class="selected-text">
      <span>{{ "uk/pages/vehicleOffer/selectedText1" | lowercase | translate }}</span>
    </div>
    <div class="selected-text">
      <span class="selected-text-medium">{{ "uk/pages/vehicleOffer/selectedText2" | lowercase | translate }}</span
      >{{ "uk/pages/vehicleOffer/selectedText3" | lowercase | translate }}
    </div>
    <div class="selected-text">
      <span>{{ "uk/pages/vehicleOffer/selectedText4" | lowercase | translate }}</span>
    </div>
  </div>
  <div class="selected-list">
    <ul class="selected-list-title" *ngFor="let list of selectedList">
      {{
        list.type | lowercase | translate
      }}
      <li *ngFor="let label of list.label">
        {{ label.name | lowercase | translate }}
      </li>
    </ul>
  </div>
  <div>
      <span class="selected-disClaimer">{{"uk/pages/vehicleOffer/disclaimer" | lowercase  |translate}}</span>
  </div>
  <div class="volvoFactSheet" id="approved">
    <a href="assets/files/UT Volvo Selected Fact Sheet - April 2024.pdf" target="_blank">
      <button class="navigation-buttonFact" >
        <div>
          <div>
            <span class="navigation-button-text">{{
              "uk/pages/services/selectedfact" | lowercase | translate
            }}</span>
          </div>
          <div>
            <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')" />
          </div>
        </div>
      </button>
    </a>
  </div>
</section>
<volvo-footer></volvo-footer>
