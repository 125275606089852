export class SearchQuery {
  page: number = 1;
  size: number = 12;
  sortQuery: SortQuery;
  site: string;
  region: string;
  requestingFilter: string;
  expandingFilter: string;
  priceRange: PriceRange;
  dateFirstRegistration: DateFirstRegistrationRange;
  mileage: Mileage;
  horsePower: horsePower;
  modelYear: ModelYear;
  freeText: string;

  stockLocationAddress: string;
  stockLocationCountry: string;
  superstructureType: string;
  majorCategory: string;
  axleConfiguration: string;
  make: string;
  model: string;
  emission: string;
  fuelType: string;
  cabType: any;
  stockLocationCity: string;
  advertisementCategory: string;
  offerAndWarranty: string;
}

export enum Direction {
  Asc,
  Desc,
}

export enum SortField {
  Price,
  PublishDate,
}

export interface SortQuery {
  direction: Direction;
  sortField: SortField;
}

export interface PriceRange {
  minPrice: number;
  maxPrice: number;
}

export interface DateFirstRegistrationRange {
  minDateFirstRegistration: number;
  maxDateFirstRegistration: number;
}

export interface Mileage {
  minMileage: number;
  maxMileage: number;
}

export interface horsePower {
  minHorsePower: number;
  maxHorsePower: number;
}

export interface ModelYear {
  modelYearMin: number;
  modelYearMax: number;
}
