<div *ngIf="isSelectedSite; else otherSite">
    <section class="ContactA">
        <h1 class="ContactA-title CPrimary">{{ 'contact/sectionA/title' | lowercase | translate }}</h1>
        <volvo-contact-page-form></volvo-contact-page-form>
        <div id="ourDealerships"></div>
    </section>    
    <section class="ContactB" >
    <div class="ContactB-wp">
        <h2 class="ContactB-title CPrimary">{{ 'aboutUs/aboutTitleB' | lowercase | translate }}</h2>

        <volvo-contact-page-dealership class="ContactB-dealership"></volvo-contact-page-dealership>
    </div>
    </section>
</div>
<ng-template #otherSite>
  <div *ngIf="this.region!==regionEnum.TX">
      <section class="contactUs">
          <div class="contactUs-mobile">
              <div class="contactUs-title topP">
                  <div class="contactUs-title-intro">
                      <span  class="contactUs-title-sales"> {{ "uk/pages/contact/contactSales" | lowercase | translate }}</span>
                  </div>
                  <div class="contactUs-title-intro">
                      {{ "uk/pages/contact/contactPhone" | lowercase | translate }}:
                      <span><a class="mobileNoColor" href="tel:+44 (0)844 579 1198">+44 (0)844 579 1198</a></span>
                  </div>
                  <div class="contactUs-title-intro">
                      {{ "uk/pages/contact/contactEmail" | lowercase | translate }}:
                      <span><a class="mobileNoColor" href="mailto:usedtrucks@volvo.com">usedtrucks@volvo.com</a></span>
                  </div>
              </div>
          </div>
      </section>
      <section class="contactUs">
          <div class="contactUs-mobile"> 
              <div class="centre-links">
                  <button class="centre-linkBtn" *ngFor="let link of navlinks; let i = index" (click)="routePage(link)">
                      <span class="centre-button">
                          <div class="centre-linkBtn-Wrapper">
                              <div class="centre-linkBtn-Wrapper-fontDiv">
                                  <span >
                                      {{ link.label | lowercase | translate }}
                                  </span>
                                  <span class="fontName">
                                      {{ link.name | lowercase | translate }}
                                  </span>
                              </div>
                              <div style="text-align: right;">
                                  <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')">
                              </div>
                          </div>
                      </span>
                  </button>
              </div>
          </div> 
      </section>    
      <div class="contactUs-desktop">
          <volvo-contact-details></volvo-contact-details>
      </div>
  </div>
  <div *isTXSite class="txTop">
      <volvo-centre-banbury></volvo-centre-banbury>
  </div>
</ng-template>
<volvo-footer></volvo-footer>
