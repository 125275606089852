import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { LocalesStateLang } from '~/core/store/locales/locales.model';
import { environment } from '../../../../environments/environment';
import { AppState } from '~/core/store';
import { DealerPlaceResult } from '~/core/store/dealerships/dealerships.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheApiService {
  private getStocklocationsPath = environment.apiUrl + 'Stocklocation/';
  private getPath = 'Dealerships/';
  private setPath = 'Dealerships';

  constructor(private http: HttpClient, private store: Store) { }

  getDealershipsInfo() {
    const region = this.store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    return this.http.get(this.langUrl(this.getPath + region));
  }

  getStocklocations() {
    const region = this.store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    // UK and NS has same company name hence passing UK for NS & TX
    if (region === 'NS' || region === 'TX') {
      return this.http.get(this.getStocklocationsPath + 'UK');
    }

    return this.http.get(this.getStocklocationsPath + region);
  }

  setDealershipsInfo(dealershipsObject: DealerPlaceResult[]) {
    return this.http.post(this.langUrl(this.setPath), dealershipsObject);
  }

  private langUrl(path: string): string {
    const lang = this.store.selectSnapshot<LocalesStateLang>((state: AppState) => state.language.lang);

    const pathWithLang = path.replace(':langId', lang.curLang.id);
    return `${environment.apiUrl}${pathWithLang}`;
  }
}
