<div class="Options">
  <button (click)="onFiltersClick()" mat-button class="Options-btn CPrimary">
    <i class="fas fa-filter font"></i>&nbsp; <b>Filters</b>&nbsp;
    <!-- <span *ngIf="selectedFiltersCount > 0" class="isStepSelected">({{ selectedFiltersCount }})</span> -->
  </button>
  <div *ngIf="notificationFeature" class="notification">
    <button (click)="onSetNotificationClick()">
      <img [src]="assetService.getAssetUrl('assets/icons/bell1.svg')"/><span>{{ 'search/notification' | lowercase | translate }}</span>
    </button>
  </div>
</div>