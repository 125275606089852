<volvo-contact-skeleton *ngIf="isLoading"></volvo-contact-skeleton>
<section class="truckCentre">
    <div class="truckCentre">
        <img [hidden]="isLoading" (load)="onImageLoad()" (error)="onError()" [src]="region===regionEnum.UK?'../../../assets/pages/contactus/Ely.webp':'../../../assets/pages/contactus/ns/washington.webp'" class="truckCentre-centreImg">
    </div>
</section>
<div [hidden]="isLoading">
    <section class="banburryCentre">
        <div class="banburryCentre-title">
            <h1>
                {{region===regionEnum.UK?("uk/pages/contact/elyTruckCentre" | lowercase | translate):("uk/pages/contact/washingtonTruckCentre" | lowercase | translate)}}
            </h1>
        </div>
        <div class="banburryCentre-text" [innerHTML]="region===regionEnum.UK?('uk/pages/contact/elyText' | lowercase | translate):('uk/pages/contact/washingtonCentreText' | lowercase | translate)">
        </div>
    </section>
    <section class="banburryCentre">
        <div class="banburryCentre-flex">
            <div class="banburryCentre-salesTeam">
                <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
            </div>
            <div class="banburryCentre-map">
                <volvo-map-centre [centre]="mapCentre"></volvo-map-centre>
            </div>
        </div>
    </section>
 </div>
    <volvo-footer></volvo-footer>
