export interface Sites {
  site: string;
}

export interface MetaData {
  publishDate: Date;
}

export interface PriceExclVat {
  currencyIsoCode3: string;
  value: number;
}

export interface Pricing {
  priceExclVatEuro: number;
  priceExclVat: PriceExclVat;
}

export interface Salesperson {
  salespersonName: string;
  telephoneNumber: string;
  email: string;
  whatsappNumber: string;
  whatsappNumberSpecified: boolean;
  isMainSalesperson: number;
  salespersonLevel: number;
}

export interface StockLocation {
  stockLocationId: string;
  stockLocationIdentifier: string;
  stockLocationName: string;
  email: string;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  region: string;
  ctdi: string;
  countryCodeISO2: string;
}

export interface Translation {
  languageCodeIso3: string;
  value: string;
}

export interface Description {
  translation: Translation[];
  engPhrase: string;
}

export interface Translation2 {
  languageCodeIso3: string;
  value: string;
}

export interface Comment {
  translation: Translation2[];
  engPhrase: string;
}

export interface Translation3 {
  languageCodeIso3: string;
  value: string;
}

export interface SelectedPlusHeading {
  translation: Translation3[];
  engPhrase: string;
}

export interface Translation4 {
  languageCodeIso3: string;
  value: string;
}

export interface SelectedPlusText {
  translation: Translation4[];
  engPhrase: string;
}

export interface SelectedPlus {
  selectedPlusHeading: SelectedPlusHeading;
  selectedPlusText: SelectedPlusText;
}

export interface Translation5 {
  languageCodeIso3: string;
  value: string;
}

export interface FinancingHeading {
  translation: Translation5[];
  engPhrase: string;
}

export interface Translation6 {
  languageCodeIso3: string;
  value: string;
}

export interface FinancingText {
  translation: Translation6[];
  engPhrase: string;
}

export interface Financing {
  financingHeading: FinancingHeading;
  financingText: FinancingText;
}

export interface Translation7 {
  languageCodeIso3: string;
  value: string;
}

export interface WarrantyHeading {
  translation: Translation7[];
  engPhrase: string;
}

export interface Translation8 {
  languageCodeIso3: string;
  value: string;
}

export interface WarrantyText {
  translation: Translation8[];
  engPhrase: string;
}

export interface Warranty {
  warrantyHeading: WarrantyHeading;
  warrantyText: WarrantyText;
}

export interface Translation9 {
  languageCodeIso3: string;
  value: string;
}

export interface LowMileageHeading {
  translation: Translation9[];
  engPhrase: string;
}

export interface Translation10 {
  languageCodeIso3: string;
  value: string;
}

export interface LowMileageText {
  translation: Translation10[];
  engPhrase: string;
}

export interface LowMileage {
  lowMileageHeading: LowMileageHeading;
  lowMileageText: LowMileageText;
}

export interface VehicleHighlights {
  selectedPlus: SelectedPlus;
  financing: Financing;
  warranty: Warranty;
  lowMileage: LowMileage;
}

export interface Axle {
  WheelNo1: number;
  WheelNo2: number;
  WheelNo3: number;
  WheelNo4: number;
  TyreWidthMm: number;
  TyreProfile: string;
  TyreDimension: number;
  axleNumber: number;
}

export interface TyreTread {
  WheelNo1: number;
  WheelNo2: number;
  WheelNo3: number;
  WheelNo4: number;
  TyreWidthMm: number;
  TyreProfile: string;
  TyreDimension: number;
  axleNumber: number;
}

export interface Translation11 {
  languageCodeIso3: string;
  value: string;
}

export interface CabType {
  translation: Translation11[];
  engPhrase: string;
}

export interface Translation12 {
  languageCodeIso3: string;
  value: string;
}

export interface ChassisHeight {
  translation: Translation12[];
  engPhrase: string;
}

export interface Translation13 {
  languageCodeIso3: string;
  value: string;
}

export interface SpoilerKit {
  translation: Translation13[];
  engPhrase: string;
}

export interface Translation14 {
  languageCodeIso3: string;
  value: string;
}

export interface RearSuspension {
  translation: Translation14[];
  engPhrase: string;
}

export interface Translation15 {
  languageCodeIso3: string;
  value: string;
}

export interface FrontSuspension {
  translation: Translation15[];
  engPhrase: string;
}

export interface Translation16 {
  languageCodeIso3: string;
  value: string;
}

export interface AxleConfiguration {
  translation: Translation16[];
  engPhrase: string;
}

export interface Translation17 {
  languageCodeIso3: string;
  value: string;
}

export interface AxleSpecification {
  translation: Translation17[];
  engPhrase: string;
}

export interface Translation18 {
  languageCodeIso3: string;
  value: string;
}

export interface RearAxleType {
  translation: Translation18[];
  engPhrase: string;
}

export interface Translation19 {
  languageCodeIso3: string;
  value: string;
}

export interface Brakes {
  translation: Translation19[];
  engPhrase: string;
}

export interface Translation20 {
  languageCodeIso3: string;
  value: string;
}

export interface AdditionalBrakes {
  translation: Translation20[];
  engPhrase: string;
}

export interface Translation21 {
  languageCodeIso3: string;
  value: string;
}

export interface PowerTakeOff {
  translation: Translation21[];
  engPhrase: string;
}

export interface Translation22 {
  languageCodeIso3: string;
  value: string;
}

export interface AirCondition {
  translation: Translation22[];
  engPhrase: string;
}

export interface Translation23 {
  languageCodeIso3: string;
  value: string;
}

export interface ServiceRepairHistory {
  translation: Translation23[];
  engPhrase: string;
}

export interface Translation24 {
  languageCodeIso3: string;
  value: string;
}

export interface FuelType {
  translation: Translation24[];
  engPhrase: string;
}

export interface Translation25 {
  languageCodeIso3: string;
  value: string;
}

export interface Type {
  translation: Translation25[];
  engPhrase: string;
}

export interface Translation26 {
  languageCodeIso3: string;
  value: string;
}

export interface FrameType {
  translation: Translation26[];
  engPhrase: string;
}

export interface Translation27 {
  languageCodeIso3: string;
  value: string;
}

export interface LiftableAxles {
  translation: Translation27[];
  engPhrase: string;
}

export interface Translation28 {
  languageCodeIso3: string;
  value: string;
}

export interface Suspension {
  translation: Translation28[];
  engPhrase: string;
}

export interface Translation29 {
  languageCodeIso3: string;
  value: string;
}

export interface Brakes2 {
  translation: Translation29[];
  engPhrase: string;
}

export interface Translation30 {
  languageCodeIso3: string;
  value: string;
}

export interface SteeringType {
  translation: Translation30[];
  engPhrase: string;
}

export interface Translation31 {
  languageCodeIso3: string;
  value: string;
}

export interface NumberOfAxles {
  translation: Translation31[];
  engPhrase: string;
}

export interface Translation32 {
  languageCodeIso3: string;
  value: string;
}

export interface SteeredAxles {
  translation: Translation32[];
  engPhrase: string;
}

export interface Translation33 {
  languageCodeIso3: string;
  value: string;
}

export interface TyreType {
  translation: Translation33[];
  engPhrase: string;
}

export interface Translation34 {
  languageCodeIso3: string;
  value: string;
}

export interface AdditionalBrakes2 {
  translation: Translation34[];
  engPhrase: string;
}

export interface Trailer {
  make: string;
  model: string;
  type: Type;
  frameType: FrameType;
  liftableAxles: LiftableAxles;
  suspension: Suspension;
  brakes: Brakes2;
  steeringType: SteeringType;
  numberOfAxles: NumberOfAxles;
  steeredAxles: SteeredAxles;
  tyreType: TyreType;
  additionalBrakes: AdditionalBrakes2;
}

export interface Translation35 {
  languageCodeIso3: string;
  value: string;
}

export interface Superstructure2 {
  translation: Translation35[];
  engPhrase: string;
}

export interface Translation36 {
  languageCodeIso3: string;
  value: string;
}

export interface RearDoors {
  translation: Translation36[];
  engPhrase: string;
}

export interface Translation37 {
  languageCodeIso3: string;
  value: string;
}

export interface LoadHandling {
  translation: Translation37[];
  engPhrase: string;
}

export interface Translation38 {
  languageCodeIso3: string;
  value: string;
}

export interface SideDoors {
  translation: Translation38[];
  engPhrase: string;
}

export interface Measurements {
  internalLengthMm: number;
  internalWidthMm: number;
  internalHeightMm: number;
  externalLengthMm: number;
  externalWidthMm: number;
  externalHeightMm: number;
}

export interface Translation39 {
  languageCodeIso3: string;
  value: string;
}

export interface Make {
  translation: Translation39[];
  engPhrase: string;
}

export interface Translation40 {
  languageCodeIso3: string;
  value: string;
}

export interface Position {
  translation: Translation40[];
  engPhrase: string;
}

export interface Translation41 {
  languageCodeIso3: string;
  value: string;
}

export interface AggregateType {
  translation: Translation41[];
  engPhrase: string;
}

export interface Translation42 {
  languageCodeIso3: string;
  value: string;
}

export interface Description2 {
  translation: Translation42[];
  engPhrase: string;
}

export interface TemperatureControl {
  make: Make;
  modelYear: number;
  dateLegalInspection: Date;
  position: Position;
  aggregateType: AggregateType;
  description: Description2;
}

export interface Translation43 {
  languageCodeIso3: string;
  value: string;
}

export interface Make2 {
  translation: Translation43[];
  engPhrase: string;
}

export interface Translation44 {
  languageCodeIso3: string;
  value: string;
}

export interface Capacity {
  translation: Translation44[];
  engPhrase: string;
}

export interface Translation45 {
  languageCodeIso3: string;
  value: string;
}

export interface Extensions {
  translation: Translation45[];
  engPhrase: string;
}

export interface Translation46 {
  languageCodeIso3: string;
  value: string;
}

export interface Other {
  translation: Translation46[];
  engPhrase: string;
}

export interface Crane {
  make: Make2;
  model: string;
  modelYear: number;
  capacity: Capacity;
  dateLegalInspection: Date;
  extensions: Extensions;
  other: Other;
}

export interface Translation47 {
  languageCodeIso3: string;
  value: string;
}

export interface Make3 {
  translation: Translation47[];
  engPhrase: string;
}

export interface Translation48 {
  languageCodeIso3: string;
  value: string;
}

export interface Position2 {
  translation: Translation48[];
  engPhrase: string;
}

export interface Translation49 {
  languageCodeIso3: string;
  value: string;
}

export interface Capacity2 {
  translation: Translation49[];
  engPhrase: string;
}

export interface TailLift {
  make: Make3;
  position: Position2;
  capacity: Capacity2;
  modelYear: number;
  dateLegalInspection: Date;
}

export interface Superstructure {
  make: string;
  model: string;
  type: string;
  numberOfPallets: number;
  numberOfCompartments: number;
  superstructure: Superstructure2;
  rearDoors: RearDoors;
  loadHandling: LoadHandling;
  sideDoors: SideDoors;
  measurements: Measurements;
  temperatureControl: TemperatureControl;
  crane: Crane;
  tailLift: TailLift;
}

export interface Specification {
  category: string;
  licensePlateCountryCode: string;
  licensePlate: string;
  description: Description;
  comment: Comment;
  advertisementTitle: string;
  vehicleHighlights: VehicleHighlights;
  make: string;
  model: string;
  modelYear: number;
  idNumber: string;
  chassisNumber: string;
  vinNumber: string;
  vehicleWeightWithoutLoadKg: number;
  legalPayloadKg: number;
  legalWeightTruckAndTrailerWithLoadKg: number;
  legalWeightTruckWithLoadKg: number;
  frontAxleLoadKg: number;
  dateProduction: Date;
  dateFirstRegistration: Date;
  dateTachographCalibration: Date;
  mileageKm: number;
  tyreTread: TyreTread[];
  wheelBaseMm: number;
  hasDiffLocks: string;
  cabType: CabType;
  chassisHeight: ChassisHeight;
  spoilerKit: SpoilerKit;
  rearSuspension: RearSuspension;
  frontSuspension: FrontSuspension;
  axleConfiguration: AxleConfiguration;
  axleConfigurationLayout: string;
  axleSpecification: AxleSpecification;
  rearAxleType: RearAxleType;
  rearAxleRatio: number;
  fuelCapacity: string;
  numberOfTanks: number;
  horsePower: number;
  emission: string;
  gearBox: string;
  engineVolumeLitres: number;
  brakes: Brakes;
  additionalBrakes: AdditionalBrakes;
  powerTakeOff: PowerTakeOff;
  airCondition: AirCondition;
  serviceRepairHistory: ServiceRepairHistory;
  fuelType: FuelType;
  trailer: Trailer;
  superstructure: Superstructure;
  batteryCapacityKWh:BatteryCapacityKWh;
  electricMotor:ElectricMotor;
  isElectric:number;
}

export interface Translation50 {
  languageCodeIso3: string;
  value: string;
}

export interface VehicleAdditionalEquipment {
  translation: Translation50[];
  engPhrase: string;
}

export interface Picture {
  filename: string;
  filenameLarge: string;
  filenameMedium: string;
  filenameSmall: string;
  shortName: string;
  description: string;
  contentType: string;
  contentBase64: string;
  filenameThumbnail: string;
  contentTypeThumbnail: string;
  contentBase64Thumbnail: string;
}

export interface Video {
  videoLink: string;
  videoName: string;
}

export interface pictures {
  picture: Picture[];
}

export interface videos {
  picture: Video[];
}

export class Vehicle {
  clientId: string;
  truckId: string;
  doEnableVFS: boolean;
  isDeleted: boolean;
  deletedAt: Date;
  stockNumber: string;
  advertisementCategory: string;
  action: string;
  sites: Sites;
  metaData: MetaData;
  vehicleStatus: number;
  pricing: Pricing;
  salesperson: Salesperson;
  salespersons: Salesperson[];
  stockLocation: StockLocation;
  externalName: string;
  specification: Specification;
  vehicleAdditionalEquipment: VehicleAdditionalEquipment[];
  pictures: pictures;
  videos: videos;
}

export interface RootObject {
  vehicle: Vehicle[];
}
export interface BatteryCapacityKWh {
  engPhrase: string;
}
export interface ElectricMotor {
  engPhrase: string;
}