<table>
    <thead><tr><td>
      <div class="header-space">&nbsp;</div>
    </td></tr></thead>
    <tbody><tr><td>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </td></tr></tbody>
    <tfoot><tr><td>
      <div class="footer-space">&nbsp;</div>
    </td></tr></tfoot>
  </table>
        
  <div class="header">
      <img class="volvoLogo" [src]="assetService.getAssetUrl('assets/logo.png')" alt="" />
      <div class="date">
        {{printDate | date:'yyyy-MM-dd'}}
      </div>
      <div class="borderLine"></div>
  </div>
  
  <div class="footer">
    <div class="borderLine"></div>
    <div class="footerText paddingDivFooter">
    </div>
  </div>
  