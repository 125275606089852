import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import {
  LocalesStateLang,
  LocalesStateLangList,
  LocalesStateRegion,
  LocalesStateRegionList,
  LocalesStateCountry,
  LocalesStateCountryList
} from '../../core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Location } from '@angular/common';
import { LocalizeRouterService, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { TranslationService } from '~/core/services/translations/translation-service';
import { SearchState } from '~/core/store/search/search.state';
import { CounterService } from '~/core/services/counter/counter-service';
import { environment } from '../../../environments/environment';
import { allLangRegEx, defaultRegionList } from '~/core/constants/constants';
@Component({
  selector: 'volvo-regional-popup',
  templateUrl: './regional-popup.component.html',
  styleUrls: ['./regional-popup.component.scss']
})
export class RegionalPopupComponent implements OnInit, AfterViewInit {
  showLanguage: boolean = false;
  showCountries: boolean = false;
  langList: any[];
  allLangList: any[];
  curLangList: LocalesStateLangList;
  currRegion: LocalesStateRegionList;
  currCountry: LocalesStateCountryList;
  selectedRegion: string;
  selectedLang: any;
  isExpand: boolean = false;
  ukwebsite: string = environment.ukwebsite;
  nswebsite: string = environment.nswebsite;
  txwebsite: string = environment.txwebsite;
  defaultRegionList = defaultRegionList;
  autoRedirect: boolean = false;

  constructor(
    public localesService: LocalesService,
    private location: Location,
    private localizeRouterService: LocalizeRouterService,
    private settings: LocalizeRouterSettings,
    private translationService: TranslationService,
    private searchState: SearchState,
    private counterService: CounterService,
  ) {
    translationService.loadTrans('en').then();
    
    this.initializePopup();
  }

  ngOnInit() { }
  ngAfterViewInit() {

    //scroll to selected the country and lang
    if (this.currCountry !== undefined && this.selectedLang !== undefined) {
      const countryEl = document.getElementById(this.currCountry.id);
      if (countryEl) {
        // setTimeout(function(){
        countryEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
        // },500)

      }
      const langEl = document.getElementById(this.selectedLang.label);
      if (langEl) {
        setTimeout(function () {
          langEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 800)
      }
    }
  }
  countryObj = {
    list: [
      { label: 'Austria', id: 'at', isSelected: false },
      { label: 'Belgium', id: 'be-nl', isSelected: false },
      { label: 'Bosnia and Herzegovina', id: 'ba', isSelected: false },
      { label: 'Bulgaria', id: 'bg', isSelected: false },
      { label: 'Czech Republic', id: 'cz', isSelected: false },
      { label: 'Croatia', id: 'hr', isSelected: false },
      { label: 'Denmark', id: 'dk', isSelected: false },
      { label: 'Estonia', id: 'ee', isSelected: false },
      { label: 'Finland', id: 'fi', isSelected: false },
      { label: 'France', id: 'fr', isSelected: false },
      { label: 'Germany', id: 'de', isSelected: false },
      { label: 'Hungary', id: 'hu', isSelected: false },
      { label: 'Italy', id: 'it', isSelected: false },
      { label: 'Latvia', id: 'lv', isSelected: false },
      { label: 'Lithuania', id: 'lt', isSelected: false },
      { label: 'Macedonia', id: 'mk', isSelected: false },
      { label: 'Netherlands', id: 'nl', isSelected: false },
      { label: 'Norway', id: 'no', isSelected: false },
      { label: 'Poland', id: 'pl', isSelected: false },
      { label: 'Romania', id: 'ro', isSelected: false },
      { label: 'Switzerland', id: 'ch-de', isSelected: false },
      { label: 'Slovenia', id: 'si', isSelected: false },
      { label: 'Slovakia', id: 'sk', isSelected: false },
      { label: 'Sweden', id: 'se', isSelected: false },
      { label: 'Spain', id: 'es', isSelected: false },
      { label: 'Serbia', id: 'rs', isSelected: false }
    ]
  };

  langObj = [
    { label: 'English', id: 'en', apiResLang: 'en', code: 'en-US', isSelected: false },
    { label: 'Lietuvių', id: 'lt', apiResLang: 'lt', code: 'lt-LT', isSelected: false },
    { label: 'Čeština', id: 'cz', apiResLang: 'cs', code: 'cs-CZ', isSelected: false },
    { label: 'Dansk', id: 'dk', apiResLang: 'da', code: 'da-DK', isSelected: false },
    { label: 'Français', id: 'fr', apiResLang: 'fr', code: 'fr-FR', isSelected: false },
    { label: 'Español', id: 'es', apiResLang: 'es', code: 'es-ES', isSelected: false },
    { label: 'Norsk', id: 'no', apiResLang: 'no', code: 'no-NO', isSelected: false },
    { label: 'Suomi', id: 'fi', apiResLang: 'fi', code: 'fi-FI', isSelected: false },
    { label: 'Polski', id: 'pl', apiResLang: 'pl', code: 'pl-PL', isSelected: false },
    { label: 'Magyar', id: 'hu', apiResLang: 'hu', code: 'hu-HU', isSelected: false },
    { label: 'Slovenčina', id: 'sk', apiResLang: 'sk', code: 'sk-SK', isSelected: false },
    { label: 'Slovenščina', id: 'si', apiResLang: 'si', code: 'sl-SI', isSelected: false },
    { label: 'Deutsch (AT)', id: 'at', apiResLang: 'de-at', code: 'de-AT', isSelected: false },
    { label: 'Română', id: 'ro', apiResLang: 'ro', code: 'ro-RO', isSelected: false },
    { label: 'Bosanski', id: 'ba', apiResLang: 'bs', code: 'bs-BA', isSelected: false },
    { label: 'Македонски', id: 'mk', apiResLang: 'mk', code: 'mk-MK', isSelected: false },
    { label: 'Српски', id: 'rs', apiResLang: 'rs', code: 'sr-RS', isSelected: false },
    { label: 'Hrvatski', id: 'hr', apiResLang: 'hr', code: 'hr-HR', isSelected: false },
    { label: 'Deutsch (DE)', id: 'de', apiResLang: 'de', code: 'de-DE', isSelected: false },
    { label: 'Nederlands', id: 'nl', apiResLang: 'nl', code: 'nl-NL', isSelected: false },
    { label: 'Български', id: 'bg', apiResLang: 'bg', code: 'bg-BG', isSelected: false },
    { label: 'Svenska', id: 'se', apiResLang: 'sv', code: 'sv-SE', isSelected: false },
    { label: 'Eesti', id: 'ee', apiResLang: 'et', code: 'et-EE', isSelected: false },
    { label: 'Latviešu', id: 'lv', apiResLang: 'lv', code: 'lv-LV', isSelected: false },
    { label: 'Русский', id: 'ru', apiResLang: 'ru', code: 'ru-RU', isSelected: false },
    { label: 'Français (BE)', id: 'be-fr', apiResLang: 'fr', code: 'fr-BE', isSelected: false },
    { label: 'Nederlands (BE)', id: 'be-nl', apiResLang: 'nl', code: 'nl-BE', isSelected: false },
    { label: 'Deutsch (CH)', id: 'ch-de', apiResLang: 'de', code: 'de-CH', isSelected: false },
    { label: 'Français (CH)', id: 'ch-fr', apiResLang: 'fr', code: 'fr-CH', isSelected: false },
    { label: 'Italiano (CH)', id: 'ch-it', apiResLang: 'it', code: 'it-CH', isSelected: false },
    { label: 'Italiano', id: 'it', apiResLang: 'it', code: 'it-IT', isSelected: false },
    { label: 'English', id: 'en-za', apiResLang: 'en-za', code: 'en-ZA', isSelected: false },
    { label: 'English', id: 'en-gb', apiResLang: 'en-gb', code: 'en-GB', isSelected: false },
  ];

  fetchLanguages(regionObj): void {
    this.currRegion = regionObj;
    //selectedRegion global variable is used to show or hide the languages section on click of region
    if (this.selectedRegion === regionObj.id && this.showLanguage) {
      this.showLanguage = false;
      regionObj.isSelected = false;
    } else {
      this.showLanguage = true;
      this.showRegionSelected(regionObj, true);
      this.currCountry = { label: null, id: null }; //Explicitly set country to null for South Africa
    }

    if (regionObj.id === 'AU' || regionObj.id === 'ZA') {
      this.langList = this.langObj.filter(l => l.id === 'en-za');
      //this.showLanguages(this.langList); //No need to show other languages for South Africa
      this.allLangList = null; //No need to show other languages for South Africa, this is done because it is a global variable and will retain list when selected from other country.
      //Deselecting Europe country if any thing selected temporarily and switched to other region
      this.countryObj.list.forEach(item => {
        item.isSelected = false;
      });
      //Deselecting Languages if any thing selected temporarily  and switched to other region
      this.langObj.forEach(item => {
        item.isSelected = false;
      });
      
      if(this.autoRedirect){ //This is to redirect girectly to South Africa witj en-za language instead of explicitly selecting the language
        this.selectedRegion = regionObj.id;
        this.onLangChange(this.langList[0].id);
      }
    }

    //if (this.showCountries) this.showCountries = false;
    this.selectedRegion = regionObj.id;

    //window.scroll({top: 0, left: 0});
  }

  setAutoFlag(){
    this.autoRedirect = true;
  }
  showLanguages(language): void {
    let langFilter = language.map(l => {
      return l.label;
    });
    this.allLangList = this.langObj.filter(item => !langFilter.includes(item.label));
    //No country for South African Republic and Australia, hence defaulting to null.
    this.currCountry = { label: null, id: null };
  }

  fetchEuropeLang(countryObj: any): void {

    this.currCountry = countryObj;

    var temp = ['en', countryObj.id];
    switch (countryObj.id) {
      case 'be-nl':
        temp = ['en', 'be-fr', countryObj.id];
        break;
      case 'ch-de':
        temp = ['en', 'ch-fr', countryObj.id, 'ch-it'];
        break;
    }

    // if (countryObj.id === 'ch-de') { //showing language name Italiano with Italiano (CH) for Country Switzerland
    //   this.langObj = this.langObj.filter(item => item.label !== 'Italiano');
    //   this.langObj.push({ label: 'Italiano (CH)', id: 'it', apiResLang: 'it', code: 'it-IT', isSelected: false });
    // }
    // else{
    //   this.langObj = this.langObj.filter(item => item.label !== 'Italiano (CH)');
    // }
    this.langList = this.langObj.filter(function (itm) {
      return temp.indexOf(itm.id) > -1;
    });
    let langFilter = this.langList.map(itemLang => {
      return itemLang.label;
    });
   this.allLangList = this.langObj.filter(item => !langFilter.includes(item.label));
    this.showLanguage = true;
    this.showCountrySelected(countryObj, this.countryObj, true);

    this.removeLanguageSelection();
    //window.scroll({top: 0, left: 0});

  }

  showCountrySelected(countryElem: any, defaultList: any, toggleFlag: boolean): void {
    defaultList.list.forEach(item => {
      item.isSelected = false;
    });

    countryElem.isSelected = toggleFlag;
  }

  showRegionSelected(regionElem: any, toggleFlag: boolean): void {
    defaultRegionList.forEach(item => {
      item.isSelected = false;
    });
    regionElem.isSelected = toggleFlag;
  }

  UK(regionElem: any): void {
    // this.showRegionSelected(regionElem, true);
    //  this.showLanguage = false;
    //  this.showCountries = false;
  }
  fetchCountries(regionElem: any): void {
    this.currRegion = regionElem;
    if (this.showLanguage) this.showLanguage = false;

    if (this.showCountries) {
      this.showCountries = false;
      this.showRegionSelected(regionElem, false);
    } else {
      this.showCountries = true;
      this.showRegionSelected(regionElem, true);
      this.langList = [{ label: 'English', id: 'en', apiResLang: 'en', isSelected: false }];
      this.allLangList = null;
      this.showLanguage = true;

      this.removeCountrySelection();

    }
  }
  removeLanguageSelection(): void {
    this.langObj.forEach(item => {
      item.isSelected = false;
    });

  }
  removeCountrySelection(): void {
    if (this.currCountry) { //Deselecting the country element when user clicks on other region options.
      const countryElem = this.countryObj.list.find(item => item.id === this.currCountry.id);
      if (countryElem) {
        countryElem.isSelected = false;
        this.currCountry = { label: null, id: null };
      }
    }
  }
  onLangChange(itemId): void {
    const updateLang = this.langObj.find(langItem => langItem.id === itemId);
    this.counterService.postRegionSelection(this.currRegion.id, this.currCountry ? this.currCountry.id : undefined, updateLang.id);
    this.setLangNewChange(updateLang, this.currRegion, this.currCountry);
  }

  setLangNewChange(
    curLang: LocalesStateLangList,
    curRegion: LocalesStateRegionList,
    curCountry: LocalesStateCountryList
  ): void {
    var routeTempPath;
    var prevRegion = this.searchState.getCurrentRegion();

    if (!this.currCountry || this.currCountry.id === null && curRegion.id === 'EU') 
    {
      curCountry = { label: null, id: null };
    }
    else {
      curCountry = !curCountry.id ? { id: curRegion.id.toLowerCase(), label: curRegion.label } : curCountry;
    }
    this.localesService.setLocales({ lang: { curLang }, region: { curRegion }, country: { curCountry }, version: 2 });
    //When a language/country is switched in same region then stay in same page, else redirect to home page
    this.settings.alwaysSetPrefix = true;
    if (prevRegion === curRegion.id) {
      var temppath = this.location
        .path()
        .toString()
        .replace(allLangRegEx, '/').concat('?FromRegionalPopup');
      routeTempPath = temppath;
    } else routeTempPath = '/'.concat('?FromRegionalPopup');

    //resetting session object for country(It is set when country filter is removed or reset filter is clicked.), when user navidates to different country
    localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE', curLang.id);
    this.resetCountryFromSession();
    this.localizeRouterService.parser.currentLang = curLang.id;
    if (curLang.id === 'en') {
      //Condition for mobile
      if (routeTempPath === '/regions?FromRegionalPopup')
        routeTempPath = this.location
          .path()
          .toString()
          .replace('/regions', '');
        
          this.location.go(routeTempPath);
    } else {
      var routepath;
      //Condition for mobile
      if (routeTempPath === '/regions?FromRegionalPopup') routeTempPath = routeTempPath.replace('/regions?FromRegionalPopup', '');

      routepath = this.localizeRouterService.parser.addPrefixToUrl(routeTempPath);
      
      this.location.go(routepath);
    }
    location.reload();
  }

  initializePopup(): void {
    var initLang: LocalesStateLang
    var initRegion: LocalesStateRegion
    var initCountry: LocalesStateCountry;

    this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      initLang = lang;
    });

    this.localesService.region$.subscribe((region: LocalesStateRegion) => {
      initRegion = region;
    });

    this.localesService.country$.subscribe((country: LocalesStateCountry) => {
      initCountry = country;
    });

    const updateRegionObj = defaultRegionList.find(item => item.id === initRegion.curRegion.id);
    
    if (this.searchState.getSingleCountryRegion()) {
      updateRegionObj.isSelected = true;
      this.fetchLanguages(updateRegionObj);
      this.selectedLang = this.langObj.find(item => item.id === initLang.curLang.id);
      this.selectedLang.isSelected = true;
    } else {
      if (initCountry.curCountry.id) {
        updateRegionObj.isSelected = true;
        this.fetchCountries(updateRegionObj);
        this.isExpand = true;
        var updateCountryObj = undefined;
        if(initCountry.curCountry.id === 'za'){
            updateCountryObj = this.langObj.find(item => item.id === "en-za");
            updateCountryObj.isSelected = true;
            this.fetchLanguages(updateCountryObj);
          } else {
            updateCountryObj = this.countryObj.list.find(item => item.id.split('-')[0] === initCountry.curCountry.id.split('-')[0]);
            updateCountryObj.isSelected = true;
            this.fetchEuropeLang(updateCountryObj);
          }
          this.selectedLang = this.langObj.find(item => item.id === initLang.curLang.id);
          this.selectedLang.isSelected = true;
      } else {
        //this.showLanguage = true;
        
        //this.fetchLanguages(updateRegionObj);
      }
    }
  }
  
  resetCountryFromSession(): void {
    if (sessionStorage.getItem("resetCountry")) 
      sessionStorage.removeItem("resetCountry");

    localStorage.setItem('searchParam', '');
  }
}
