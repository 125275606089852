<div *ngIf="translationService.langInited"> 
  <section [ngClass]="isSelectedSite ? 'HomeA' : 'HomeA HomeImg'">    
    <div [ngClass]="
    region === regionEnum.UK ? 'HomeA-bg-uk' :
    region === regionEnum.NS ? 'HomeA-bg-ns' :
    region === regionEnum.TX ? 'HomeA-bg-tx' :
    isSelectedSite ? 'HomeA-bg' : ''"
    [ngStyle]="getBackgroundStyle()">
  </div>
  
    <h1 [ngClass]="region===regionEnum.TX ? 'HomeA-titleTX' : isSelectedSite ? 'HomeA-title' : 'HomeA-titleUKNS' " data-cy="home-choosetruck">
      <div>
        <div>
          {{"home/sectionA/title" | lowercase | translate}}
        </div>
        <div *ngIf="region===regionEnum.NS || region===regionEnum.TX">
          
          <span [ngClass]="region===regionEnum.TX ? 'HomeA-titleTX-innerTextTX' : 'HomeA-title-innerText'">
            {{"uk/home/sectionA/subtitle" | lowercase | translate}}
          </span>
        </div>
      </div>
      <div *ngIf="isSelectedSite">
        <button (click)="onSearch(item.id)" *ngFor="let item of search?.steps.majorCategory.list" mat-button
        class="HomeA-footerBtn">
          <span *ngIf="item.name === 'Tractor'" data-cy="tractor">
            <img *ngIf="item.icon"  [src]="item.icon" volvoLazyImage [alt]="item.name"
              style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
            <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
              {{ 'globalMiss/tractor' | lowercase | translate }} &nbsp;
              <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
            </span>
          </span>
          <span *ngIf="item.name === 'Semi Trailer'" data-cy="semitrailer">
            <img *ngIf="item.icon" [hidden]="!isImgLoaded" [src]="item.icon" volvoLazyImage [alt]="item.name"
              style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
            <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
              {{ 'globalMiss/semiTrailer' | lowercase | translate }} &nbsp;
              <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
            </span>
          </span>
          <span *ngIf="item.name === 'Truck'" data-cy="truck">
            <img *ngIf="item.icon" [hidden]="!isImgLoaded" [src]="item.icon" volvoLazyImage [alt]="item.name"
              style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
            <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
              {{ 'globalMiss/truck' | lowercase | translate }} &nbsp;
              <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
            </span>
          </span>
          <span *ngIf="item.name === 'Trailer'" data-cy="trailer">
            <img *ngIf="item.icon" [hidden]="!isImgLoaded" [src]="item.icon" volvoLazyImage [alt]="item.name"
              style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
            <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
              {{ 'globalMiss/trailer' | lowercase | translate }} &nbsp;
              <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
            </span>
          </span>
        </button>
      </div>
    </h1>
    
    
    
  </section>
  <section class="chooseTruck" *ngIf="(isMobile || isTablet) && isSelectedSite">
    <div class="chooseTruck-title">
      <div>
        {{"home/sectionA/title" | lowercase | translate}}
      </div>
      <div *ngIf="region===regionEnum.NS || region===regionEnum.TX">
        
        <span [ngClass]="region===regionEnum.TX ? 'HomeA-titleTX-innerTextTX' : HomeA-titleTX-innerText">
          {{"uk/home/sectionA/subtitle" | lowercase | translate}}
        </span>
      </div>
      
    </div>
    <div>
      <button (click)="onSearch(item.id)" *ngFor="let item of search?.steps.majorCategory.list" mat-button
      class="HomeA-footerBtn">
        <span *ngIf="item.name === 'Tractor'" data-cy="tractor">
          <img *ngIf="item.icon"  [src]="item.icon" volvoLazyImage [alt]="item.name"
            style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
          <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
            {{ 'globalMiss/tractor' | lowercase | translate }} &nbsp;
            <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
          </span>
        </span>
        <span *ngIf="item.name === 'Semi Trailer'" data-cy="semitrailer">
          <img *ngIf="item.icon" [hidden]="!isImgLoaded" [src]="item.icon" volvoLazyImage [alt]="item.name"
            style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
          <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
            {{ 'globalMiss/semiTrailer' | lowercase | translate }} &nbsp;
            <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
          </span>
        </span>
        <span *ngIf="item.name === 'Truck'" data-cy="truck">
          <img *ngIf="item.icon" [hidden]="!isImgLoaded" [src]="item.icon" volvoLazyImage [alt]="item.name"
            style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
          <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
            {{ 'globalMiss/truck' | lowercase | translate }} &nbsp;
            <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
          </span>
        </span>
        <span *ngIf="item.name === 'Trailer'" data-cy="trailer">
          <img *ngIf="item.icon" [hidden]="!isImgLoaded" [src]="item.icon" volvoLazyImage [alt]="item.name"
            style="height: 27px" (load)=isImageLoaded(); [ngClass]="isImgLoaded ? '' : 'skeletonTruckType'" />
          <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
            {{ 'globalMiss/trailer' | lowercase | translate }} &nbsp;
            <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
          </span>
        </span>
      </button>
    </div>
  </section>
  <!-- <div>
    <mat-form-field appearance="outline" class="MatInput HomeA-regionSelect">
      <mat-select [(value)]="region" (selectionChange)="onRegionChange($event)" disableOptionCentering>
        <mat-option *ngFor="let item of defaultRegionList" [value]="item.id">
          {{ item.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->
  <volvo-new-in-stock [title]="'home/newInStock' | lowercase | translate" [trucks]="newInStockTrucks">
  </volvo-new-in-stock>

  <volvo-best-in-stock [title]="'home/sectionB/title' | lowercase | translate" [isTablet]="isTablet"
    [trucks]="highlightedTrucks">
  </volvo-best-in-stock>
  <div>
  
  <div *ngIf ="!isSelectedSite">
    <div class="homeContent">
      <volvo-home-page-content [text]="'uk/home/sectionD/text' | lowercase | translate"></volvo-home-page-content>
    </div>

    <div class="HomeA-stockLink" *ngIf="region!==regionEnum.TX">
      <volvo-stock-link-page></volvo-stock-link-page>
    </div>
    <div class="HomeA-media">
      <volvo-home-media *ngIf="!isTablet" [twitterLoaded]=false [region]=region></volvo-home-media>
    </div>
  </div>
  <section class="HomeC" *ngIf ="isSelectedSite && !isMobile" [ngClass]="!volvoSelectedProEnabled ? 'alignClass' : ''">
    <div class="HomeC-item HomeC-itemA">
      <div class="HomeC-itemBox"></div>
      <div class="HomeC-textDiv">
        <div class="HomeC-title" data-cy="search-stock">
          {{ 'home/sectionC/itemA/title' | lowercase | translate }}
        </div>
        <div class="HomeC-text" data-cy="search-beststock">
          {{ 'globalMiss/bestChoice' | lowercase | translate }}
        </div>
        <div>
          <button mat-button class="HomeA-footerBtn customStyle" (click)="routePage(HtmlRoutePages.aboutUs)">
              <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
                Read more &nbsp;
                <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
              </span>
          </button>
        </div>
      </div>
    </div>
    <div class="HomeC-item HomeC-itemB" *ngIf="directToContactForFinance; else toContactBlock">
      <div class="HomeC-itemBox"></div>
      <div class="HomeC-textDiv">
        <div class="HomeC-title" data-cy="search-stock">
          {{ 'home/sectionC/itemB/title' | lowercase | translate }}
        </div>
        <div class="HomeC-text" data-cy="search-beststock">
          {{ 'globalMiss/smarterInvestment' | lowercase | translate }}
        </div>
        <div>
          <button mat-button class="HomeA-footerBtn customStyle" (click)="routePage(HtmlRoutePages.contact)">
              <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
                Read more &nbsp;
                <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
              </span>
          </button>
        </div>
      </div>
    </div>
    <ng-template #toContactBlock>
      <div class="HomeC-item HomeC-itemB">
        <div class="HomeC-itemBox"></div>
        <div class="HomeC-textDiv">
          <div class="HomeC-title" data-cy="search-stock">
            {{ 'home/sectionC/itemB/title' | lowercase | translate }}
          </div>
          <div class="HomeC-text" data-cy="search-beststock">
            {{ 'globalMiss/smarterInvestment' | lowercase | translate }}
          </div>
          <div>
            <button mat-button class="HomeA-footerBtn customStyle" (click)="routePage(HtmlRoutePages.financing)">
                <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
                  Read more &nbsp;
                  <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
                </span>
            </button>
          </div>
          
        </div>
      </div>
    </ng-template>
    <div *ngIf="volvoSelectedProEnabled" class="HomeC-item HomeC-itemC">
      <div class="HomeC-itemBox"></div>
      <div class="HomeC-textDiv">
        <div class="HomeC-title" data-cy="search-stock">
          {{ 'home/sectionC/itemC/title' | lowercase | translate }}
        </div>
        <div class="HomeC-text" data-cy="search-beststock">
          {{ 'globalMiss/qualityAssured' | lowercase | translate }}
        </div>
        <div>
          <button mat-button class="HomeA-footerBtn customStyle" (click)="routePage(HtmlRoutePages.volvoSelected)">
              <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
                Read more &nbsp;
                <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
              </span>
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="HomeC" *ngIf ="isSelectedSite && isMobile">
    <div>
      <button mat-button class="HomeA-footerBtn customStyle" (click)="routePage(HtmlRoutePages.aboutUs)">
          <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
            Read about Volvo Selected &nbsp;
            <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
          </span>
      </button>
        <button mat-button class="HomeA-footerBtn customStyle" (click)="routePage(HtmlRoutePages.financing)">
            <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
              Read about financing & insurance &nbsp;
              <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
            </span>
      </button>
      <button mat-button class="HomeA-footerBtn customStyle" *ngIf="volvoSelectedProEnabled" (click)="routePage(HtmlRoutePages.volvoSelected)">
          <span [ngClass]="isImgLoaded ? '' : 'skeletonTruckType-type'">
            Read about Volvo Selected Pro &nbsp;
            <i [ngClass]="isImgLoaded ? 'fal fa-angle-right' : ''" [hidden]="!isImgLoaded"></i>
          </span>
      </button>
    </div>
  </section>
  <section *ngIf="region === regionEnum.UK">
    <div class="compliance">
      <p>
        <i>
          {{ 'home/compliancetext' | lowercase | translate }}
        </i>
      </p>
    </div>
  </section>
  <volvo-footer></volvo-footer>
  <!-- <volvo-download-pwa></volvo-download-pwa> -->
</div>