import { LocalizeRouterService, LocalizeRouterSettings } from "@gilsdav/ngx-translate-router";
import { Action, State, StateContext } from "@ngxs/store";
import { TranslateService } from "@ngx-translate/core";

import { InitLanguage, SetLocales } from "~/core/store/locales/locales.actions";
import { LocalesStateInterface } from "~/core/store/locales/locales.model";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { NestedTreeControl } from "@angular/cdk/tree";
import { allLangRegEx } from "~/core/constants/constants";

const defaultRegionList = [
    { label: "Europe", id: "EU" },
    { label: "South Africa", id: "ZA" },
    // { label: "Australia", id: "AU" }
];

const defaultLangList = [
    { label: 'English', id: 'en', apiResLang: 'en', code: 'en-US' },
    { label: 'Lietuvių', id: 'lt', apiResLang: 'lt', code: 'lt-LT' },
    { label: 'Čeština', id: 'cz', apiResLang: 'cs', code: 'cs-CZ' },
    { label: 'Dansk', id: 'dk', apiResLang: 'da', code: 'da-DK' },
    { label: 'Français', id: 'fr', apiResLang: 'fr', code: 'fr-FR' },
    { label: 'Español', id: 'es', apiResLang: 'es', code: 'es-ES' },
    { label: 'Norsk', id: 'no', apiResLang: 'no', code: 'no-NO' },
    { label: 'Italiano', id: 'it', apiResLang: 'it', code: 'it-IT' },
    { label: 'Suomi', id: 'fi', apiResLang: 'fi', code: 'fi-FI' },
    { label: 'Polski', id: 'pl', apiResLang: 'pl', code: 'pl-PL' },
    { label: 'Magyar', id: 'hu', apiResLang: 'hu', code: 'hu-HU' },
    { label: 'Slovenčina', id: 'sk', apiResLang: 'sk', code: 'sk-SK' },
    { label: 'Slovenščina', id: 'si', apiResLang: 'si', code: 'sl-SI' },
    { label: 'Deutsch (AT)', id: 'at', apiResLang: 'de-at', code: 'de-AT' },
    { label: 'Română', id: 'ro', apiResLang: 'ro', code: 'ro-RO' },
    { label: 'Bosanski', id: 'ba', apiResLang: 'bs', code: 'bs-BA' },
    { label: 'Македонски', id: 'mk', apiResLang: 'mk', code: 'mk-MK' },
    { label: 'Српски', id: 'rs', apiResLang: 'rs', code: 'sr-RS' },
    { label: 'Hrvatski', id: 'hr', apiResLang: 'hr', code: 'hr-HR' },
    { label: 'Deutsch (DE)', id: 'de', apiResLang: 'de', code: 'de-DE' },
    { label: 'Nederlands', id: 'nl', apiResLang: 'nl', code: 'nl-NL' },
    { label: 'Български', id: 'bg', apiResLang: 'bg', code: 'bg-BG' },
    { label: 'Svenska', id: 'se', apiResLang: 'sv', code: 'sv-SE' },
    { label: 'Eesti', id: 'ee', apiResLang: 'et', code: 'et-EE' },
    { label: 'Latviešu', id: 'lv', apiResLang: 'lv', code: 'lv-LV' },
    { label: 'Русский', id: 'ru', apiResLang: 'ru', code: 'ru-RU' },
    { label: 'Français (BE)', id: 'be-fr', apiResLang: 'fr', code: 'fr-BE' },
    { label: 'Nederlands (BE)', id: 'be-nl', apiResLang: 'nl', code: 'nl-BE' },
    { label: 'Deutsch (CH)', id: 'ch-de', apiResLang: 'de', code: 'de-CH' },
    { label: 'Français (CH)', id: 'ch-fr', apiResLang: 'fr', code: 'fr-CH' },
    { label: 'Italiano (CH)', id: 'ch-it', apiResLang: 'it', code: 'it-CH' },
    { label: 'English', id: 'en-za', apiResLang: 'en-za', code: 'en-ZA' },
    { label: 'English', id: 'en-gb', apiResLang: 'en-gb', code: 'en-GB' },
    { label: 'English', id: 'en-ns', apiResLang: 'en-ns', code: 'en-NS' },
    { label: 'English', id: 'en-tx', apiResLang: 'en-tx', code: 'en-TX' },
];

const defaultCountryList = [
    { label: 'Austria', id: 'at' },
    { label: 'Belgium', id: 'be-nl' },
    { label: 'Bosnia and Herzegovina', id: 'ba' },
    { label: 'Bulgaria', id: 'bg' },
    { label: 'Czech Republic', id: 'cz' },
    { label: 'Croatia', id: 'hr' },
    { label: 'Denmark', id: 'dk' },
    { label: 'Estonia', id: 'ee' },
    { label: 'Finland', id: 'fi' },
    { label: 'France', id: 'fr' },
    { label: 'Germany', id: 'de' },
    { label: 'Hungary', id: 'hu' },
    { label: 'Italy', id: 'it' },
    { label: 'Latvia', id: 'lv' },
    { label: 'Lithuania', id: 'lt' },
    { label: 'Macedonia', id: 'mk' },
    { label: 'Netherlands', id: 'nl' },
    { label: 'Norway', id: 'no' },
    { label: 'Poland', id: 'pl' },
    { label: 'Romania', id: 'ro' },
    { label: 'Switzerland', id: 'ch-de' },
    { label: 'Slovenia', id: 'si' },
    { label: 'Slovakia', id: 'sk' },
    { label: 'Sweden', id: 'se' },
    { label: 'Spain', id: 'es' },
    { label: 'Serbia', id: 'rs' },
    { label: 'South Africa', id: 'za' },
    { label: null, id: null }
];

@State<LocalesStateInterface>({
    name: "language",
    defaults: {
        lang: {
            curLang: { label: "English", id: "en", apiResLang: "en", code: "en-US" },
            list: defaultLangList,
        },
        region: {
            curRegion: { label: "Europe", id: "EU", },
            list: defaultRegionList,
        },
        country: {
            curCountry: { label: null, id: null, },
            list: defaultCountryList,
        },
        data: null,
    },
})
@Injectable()
export class LocalesState {
    private router: Router;
    constructor(
        private translateService: TranslateService,
        private localizeRouterService: LocalizeRouterService,
        private settings: LocalizeRouterSettings,
        private location: Location
    ) { }

    @Action(SetLocales)
    setLocales(
        { patchState }: StateContext<LocalesStateInterface>,
        { payload }: SetLocales
    ) {
        let newState = payload;
        newState.country.list = defaultCountryList;
        newState.lang.list = defaultLangList;
        newState.region.list = defaultRegionList;
        newState.version = payload.version;
        if(newState.region.curRegion.id === 'EU') {
            this.useLang(newState.lang.curLang.id);
        }
        patchState(newState);
        //localStorage.setItem('language', JSON.stringify(newState));

        if (!this.location.path().includes("regions")) {
            // if (this.location.path().toString().includes("?gclid") ||
            //     this.location.path().toString().includes("?fbclid") ||
            //     this.location.path().toString().includes("?utm_source")) {
            //     this.router.navigate(['/'], { queryParamsHandling: 'merge' });
            // }

            var path = this.location.path().toString().replace(allLangRegEx, "/");
            //this.localizeRouterService.parser.currentLang = newState.lang.curLang.id;
            //var routepath = this.localizeRouterService.parser.addPrefixToUrl(path);
            
            //this.location.go(path)
        }
    }

    @Action(InitLanguage)
    initLanguage({ getState, patchState }: StateContext<LocalesStateInterface>) {
        const state: LocalesStateInterface = getState();
        const newState = {
            lang: {
                ...state.lang,
                list: defaultLangList,
            },
        };

        if(state.region.curRegion.id === 'EU') {
            this.useLang(newState.lang.curLang.id);
        }
        
        patchState(newState);

        if (!this.location.path().includes("regions")) {
            if (this.location.path().toString().includes("?gclid") ||
                this.location.path().toString().includes("?fbclid") ||
                this.location.path().toString().includes("?utm_source")) {
                this.router.navigate(['/'], { queryParamsHandling: 'merge' });
            }
            var path = this.location.path().toString().replace(allLangRegEx, "/");
            this.localizeRouterService.parser.currentLang = newState.lang.curLang.id;
            var routepath = this.localizeRouterService.parser.addPrefixToUrl(path);
            
            this.location.go(routepath)
        }
    }

    private async useLang(langId: string) {

        await this.translateService.use(langId).toPromise();
        this.localizeRouterService.changeLanguage(langId);
    }
}
