<div *ngIf="isSelectedSite; else otherSite">
  <section class="AboutA" data-cy="aboutus-vsheading"></section>
  
  <section class="AboutB">
    <div class="AboutB-title" data-cy="aboutus-vstitle">
      {{ 'aboutUs/sectionB/title' | lowercase | translate }}
    </div>
  
    <div class="AboutB-text" data-cy="aboutus-vstext" [innerHTML]="'aboutUs/sectionB/text'| lowercase | translate">
    </div>
    
    <div class="AboutB-items">
        <div>
            <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/aboutus.webp')" class="AboutB-itemImage">
        </div>
        <div class="AboutB-itemTitle">
            {{ 'aboutUs/sectionB/itemA/title' | lowercase | translate }}
        </div>
        <div id="whyBuyUsedTruck"></div>
        <div class="AboutB-itemText" [innerHTML]="'aboutUs/sectionB/itemA/text'| lowercase | translate">
        </div>
    </div>
  </section>
  
  <section class="AboutC">
    <div class="AboutC-title" data-cy="aboutus-vsprocharacteristics" [innerHTML]="'aboutUs/sectionD/title'| lowercase | translate">
    </div>
    <div class="AboutC-items" >
      <div class="AboutC-item">
        <div class="AboutC-itemIcon">
          <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/vehicle.webp')">
        </div>
        <div class="AboutC-itemWidth">
          <div class="AboutC-itemTitle" data-cy="aboutus-vsproquality">
            {{ 'aboutUs/sectionD/itemA/title' | lowercase | translate }}
          </div>
          <div [innerHTML]="'aboutUs/sectionD/itemA/text' | lowercase | translate" class="AboutC-itemText"></div>
        </div>
      </div>
      <div class="AboutC-item">
        <div class="AboutC-itemIcon">
          <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Group.webp')">
        </div>
        <div class="AboutC-itemWidth">
          <div class="AboutC-itemTitle" data-cy="aboutus-vsprodriveline">
            {{ 'aboutUs/sectionD/itemB/title' | lowercase | translate }}
          </div>
          <div [innerHTML]="'aboutUs/sectionD/itemB/text' | lowercase | translate" class="AboutC-itemText"></div>
        </div>
  
      </div>
  
      <div class="AboutC-item">
        <div class="AboutC-itemIcon">
          <img [src]="assetService.getAssetUrl('assets/pages/aboutUs/Union.webp')">
        </div>
        <div class="AboutC-itemWidth">
          <div class="AboutC-itemTitle" data-cy="aboutus-vsproservice">
            {{ 'aboutUs/sectionD/itemC/title' | lowercase | translate }}
          </div>
          <div [innerHTML]="'aboutUs/sectionD/itemC/text' | lowercase | translate" class="AboutC-itemText"></div>
        </div>
      </div>
    </div>
  </section>
</div>
<ng-template #otherSite>
  <section class="aboutUs">
    <div *ngIf="!isTablet" class="aboutUs-desktop">
        <div >
            <img src="{{imageUrl}}" class="aboutUs-imageDiv"/>
        </div>
        <div>
            <volvo-who-we-are id="whoweare" [region] = "region"></volvo-who-we-are>
            <volvo-what-we-have id="whatwehave" *ngIf="this.region !== this.regionEnum.TX"></volvo-what-we-have>
            <volvo-what-customer-say id="whatcustomersay"  *ngIf="this.region !== this.regionEnum.TX"></volvo-what-customer-say>
        </div>
    </div>
    <div *ngIf="isTablet" class="aboutUs-mobile">
        <div class="aboutUs-links">
            <button class="aboutUs-linkBtn" *ngFor="let link of navlinks; let i = index" (click)="routePage(link)">
                <span class="aboutUs-button">
                    <div class="aboutUs-linkBtn-Wrapper">
                        <div class="aboutUs-linkBtn-Wrapper-fontDiv">
                            <span >
                                {{ link.label | lowercase | translate }}
                            </span>
                            <span class="fontName">
                                {{ link.name | lowercase | translate }}
                            </span>
                        </div>
                        <div style="text-align: right;">
                            <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')">
                        </div>
                    </div>
                </span>
            </button>
        </div>
    </div>
  </section>
</ng-template>
    
  <volvo-footer></volvo-footer>
