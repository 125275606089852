import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

import { NavLinks } from 'src/app/core/services/sidenav/sidenav.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { HtmlRoutePages, LangRoutePages } from 'src/app/app-routing.model';
import {
    SubHeader,
    SubHeaderLinks
} from '../../../components/header/header.component';

import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Location } from '@angular/common';

@Component({
    selector: 'volvo-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {
    @Input() subHeader: NavLinks;
    @Input() pageIdentifier;
    @Output() scrollTo = new EventEmitter();
    routeurl: any;
    sub: any;
    HtmlRoutePages = HtmlRoutePages;
    pageTitle = '';
    regionEnum = regionEnum;
    region: string;
    private countrySetting: String;
    showFinancePage: Boolean;
    regionalSettings: RegionSettings;
    isSelectedSite: boolean = false;

    constructor(
        private route: ActivatedRoute, private location: Location,
        public store: Store,
        private router: Router,
        private localizeRouterService: LocalizeRouterService,
        private globalUtils: GlobalUtils, private titleService: Title, private translateService: TranslateService, private regionSettingService: RegionalSettingsService
    ) {        
        this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
        this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
        if (this.regionalSettings && this.regionalSettings.site === "volvoselected") this.isSelectedSite = true;
    }

    ngOnInit(): void {
    }

    // this method is called on click of any sub-menu, weather to redirecto to sub-page or remain on the same and scroll to the elmment is done here
    routePage(link: SubHeaderLinks, type: string): void {
        var pathurl: string = this.localizeRouterService.translateRoute(link.path).toString()
        const lastUrlSegment = pathurl.split('/').pop();

        // For few main pages we have scroll to the element and not opening sub-pages in main page
        if (
            type === HtmlRoutePages.vehicleOffers ||
            (type === HtmlRoutePages.services && !this.isSelectedSite) ||
            type === HtmlRoutePages.aboutUs ||
            (type === HtmlRoutePages.contact && this.region === this.regionEnum.TX)
        ) {
            const newUrlwithQueryParam = `${this.localizeRouterService.translateRoute(type)}?path=${lastUrlSegment}`;
            // updete the window url in browser to show query string with path,
            // which will help the gethighlighter method to identify if this has to be highligted or not
            if (!this.isSelectedSite && (window.location.href.includes(HtmlRoutePages.approvedFAQ)
                || window.location.href.includes(HtmlRoutePages.economyFAQ))) {
                this.router.navigateByUrl(newUrlwithQueryParam);
            }
            else {
                this.location.go(newUrlwithQueryParam);// this will just update the url and not redirect to any url
                this.scrollToElement(type, lastUrlSegment);
            }            
        }   
        else {
            // Actuall navigation/redirection to theubpagege
            this.router.navigate(
                [pathurl],
                { relativeTo: this.route }
            );
        }
        //Update the metatitle after the click
        this.updateMetatitles(link.path)
    }

    // this method is been called for each sub-menu while loading
    getHighlighterClass(index: number, link: any): string {
        const lastUrlSegment = window.location.href.split('/').pop();
        const newUrl = this.convertQueryParamToUrl(window.location.href);

        //check if the link path(routed path) is included in main window url. then return to highlight that particular sub-menu
        if (!this.isSelectedSite && (window.location.href.includes(link.path)) || newUrl.includes(link.path)) {
            return 'Link-Active'
            
        }
        //enable first link of all sublinks for these main pages (services, contact, about us)
        else if ((lastUrlSegment === LangRoutePages.services
            || lastUrlSegment === LangRoutePages.contact
            || lastUrlSegment === LangRoutePages.aboutUs)
            && index === 0) {
            return 'Link-Active';
        }
        else {
            return 'Link-inActive';
        }
    }

    // method to convert the url with query parameter to simplify it without ?path='somePath' and make it '/somepath'
    convertQueryParamToUrl(originalUrl: string): string {
        // Parse the URL to get the query parameters
        const url = new URL(originalUrl);
        const path = url.searchParams.get('path');

        // Check if the path is not null or undefined
        if (path) {
            // Construct the new URL with query parameter
            return `${url.pathname}/${path}`;
        } else {
            return originalUrl;
        }
    }

    scrollToElement(type, link): void {        
        let path: string = link;
        const el = document.getElementById(path);        
        if (this.globalUtils.isBrowser()) {
            //because aboutUs page is special and need extra negative scroll
            if (type === HtmlRoutePages.aboutUs) {
                var divOffset = this.offset(el);
                window.scrollTo(divOffset.left, divOffset.top - 130);
            }
            else
                this.scrollTo.emit(el);
        }
    }   
    updateMetatitles(link: string): void {
        switch (link) {
            case HtmlRoutePages.vehicleWarranty:                
                this.titleService.setTitle(this.translateService.instant('uk/vehicleoffers/warranty/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.serviceExport:               
                this.titleService.setTitle(this.translateService.instant('pages/services/export/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.serviceCustomisation:                
                this.titleService.setTitle(this.translateService.instant('services/customisation/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.volvoSelected:                
                this.titleService.setTitle(this.translateService.instant('services/volvoselected/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.financing:                
                this.titleService.setTitle(this.translateService.instant('financing/metaTitle'.toLowerCase()));
                break;
            case HtmlRoutePages.vehicleSelected:                
                this.titleService.setTitle(this.translateService.instant('uk/vehicleoffers/selected/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.vehicleApproved:                
                this.titleService.setTitle(this.translateService.instant('uk/vehicleoffers/approved/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.vehicleEconomy:                
                this.titleService.setTitle(this.translateService.instant('uk/vehicleoffers/economy/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.serviceApproved:                
                this.titleService.setTitle(this.translateService.instant('uk/services/volvoselected/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.serviceEconomy:                
                this.titleService.setTitle(this.translateService.instant('uk/services/volvoeconomy/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.finance:                
                this.titleService.setTitle(this.translateService.instant('uk/services/financing/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.serviceContract:                
                this.titleService.setTitle(this.translateService.instant('uk/services/servicecontract/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.customisation:                
                this.titleService.setTitle(this.translateService.instant('uk/services/customisation/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.whoweare:                
                this.titleService.setTitle(this.translateService.instant('uk/aboutus/whoweare/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.whatwehave:                
                this.titleService.setTitle(this.translateService.instant('uk/aboutus/whatwehavebeen/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.whatcustomersay:                
                this.titleService.setTitle(this.translateService.instant('uk/aboutus/whatcustomersay/metatitle'.toLowerCase()));
                break;
            case HtmlRoutePages.volvoConnect:                
                this.titleService.setTitle(this.translateService.instant('uk/services/volvoconnect/metatitle'.toLowerCase()));
                break;
        }
    }

    offset(el) {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }
}
