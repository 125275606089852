<section class="volvo">
    <div class="txvolvoDiv">
        <div>
            <div class="txvolvoDiv-title">
                <span>{{ "uk/components/footer/financing" | lowercase | translate }}</span>
            </div>
            <div class="txvolvoDiv-text">
                <span>{{ "uk/pages/services/financing/text1" | lowercase | translate }}</span>
            </div>
        </div>
        <div class="tx-list">
            <ul class="tx-list-title" *ngFor="let list of financeList">
                <div class="tx-list-display">
                    <div>
                        <div *ngIf="list.type === 'uk/pages/services/Financing/title1'" class="finance-list-title-tractor">
                            <img [src]="list.img" />
                        </div>
                        <div *ngIf="list.type === 'uk/pages/services/Financing/title2'"
                             class="">
                            <img [src]="list.img" />
                        </div>
                        <div *ngIf="list.type === 'uk/pages/services/Financing/title3'"
                             class="">
                            <img [src]="list.img" />
                        </div>
                    </div>
                    <div class="tx-list-display-padding">
                        <span> {{ list.type | lowercase | translate }}</span>
                        <li> {{ list.name | lowercase | translate }}</li>
                    </div>
                </div>

            </ul>
        </div>
        <div class="txvolvoDiv-text">
            <span>{{ "uk/pages/services/financing/text3" | lowercase | translate }}</span>
        </div>
        <div id="serviceContract"></div>
    </div>
</section>
<volvo-footer></volvo-footer>
