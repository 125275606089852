import { Component, OnInit } from '@angular/core';
import { SalesTeam } from '../contact-page.model';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
@Component({
  selector: 'volvo-centre-bardon',
  templateUrl: './centre-bardon.component.html',
  styleUrls: ['../centre-banbury/centre-banbury.component.scss']
})
export class CentreBardonComponent implements OnInit {
  mapCentre: string = 'Volvo Truck and Bus Centre Leicester';
  regionEnum = regionEnum;
  region: string;
  public isLoading = true
  constructor(private router: Router, private titleService: Title, 
    private translateService: TranslateService, 
    private metaTagService: Meta, 
    private regionSettingService: RegionalSettingsService, 
    private activatedRoute: ActivatedRoute,
    private breadCrumbService: BreadcrumbserviceService,) { }

    onImageLoad() {
        this.isLoading = false;
    }
    onError(): void {
        console.error('Image failed to load.');
        this.isLoading = false; // You may want to handle this differently
    }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/contactus/bardonmetatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/contactus/bardonmetatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/contactus/bardonmetadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/contactus/bardonaltImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/contactus/bardonmetatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/contactus/bardonmetatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/contactus/bardonmetadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/contactus/bardonaltImage'.toLowerCase())
    });
    this.regionSettingService.regionalSettings$.subscribe((res:any) => {
      this.region = res.regionsSettings[0].region;
    });
    if (this.region === regionEnum.NS) {
      this.mapCentre = 'Volvo Truck and Bus Centre Glasgow West';
      this.salesTeamMangementDetails = [
        {
          imageUrl: '/assets/pages/contactus/ns/Ian.webp',
          imageName: 'Ian Laverie',
          imageDesignation: 'uk/pages/contact/salesExecutive7',
          imageCentre: '',
          imageEmail: 'ian.laverie@volvo.com',
          imageMobile: '07845 124126',
        }
      ];
      //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Glasgow West Used Truck Centre', HtmlRoutePages.contact);
    }
    else{
      //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Leicester Used Truck Centre', HtmlRoutePages.contact);
    }
  }
  salesTeamMangementDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/Tommy Stewart.webp',
      imageName: 'Tommy Stewart',
      imageDesignation: 'uk/pages/contact/salesExecutive1',
      imageCentre: 'uk/pages/contact/salesCentre4',
      imageEmail: 'tommy.stewart@volvo.com',
      imageMobile: '07526 505164',
   },
  ]

}
