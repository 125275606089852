<section class="volvo">
  <div class="volvo-volvoDiv">
    <div class="volvo-imgDiv">
      <img
        src="assets/pages/buyersGuide/volvoFE.webp"
        class="volvo-imgDiv-volvoImg"
      />
    </div>
    <div class="volvo-title">
      <span>{{ "uk/pages/buyersGuide/volvoFE" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
      <span>{{ "uk/pages/buyersGuide/volvoFE/text1" | lowercase | translate }}</span>
    </div>
    <div class="volvo-text">
        <span class="volvo-text-medium">{{
          "uk/pages/buyersGuide/volvoFE/text2" | lowercase | translate
        }}</span
        >{{ "uk/pages/buyersGuide/volvoFE/text3" | lowercase | translate }}
      </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFE/text4" | lowercase | translate }}
      </span>
    </div>
    <div class="volvo-text">
      <span>
        {{ "uk/pages/buyersGuide/volvoFE/text5" | lowercase | translate }}
      </span>
    </div>
    <div class="volvo-text">
      <span class="volvo-text-medium">{{
        "uk/pages/buyersGuide/volvoFE/text6" | lowercase | translate
      }}</span
      >{{ "uk/pages/buyersGuide/volvoFE/text7" | lowercase | translate }}
    </div>
  </div>
</section>
<volvo-footer></volvo-footer>
