
<section >
    <div class="centre-title">
        <span  class="centre-title-ourCentreTitle"> {{ "uk/pages/contact/ourUserTruckCentre" | lowercase | translate }} </span>
    </div>
    <div class="centre-links">
        <div class="centre-title">
            <button class="centre-linkBtn" *ngFor="let link of truckCentrelinks; let i = index" (click)="routePage(link)">
                <span class="centre-button">
                    <div class="centre-linkBtn-Wrapper">
                        <div class="centre-linkBtn-Wrapper-fontDiv">
                            <span >
                                {{ link.label | lowercase | translate }}
                            </span>
                            <span class="fontName">
                                {{ link.name | lowercase | translate }}
                            </span>
                        </div>
                        <div>
                            <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')">
                        </div>
                    </div>
                </span>
            </button>
        </div>
    </div>
</section>
<section class="centre">
    <div class="centre-mobileLinks">
        <button class="centre-linkBtn" *ngFor="let link of mobileCentrelinks; let i = index" (click)="routePage(link)">
            <span class="centre-button">
                <div class="centre-linkBtn-Wrapper">
                    <div class="centre-linkBtn-Wrapper-fontDiv">
                        <span >
                            {{ link.label | lowercase | translate }}
                        </span>
                        <span class="fontName">
                            {{ link.name | lowercase | translate }}
                        </span>
                    </div>
                    <div>
                        <img [src]="assetService.getAssetUrl('assets/icons/ChevronRight4.jpg')">
                    </div>
                </div>
            </span>
        </button>
    </div>
</section>

<section class="mobile">
    <volvo-footer ></volvo-footer>    
</section>