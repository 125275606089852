<section class="customisation">
  <div class="customisation-customisationDiv">
      <div>
          <img
            src="assets/pages/services/Customisation.webp"
            class="customisation-customisationImg"
          />
        </div>
    <div class="customisation-title">
      <span>{{ "uk/components/footer/customisation" | lowercase | translate }}</span>
    </div>
    <div class="customisation-text">
      <span>{{ "uk/pages/services/customisation/text1" | lowercase | translate }}</span>
    </div>
  </div>
  <div class="customisation-list">
    <ul class="customisation-list-title" *ngFor="let list of customisationList">
      <span> {{ list.type | translate }}</span>
      <li>
        {{ list.name | translate }}
      </li>
    </ul>
  </div>
  <div class="customisation-text" id="volvoConnect">
    <span>{{ "uk/pages/services/customisation/text2" | lowercase | translate }}</span>
  </div>
</section>
<volvo-footer></volvo-footer>
