<section class="contract">
  <div class="contract-contractDiv" id="contract">
    <div class="contract-title">
      <span>{{ "uk/components/footer/serviceContracts" | lowercase | translate }}</span>
    </div>
    <div class="contract-text">
      <span class="contract-text-medium">{{
        "uk/pages/services/serviceContracts/text1" | lowercase | translate
      }}</span>
      <span>{{ "uk/pages/services/serviceContracts/text2" | lowercase | translate }}</span>
    </div>
    <div class="contract-text">
      <span>{{ "uk/pages/services/serviceContracts/text3" | lowercase | translate }}</span>
    </div>
  </div>
  <div class="contract-list">
    <ul class="contract-list-title" *ngFor="let list of contractList">
      <div >
        <img [src]="list.img" />
      </div>
      <span> {{ list.type | lowercase | translate }}</span>
      <li  id="customisation">
        {{ list.name | lowercase | translate }}
      </li>
    </ul>
  </div>
</section>
<volvo-footer></volvo-footer>
