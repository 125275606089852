import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SliderData } from 'src/app/core/store/buckets/buckets.model';
import { SearchStep, SearchStepSliderValue } from 'src/app/core/store/search/search.model';
import { GlobalUtils } from 'src/app/core/utils/global-utils/global-utils';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { SearchService } from 'src/app/core/store/search/search.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppState } from 'src/app/core/store';
import { isNumber } from 'util';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
@Component({
  selector: 'volvo-filter-slider',
  templateUrl: './filter-slider.component.html',
  styleUrls: ['./filter-slider.component.scss'],
})
export class FilterSliderComponent implements OnInit {
  isBrowser: boolean;
  private s0: Subscription;
  region: string;
  regionalSettings: RegionSettings;
  invalidMinValue: boolean = false;
  invalidMaxValue: boolean = false;

  @Input() aggregationTypeName: string;

  @Input() set showLables(value: boolean) {
    this.options[this.aggregationTypeName].hideLimitLabels = !value;
    this.options[this.aggregationTypeName].hidePointerLabels = !value;
  }

  _step: SearchStep;
  initMin = 0;
  initMax = 0;

  
  options: { [key: string]: Options } = {
    priceExclVatEuro: {
      floor: 0,
      ceil: 300000,
      step: 10000,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ariaLabel:' €',
      showSelectionBar: true
    },
    priceExclVatPounds: {
      floor: 0,
      ceil: 300000,
      step: 10000,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ariaLabel:' £',
      showSelectionBar: true
    },
    mileageKm: {
      floor: 0,
      ceil: 2000000,
      step: 10000,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ariaLabel:' km'
    },
    horsePower: {
      floor: 0,
      ceil: 800,
      step: 50,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ariaLabel:' hp'
    },
    dateFirstRegistration: {
      floor: 1990,
      ceil: new Date().getFullYear(),
      step: 1,
      hideLimitLabels: true,
      hidePointerLabels: true,
      ariaLabel:''
    }
  };

  form: FormGroup;

  @Input() set step(step: SearchStep) {
    this.options[this.aggregationTypeName].floor = (step.initValue as SearchStepSliderValue).min;
    this.options[this.aggregationTypeName].ceil = (step.initValue as SearchStepSliderValue).max;
    this.initMin = (step.value as SearchStepSliderValue).min;
    this.initMax = (step.value as SearchStepSliderValue).max;


    this._step = step;
  }

  @Input() set aggregations(aggregations: SliderData) {
  }

  @Output() filterChange = new EventEmitter();
  @Output() filterReset = new EventEmitter();
  constructor(private globalUtils: GlobalUtils, private searchService: SearchService, private store: Store) {
    this.isBrowser = this.globalUtils.isBrowser();
    this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === this.region)[0]);
    if(this.region === 'ZA')
    {
      this.options['priceExclVatEuro'].ariaLabel=' R';
    } 
  }
  ngOnInit(): void {
    this.initSubscriptions();
  }

  private initSubscriptions(): void {
    this.s0 = this.searchService.filter$.subscribe((filter) => {
      if (filter) {
        // this.options[this.aggregationTypeName].hideLimitLabels = !filter.steps[this.aggregationTypeName].isExpanded;
        // this.options[this.aggregationTypeName].hidePointerLabels = !filter.steps[this.aggregationTypeName].isExpanded;
      }
    }
    );
  }

  onChange(event: ChangeContext,min,max): void {
    this.invalidMinValue = false;
    this.invalidMaxValue=false;
    if(this.initMin !== this.options[this.aggregationTypeName].floor || this.initMax !== this.options[this.aggregationTypeName].ceil)
    {
      this.filterChange.emit({
        ...this._step,
        isSelected: true,
        value: {
          min: this.initMin,
          max: this.initMax
        },
      });
    }
    else{
      this.filterReset.emit({
        ...this.step
      });
    }
  }

  onMinInputChange(minInputControl,floor,maxInputControl) {
    let sliderMinValue=minInputControl.value.replace(/,/g, '').replace(/\./g, '');
    if(isNaN(Number(sliderMinValue)))
    {
      this.invalidMinValue = true;
    }
    else if(!minInputControl.value)
    {    
      this.invalidMinValue = false;
      this.initMin=floor;
      minInputControl.value=this.initMin;
      this.onChange(undefined,minInputControl,maxInputControl);
    }
    else if(parseInt(sliderMinValue) < floor)
    {
      this.invalidMinValue = true;
    }
    else if (parseInt(sliderMinValue) > this.initMax) {
      this.invalidMinValue = true;
    }
    else {
      this.invalidMinValue = false;
        this.initMin=parseInt(sliderMinValue);
        this.onChange(undefined,minInputControl,maxInputControl);
    }
  }

  onMaxInputChange(maxInputControl,ceil,minInputControl) {
    let sliderMaxValue=maxInputControl.value.replace(/,/g, '').replace(/\./g, '');
    if(isNaN(Number(sliderMaxValue)))
    {
      this.invalidMaxValue = true;
    }
    else if(!sliderMaxValue)
    {
      this.invalidMaxValue = false;
      this.initMax=ceil;
      maxInputControl.value=this.initMax;
      this.onChange(undefined,minInputControl,maxInputControl);
    }
    else if(parseInt(sliderMaxValue) < this.initMin)
    {
      this.invalidMaxValue = true;
    }
    else if (parseInt(sliderMaxValue) > parseInt(ceil)) {
      this.invalidMaxValue = true;
    }
    else {
      this.invalidMaxValue = false;
        this.initMax=parseInt(sliderMaxValue);
        this.onChange(undefined,minInputControl,maxInputControl);
      }
  }
  
  setMinValue()
  {
    if(this.region === 'UK' || this.region === 'TX' || this.region ===  'NS'){
      return this.aggregationTypeName !== 'dateFirstRegistration' ? Number(this.initMin).toLocaleString('en-GB') : this.initMin;
    }else{
      return this.aggregationTypeName !== 'dateFirstRegistration' ? Number(this.initMin).toLocaleString('de-DE') : this.initMin;
    }
  }
  setMaxValue()
  {
    if(this.region === 'UK' || this.region === 'TX' || this.region ===  'NS'){
      return this.aggregationTypeName !== 'dateFirstRegistration' ?  Number(this.initMax).toLocaleString('en-GB') : this.initMax;
    }else{
      return this.aggregationTypeName !== 'dateFirstRegistration' ?  Number(this.initMax).toLocaleString('de-DE') : this.initMax;
    }
  }
}
