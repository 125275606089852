<div *ngIf="regionalSettings.site==='volvoselected'" class="stickyBox-locale">
  <mat-selection-list #selectionList (selectionChange)="onChange()" class="MatInput Search-input customForMobile-mBgColor"
    [placeholder]="_step?.title">
    <div *ngIf="bucketsList">
      <ng-container *ngFor="let item of bucketsList">
        <mat-list-option [value]="item.key" [ngClass]="item.doc_count === 0?'FilterRadioSearch-diabled volvofont CPrimary customForMobile-mBgDisabled customHeight':'FilterRadioSearch-Btn volvofont CPrimary customHeight'"
          checkboxPosition="before" [selected]="checkSelected(_step.value, item.key)">
          <div [attr.data-cy]="aggregationTypeName">
            <span *ngIf="!isSuperstructure && !isFuelType">{{ item.key }} </span>
            <span *ngIf="isSuperstructure">{{ 'search/filter/country/superstructure/' + item.key |
              translateMany }}</span>
            <span *ngIf="isFuelType">{{ 'search/filter/fueltype/' + item.key |
              translateMany | capitalize}}</span>
            <span class="customForMobile-mPadding" style="float: right;" data-cy="count">{{ item.doc_count }}</span>
          </div>
        </mat-list-option>
      </ng-container>
    </div>
  </mat-selection-list>
</div>
<div *ngIf="regionalSettings.site!=='volvoselected'" class="stickyBox-locale">
  <mat-selection-list #selectionList (selectionChange)="onChange()" class="MatInput Search-input"
    [placeholder]="_step?.title" style="background: #FFF; height: auto;">
    <div *ngIf="bucketsList">
      <ng-container *ngFor="let item of bucketsList">
        <mat-list-option *ngIf='_step?.title !== "Offer and Warranty"' [value]="item.key" [ngClass]="item.doc_count === 0?'FilterRadioSearch-disabled volvofont CPrimary customForMobile-mBgDisabled customHeight':'FilterRadioSearch-Btn volvofont CPrimary customHeight'"
          checkboxPosition="before" style="height: 32px;" [selected]="checkSelected(_step.value, item.key)">
          <div data-cy="filter-vehmodel" class="volvofont">
            <span >{{ keyName(item.key) }} </span>
            <span style="float: right;">{{ item.doc_count }}</span>
          </div>
        </mat-list-option>
      </ng-container>
    </div>
    <span *ngIf="sortBucketsList">
      <ng-container *ngFor="let item of sortBucketsList">
        <mat-list-option *ngIf='_step?.title === "Offer and Warranty"' [value]="item.key" [ngClass]="item.doc_count === 0?'FilterRadioSearch-disabled volvofont CPrimary customForMobile-mBgDisabled customHeight':'FilterRadioSearch-Btn volvofont CPrimary customHeight'" 
          checkboxPosition="before" style="height: 32px;" [selected]="checkSelected(_step.value, item.key)">
          <div data-cy="filter-vehmodel" class="volvofont">
            <span >{{ keyName(item.key) }} </span>
            <span style="float: right;">{{ item.doc_count }}</span>
          </div>
      </mat-list-option>
      </ng-container>
      
    </span>
  </mat-selection-list>
</div>