import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LocalesStateLang } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { SearchQuery, Direction, SortField } from 'src/app/core/models/search-query.model';
import { NotificationQuery, NotificationData } from 'src/app/core/models/notification-query.model';
import { SearchStateInterface } from 'src/app/core/store/search/search.model';
import { NotificationService } from 'src/app/core/services/notification/notification-service';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { MatDialog } from '@angular/material/dialog';
import { countries } from '~/core/constants/country';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchState } from 'src/app/core/store/search/search.state';
import { Base64 } from 'js-base64';
import { ScreenUtils } from '../../core/utils/screen-utils/screen-utils';
import isValid from "uk-postcode-validator";
import validatePhoneNumber from "validate-phone-number-node-js";
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { regionEnum } from '~/core/declaration/declaration';
import { AssetService } from '~/core/services/assets/asset.service';
export interface NotificationFilters {
  isFilterSelected: boolean;
}

@Component({
  selector: 'volvo-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {
  currentLang: string = '';
  notificationQuery: NotificationQuery;
  region: string;
  searchData: any;
  countryDropDown: any;
  submitted: boolean = false;
  langId: string;
  url: string = "";
  currCountry: string;
  showCheckoxBorder: boolean = false;
  notificationForm = this.formBuilder.group({
    phone: ['', [Validators.pattern(/\d/g)]],
    email: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    name: '',
    country: '',
    company: '',
    instantly: true,
    daily: false,
    weekly: false,
    consent: [false, [Validators.requiredTrue]],
    postcode:''
  });
  priveMaxValue: number;
  decodeUrl: string;
  encodeUrl: string;
  btnstate: boolean = false;
  hasFilters: boolean;
  isPhone: boolean = false;
  isSelectedSite: boolean = false;
  isValidPostcode:boolean=true;
  isValidPhoneno:boolean=true;
  isEmailValid:boolean=true;
  regionalSettings: RegionSettings; 
  regionEnum = regionEnum;
  site: string;
  constructor(
    public dialogRef: MatDialogRef<NotificationPopupComponent>,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private localesService: LocalesService,
    public store: Store,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private searchState: SearchState,
    public assetService: AssetService,
    @Inject(MAT_DIALOG_DATA) public data: NotificationFilters
  ) {
    //this.region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
        this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
        

        if(this.regionalSettings){
          this.region = this.regionalSettings?.region;
          this.site = this.regionalSettings?.site;
          if(this.site === "volvoselected")
            this.isSelectedSite = true;
        }
      
    this.searchData = store.selectSnapshot<SearchStateInterface>((state: AppState) => state.search).filter;
    this.currCountry = store.selectSnapshot<string>((state: AppState) => state.language.country.curCountry.id);
    this.countryDropDown = countries;
    if (this.countryDropDown.filter(x => x.id === 'other').length === 0) {
      this.countryDropDown.push({ label: 'Other', id: 'other' });
    }

    if (this.region.toLowerCase() === 'eu') {
      this.countryDropDown = this.countryDropDown.filter(x => x.id !== 'za');
    }
    else {
      this.countryDropDown = this.countryDropDown.filter(x => x.id === 'za' || x.id === 'other');
    }
    this.priveMaxValue = this.searchState.setPriceFilterValue();

    if (this.searchData.steps.priceExclVatEuro)
      this.searchData = this.searchState.checkAndSetZarPrice(this.searchData);
    this.hasFilters = data.isFilterSelected;
  }
  onClose(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.isPhone = ScreenUtils.isPhone() || ScreenUtils.isTablet();
    this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.langId = lang.curLang.id
      this.currentLang = this.currCountry;
    });
  }

  async onSubmit(): Promise<void> {
    this.showCheckoxBorder = true;
    if (((this.isSelectedSite || this.region === 'TX') && this.notificationForm.valid) || (!this.isSelectedSite && (this.notificationForm.valid && this.isValidPostcode && this.isValidPhoneno))) {
      this.btnstate = true;
      this.showCheckoxBorder = false;
      this.notificationQuery = new NotificationQuery();
      this.notificationQuery.SearchQuery = this.getSearchData();
      this.notificationQuery.NotificationData = this.getNotificatiationData();
      this.notificationService.saveNotificationData(this.notificationQuery).subscribe(response => {
        if (response.ok) {
          if (response.body.notificationData.country && this.isSelectedSite) {
            response.body.notificationData.country = response.body.notificationData.country;
          }
          this.submitted = true;
          this.setNotificationData(response.body.notificationData);
          if (this.isPhone) {
            this.dialogRef.updatePosition({ bottom: "bottom" });
          }
          else {
            this.dialogRef.updatePosition({ top: '' });
          }
          this.dialogRef.addPanelClass("volvo-notificationSuccess-popup");
        }
      });
    }
  }

  getNotificatiationData() {
    var notificationData = new NotificationData();
    notificationData.Name = this.notificationForm.controls['name'].value;
    notificationData.Email = this.notificationForm.controls['email'].value;
    notificationData.Phone = this.notificationForm.controls['phone'].value;
    if (this.notificationForm.controls['country'].value && (this.isSelectedSite || this.region === regionEnum.TX)) {
      if(this.isSelectedSite)
        notificationData.Country = this.countryDropDown.find(country => country.id === this.notificationForm.controls['country'].value).label;
      else
        notificationData.Country = this.notificationForm.controls['country'].value;
    }
    else {
      notificationData.Country = '';
    }
    notificationData.Company = this.notificationForm.controls['company'].value;
    notificationData.Instantly = this.notificationForm.controls['instantly'].value;
    notificationData.Daily = this.notificationForm.controls['daily'].value;
    notificationData.Weekly = this.notificationForm.controls['weekly'].value;
    notificationData.Active = true;
    notificationData.LangCode = this.langId;
    notificationData.Region = this.region;
    notificationData.Site = this.site;
    notificationData.EmailNotification = this.notificationForm.controls['email'].value === '' ? false : true;
    notificationData.SmsNotification = this.notificationForm.controls['phone'].value === '' ? false : true;
    notificationData.PushNotification == false;
    this.url = this.activatedRoute.snapshot.queryParams.qf;
    this.decodeUrl = Base64.decode(this.url);
    let splitDecodeUrl = this.decodeUrl.split('&').filter(x => !x.includes('requestingFilter=')).join('&') + '&requestingFilter=""';
    this.url = this.router.url.split('?')[0] + "?qf=" + Base64.encode(splitDecodeUrl);
    notificationData.Url = this.url;
    notificationData.Postcode=this.notificationForm.controls['postcode'].value;
    return notificationData;
  }

  getSearchData() {
    var searchQuery = new SearchQuery();
    //this.searchData : We need to take from store since the user may have removed one of the filters from notification popup.
    this.searchData = this.store.selectSnapshot<SearchStateInterface>((state: AppState) => state.search).filter;
    if (this.searchData.steps.priceExclVatEuro)
      this.searchData = this.searchState.checkAndSetZarPrice(this.searchData);

    searchQuery.freeText = this.searchData.q;
    searchQuery.page = this.searchData.page === undefined ? 1 : this.searchData.page;
    searchQuery.size = 12;
    searchQuery.site = this.site;
    searchQuery.region = this.region;

    if (this.searchData.requestingFilter) {
      searchQuery.requestingFilter = this.searchData.requestingFilter;
    }

    if (this.searchData.expandingFilter) {
      searchQuery.expandingFilter = this.searchData.expandingFilter;
    }

    if (this.searchData.steps.stockLocationAddress) {
      searchQuery.stockLocationAddress = this.searchData.steps.stockLocationAddress.value === '' ? null : this.searchData.steps.stockLocationAddress.value;
    }

    if (this.searchData.steps.stockLocationCity) {
      searchQuery.stockLocationCity = (this.searchData.steps.stockLocationCity.value === 'All' || this.searchData.steps.stockLocationCity.value === '' )? null:this.searchData.steps.stockLocationCity.value;
    }


    if (this.searchData.steps.stockLocationCountry) {
      searchQuery.stockLocationCountry = this.searchData.steps.stockLocationCountry.value === '' ? null : (this.region === 'ZA' ? null : this.searchData.steps.stockLocationCountry.value);
    }

    if (this.searchData.steps.superstructureType) {
      searchQuery.superstructureType = (this.searchData.steps.superstructureType.value === 'All' || this.searchData.steps.superstructureType.value === '') ? null : this.searchData.steps.superstructureType.value;
    }

    if (this.searchData.steps.majorCategory) {
      searchQuery.majorCategory = (this.searchData.steps.majorCategory.value === 'All' || this.searchData.steps.majorCategory.value === '') ? null : this.searchData.steps.majorCategory.value;
    }

    if (this.searchData.steps.axleConfiguration) {
      searchQuery.axleConfiguration = (this.searchData.steps.axleConfiguration.value === 'All' || this.searchData.steps.axleConfiguration.value === '') ? null : this.searchData.steps.axleConfiguration.value;
    }

    if (this.searchData.steps.make) {
      searchQuery.make = (this.searchData.steps.make.value === 'All' || this.searchData.steps.make.value === '') ? null : this.searchData.steps.make.value;
    }

    if (this.searchData.steps.cabType) {
      searchQuery.cabType = (this.searchData.steps.cabType.value === 'All' || this.searchData.steps.cabType.value === '') ? null :this.searchData.steps.cabType.value;
    }

    if (this.searchData.steps.advertisementCategory) {
      searchQuery.advertisementCategory = (this.searchData.steps.advertisementCategory.value === 'All' || this.searchData.steps.advertisementCategory.value === '') ? null :this.searchData.steps.advertisementCategory.value;
    }

    if (this.searchData.steps.offerAndWarranty) {
      searchQuery.offerAndWarranty = (this.searchData.steps.offerAndWarranty.value === 'All' || this.searchData.steps.offerAndWarranty.value === '') ? null : this.searchData.steps.offerAndWarranty.value;
    }
    
    if (this.searchData.steps.model) {
      searchQuery.model = (this.searchData.steps.model.value === 'All' || this.searchData.steps.model.value === '') ? null : this.searchData.steps.model.value;
    }

    if (this.searchData.steps.emission) {
      searchQuery.emission = (this.searchData.steps.emission.value === 'All' || this.searchData.steps.emission.value === '') ? null : this.searchData.steps.emission.value;
    }

    if (this.searchData.steps.fuelType) {
      searchQuery.fuelType = (this.searchData.steps.fuelType.value === 'All' || this.searchData.steps.fuelType.value === '') ? null : this.searchData.steps.fuelType.value;
    }

    if (this.searchData.steps.mileageKm) {
      searchQuery.mileage = {
        maxMileage: this.searchData.steps.mileageKm
          ? this.searchData.steps.mileageKm.value.max
          : Number.MAX_SAFE_INTEGER,
        minMileage: this.searchData.steps.mileageKm ? this.searchData.steps.mileageKm.value.min : 0
      };
    }

    if (this.searchData.steps.horsePower) {
      searchQuery.horsePower = {
        maxHorsePower: this.searchData.steps.horsePower
          ? this.searchData.steps.horsePower.value.max
          : Number.MAX_SAFE_INTEGER,
        minHorsePower: this.searchData.steps.horsePower ? this.searchData.steps.horsePower.value.min : 0
      };
    }

    if (this.searchData.steps.modelYear) {
      searchQuery.modelYear = {
        modelYearMax: this.searchData.steps.modelYear
          ? this.searchData.steps.modelYear.value.max
          : Number.MAX_SAFE_INTEGER,
        modelYearMin: this.searchData.steps.modelYear ? this.searchData.steps.modelYear.value.min : 0
      };
    }

    if (this.searchData.steps.priceExclVatEuro) {
      searchQuery.priceRange = {
        maxPrice: this.searchData.steps.priceExclVatEuro
          ? this.searchData.steps.priceExclVatEuro.value.max
          : this.priveMaxValue,
        minPrice: this.searchData.steps.priceExclVatEuro ? this.searchData.steps.priceExclVatEuro.value.min : 0
      };
    }

    if (this.searchData.steps.dateFirstRegistration) {
      searchQuery.dateFirstRegistration = {
        maxDateFirstRegistration: this.searchData.steps.dateFirstRegistration
          ? this.searchData.steps.dateFirstRegistration.value.max
          : Number.MAX_SAFE_INTEGER,
        minDateFirstRegistration: this.searchData.steps.dateFirstRegistration
          ? this.searchData.steps.dateFirstRegistration.value.min
          : 0
      };
    }

    if (this.searchData.sort) {
      const lowest = this.searchData.sort.toLowerCase().includes('lowest');
      const price = this.searchData.sort.toLowerCase().includes('price');

      searchQuery.sortQuery = {
        direction: lowest ? Direction.Asc : Direction.Desc,
        sortField: price ? SortField.Price : SortField.PublishDate
      };
    } else {
      searchQuery.sortQuery = {
        direction: Direction.Desc,
        sortField: SortField.PublishDate
      };
    }
    return searchQuery;
  }

  setNotificationData(pdata) {
    this.notificationForm.controls['phone'].setValue(pdata.phone);
    this.notificationForm.controls['email'].setValue(pdata.email);
    this.notificationForm.controls['name'].setValue(pdata.name);
    this.notificationForm.controls['country'].setValue(pdata.country);
    this.notificationForm.controls['company'].setValue(pdata.company);
    this.notificationForm.controls['instantly'].setValue(pdata.instantly);
    this.notificationForm.controls['daily'].setValue(pdata.daily);
    this.notificationForm.controls['weekly'].setValue(pdata.weekly);
  }
  toogleSlideButton(ptype: string) {
    this.notificationForm.controls['instantly'].setValue(false);
    this.notificationForm.controls['daily'].setValue(false);
    this.notificationForm.controls['weekly'].setValue(false);
    switch (ptype) {
      case 'instantly': this.notificationForm.controls['instantly'].setValue(true);
        break;
      case 'daily': this.notificationForm.controls['daily'].setValue(true);
        break;
      case 'weekly': this.notificationForm.controls['weekly'].setValue(true);
        break;
    }
  }

  postcodeChange()
  {
    if(this.notificationForm.controls['postcode'].value)
      this.isValidPostcode=isValid(this.notificationForm.controls['postcode'].value);
    else
      this.isValidPostcode=true;
  }
  phoneNumberChange()
  {
    if(this.notificationForm.controls['phone'].value)
      this.isValidPhoneno=validatePhoneNumber.validate(this.notificationForm.controls['phone'].value);
    else
      this.isValidPhoneno=true;
  }
  validateEmail()
  {
    if(this.notificationForm.controls['email'].valid)
    {
      this.isEmailValid=true;
    } 
    else if (!this.notificationForm.controls['email'].value)
    {
      this.isEmailValid=true;
    } 
    else
    { 
      this.isEmailValid=false;
    }
  }
}
