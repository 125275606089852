import { Action, State, StateContext } from "@ngxs/store";
import { CountrySettingsStateInterface, RegionalSettingsStateInterface } from "./regionalSettings.model";
import { GetCountrySettings, GetRegionalSettings, InitCountrySettings, InitRegionalSettings, SetRegion } from "./regionalSettings.actions";
import { Injectable } from "@angular/core";

const defaultRegionSettings = [
    {
        site: "volvoselected",
        region: "ZA",
        singleCountryRegion: true,
        vfsCalculatorEnabled: false,
        goldContractEnabled: false,
        zarPriceEnabled: true,
    },
    {
        site: "volvoselected",
        region: "AU",
        singleCountryRegion: true,
        vfsCalculatorEnabled: false,
        goldContractEnabled: true,
        zarPriceEnabled: false,
    },
    {
        site: "volvoselected",
        region: "EU",
        singleCountryRegion: false,
        vfsCalculatorEnabled: false,
        goldContractEnabled: true,
        zarPriceEnabled: false,
    },
    {
        site: "ukwebportal",
        region: "UK",
        singleCountryRegion: true,
        vfsCalculatorEnabled: false,
        goldContractEnabled: false,
        zarPriceEnabled: false,
    },
    {
        site: "northandscotlandwebportal",
        region: "NS",
        singleCountryRegion: true,
        vfsCalculatorEnabled: false,
        goldContractEnabled: false,
        zarPriceEnabled: false,
    },
    {
        site: "TX",
        region: "TX",
        singleCountryRegion: true,
        vfsCalculatorEnabled: false,
        goldContractEnabled: false,
        zarPriceEnabled: false,
    },
];

@State<RegionalSettingsStateInterface>({
    name: "regionalSettings",
    defaults: {
        regionsSettings: defaultRegionSettings            
    },
})
@Injectable()
export class RegionalSettingsState {
    constructor() { }

    @Action(GetRegionalSettings)
    getRegionalSettings({ getState }: StateContext<RegionalSettingsStateInterface>) {
        const state: RegionalSettingsStateInterface = getState();
    }

    @Action(InitRegionalSettings)
    initLanguage({ getState, patchState }: StateContext<RegionalSettingsStateInterface>) {
        const state: RegionalSettingsStateInterface = getState();
        const newState = {
            // Maybe we can load this from the backend in the future, 
            // in that case, load it and init the regionalSettings here
        };

        patchState(newState);
    }

    @Action(SetRegion)
    setRegion({ getState, patchState }: StateContext<RegionalSettingsStateInterface>, { payload }: SetRegion) {
        const newState: Partial<RegionalSettingsStateInterface> = {
            regionsSettings: defaultRegionSettings.filter(region => region.region === payload)
        };
        patchState(newState);
    }
}

@State<CountrySettingsStateInterface>({
    name: "countrySettings",
    defaults: {
        countrysSettings:
            [
                {
                    country: "de",
                    selectedProEnabled: false,
                    selectedAdvancedEnabled: true,
                },
                {
                    country: "dk",
                    selectedProEnabled: false,
                    selectedAdvancedEnabled: false,
                },
                {
                    country: "global",
                    selectedProEnabled: true,
                    selectedAdvancedEnabled: true,
                }
            ]
    },
})
@Injectable()
export class CountrySettingsState {
    constructor() { }

    @Action(GetCountrySettings)
    getCountrySettings({ getState }: StateContext<CountrySettingsStateInterface>) {
        const state: CountrySettingsStateInterface = getState();
    }

    @Action(InitCountrySettings)
    initLanguage({ getState, patchState }: StateContext<CountrySettingsStateInterface>) {
        const state: CountrySettingsStateInterface = getState();
        const newState = {
            // Maybe we can load this from the backend in the future, 
            // in that case, load it and init the regionalSettings here
        };

        patchState(newState);
    }
}
