import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LocalizeRouterService, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalesStateLang, LocalesStateLangList } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { TranslationService } from '~/core/services/translations/translation-service';
import { allLangRegEx } from '~/core/constants/constants';

@Component({
  selector: 'volvo-markets-page',
  templateUrl: './markets-page.component.html',
  styleUrls: ['./markets-page.component.scss'],
})
export class MarketsPageComponent implements OnInit {
  public sendStatus = false;
  curLang: LocalesStateLangList;

  langObj = {
    curLang: { label: 'English', id: 'en', apiResLang: 'en' },
    list: [
      { label: 'English', id: 'en', apiResLang: 'en', code: 'en-US' },
      { label: 'Lietuvių', id: 'lt', apiResLang: 'lt', code: 'lt-LT' },
      { label: 'Čeština', id: 'cz', apiResLang: 'cs', code: 'cs-CZ' },
      { label: 'Dansk', id: 'dk', apiResLang: 'da', code: 'da-DK' },
      { label: 'Français', id: 'fr', apiResLang: 'fr', code: 'fr-FR' },
      { label: 'Español', id: 'es', apiResLang: 'es', code: 'es-ES' },
      { label: 'Norsk', id: 'no', apiResLang: 'no', code: 'no-NO' },
      { label: 'Italiano', id: 'it', apiResLang: 'it', code: 'it-IT' },
      { label: 'Suomi', id: 'fi', apiResLang: 'fi', code: 'fi-FI' },
      { label: 'Polski', id: 'pl', apiResLang: 'pl', code: 'pl-PL' },
      { label: 'Magyar', id: 'hu', apiResLang: 'hu', code: 'hu-HU' },
      { label: 'Slovenčina', id: 'sk', apiResLang: 'sk', code: 'sk-SK' },
      { label: 'Slovenščina', id: 'si', apiResLang: 'si', code: 'sl-SI' },
      { label: 'Deutsch (AT)', id: 'at', apiResLang: 'de-at', code: 'de-AT' },
      { label: 'Română', id: 'ro', apiResLang: 'ro', code: 'ro-RO' },
      { label: 'Bosanski', id: 'ba', apiResLang: 'bs', code: 'bs-BA' },
      { label: 'Македонски', id: 'mk', apiResLang: 'mk', code: 'mk-MK' },
      { label: 'Српски', id: 'rs', apiResLang: 'rs', code: 'sr-RS' },
      { label: 'Hrvatski', id: 'hr', apiResLang: 'hr', code: 'hr-HR' },
      { label: 'Deutsch (DE)', id: 'de', apiResLang: 'de', code: 'de-DE' },
      { label: 'Nederlands', id: 'nl', apiResLang: 'nl', code: 'nl-NL' },
      { label: 'Български', id: 'bg', apiResLang: 'bg', code: 'bg-BG' },
      { label: 'Svenska', id: 'se', apiResLang: 'sv', code: 'sv-SE' },
      { label: 'Eesti', id: 'ee', apiResLang: 'et', code: 'et-EE' },
      { label: 'Latviešu', id: 'lv', apiResLang: 'lv', code: 'lv-LV' },
      { label: 'Русский', id: 'ru', apiResLang: 'ru', code: 'ru-RU' },
      { label: 'Français (BE)', id: 'be-fr', apiResLang: 'fr', code: 'fr-BE' },
      { label: 'Nederlands (BE)', id: 'be-nl', apiResLang: 'nl', code: 'nl-BE' },
      { label: 'Deutsch (CH)', id: 'ch-de', apiResLang: 'de', code: 'de-CH' },
      { label: 'Français (CH)', id: 'ch-fr', apiResLang: 'fr', code: 'fr-CH' },
      { label: 'Italiano (CH)', id: 'ch-it', apiResLang: 'it', code: 'it-CH' },
      { label: 'English', id: 'en-za', apiResLang: 'en-za', code: 'en-ZA' },
      { label: 'English', id: 'en-gb', apiResLang: 'en-gb', code: 'en-GB' },
      { label: 'English', id: 'en-ns', apiResLang: 'en-ns', code: 'en-NS' },
      { label: 'English', id: 'en-tx', apiResLang: 'en-tx', code: 'en-TX' },
    ]
  };

  constructor(
    public localesService: LocalesService,
    private settings: LocalizeRouterSettings,
    private location: Location,
    private localizeRouterService: LocalizeRouterService,
    private translationService: TranslationService
  ) {
    translationService.loadTrans('en').then();
    
  }

  ngOnInit(): void { }

  scroll(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
  }

  SouthernObj = {
    listB: [
      { label: 'België', id: 'be-nl' },
      { label: 'Belgique', id: 'be-fr' },
      { label: 'Bosna', id: 'ba' },
      { label: 'Bulgariya', id: 'bg' },
    ],
    listC: [
      { label: 'Česká republika', id: 'cz' },
      { label: 'Србија', id: 'rs' },
    ],
    listD: [
      { label: 'Danmark', id: 'dk' },
      { label: 'Deutschland', id: 'de' },
    ],
    listE: [
      { label: 'Eesti', id: 'ee' },
      { label: 'España', id: 'es' },
    ],
    listF: [{ label: 'France', id: 'fr' }],
    listH: [{ label: 'Hrvatska', id: 'hr' }],
    listI: [{ label: 'Italia', id: 'it' }],
    listL: [
      { label: 'Latvija', id: 'lv' },
      { label: 'Lietuva', id: 'lt' },
    ],
    listM: [
      { label: 'Makedonija', id: 'mk' },
      { label: 'Magyarország', id: 'hu' },
    ],
    listN: [
      { label: 'Norge', id: 'no' },
      { label: 'Nederland', id: 'nl' },
    ],
    listP: [{ label: 'Polska', id: 'pl' }],
    listR: [{ label: 'România', id: 'ro' }],

    listS: [
      { label: 'Schweiz', id: 'ch-de' },
      { label: 'Slovenija', id: 'si' },
      { label: 'Slovensko', id: 'sk' },
      { label: 'Suisse', id: 'ch-fr' },
      { label: 'Suomi', id: 'fi' },
      { label: 'Sweden', id: 'se' },
    ],
    listO: [{ label: 'Österreich', id: 'at' }],
    listEn: [{ label: 'English', id: 'en' }],
    listRu: [{ label: 'русский язык', id: 'ru' }],
  };

  onLangChange(itemId): void {
    const updateLang = this.langObj.list.find((langItem) => langItem.id === itemId);
    this.setLangNewChange(updateLang);
  }

  setLangNewChange(curLang: LocalesStateLangList): void {
    this.localesService.setLocales({ lang: { curLang } });
    if (curLang.id === 'en') {
      var path = this.location.path().toString().replace('/markets', '');
      this.location.go(path);
      location.reload();
    } else {
      this.settings.alwaysSetPrefix = true;
      var temppath = this.location.path().toString().replace(allLangRegEx, '/');
      var path = temppath.replace('/markets', '');
      this.localizeRouterService.parser.currentLang = curLang.id;
      var routepath = this.localizeRouterService.parser.addPrefixToUrl(path);
      
      this.location.go(routepath);
      location.reload();
    }
  }
}
