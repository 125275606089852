import { Component, OnInit } from '@angular/core';
import { ScreenUtils } from '../../../core/utils/screen-utils/screen-utils';
import { CentreNavLinks, SalesTeam } from '../contact-page.model';
import { HtmlRoutePages } from '../../../app-routing.model';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationEnd
} from '@angular/router';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';

@Component({
  selector: 'volvo-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit {


  isTablet: boolean = false;
  salesImage: string[];
  salesName: string[];
  salesDesignation: string[];
  salesCity: string[];
  salesEmail: string[];
  salesPhone: string[];
  path: string = '';
  region:string;
  regionEnum=regionEnum;
  truckCentrelinks: CentreNavLinks[] = [
    {
        label: 'uk/pages/contact/UserTruckCentre',
        name: 'uk/pages/contact/UserTruckCentre1',
        path: HtmlRoutePages.centreBanbury,

    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre2',
      path: HtmlRoutePages.centreBardon,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre3',
      path: HtmlRoutePages.centreEly,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre4',
      path: HtmlRoutePages.centreEnfield,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre5',
      path: HtmlRoutePages.centreFelixStowe,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre6',
      path: HtmlRoutePages.centreHayes,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre7',
      path: HtmlRoutePages.centreReading,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre8',
      path: HtmlRoutePages.centreWellingBorough,
    },
  ];
  salesTeamEconomyDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/Steven Worts.webp',
      imageName: 'Steven Worts',
      imageDesignation: 'uk/pages/contact/salesExecutive2',
      imageCentre: '',
      imageEmail: 'steven.worts@volvo.com',
      imageMobile: '07525 202999',
    },
  ]

  salesTeamMangementDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/Carl White.webp',
      imageName: 'Carl White',
      imageDesignation: 'uk/pages/contact/salesExecutive3',
      imageCentre: '',
      imageEmail: 'carl.white@volvo.com',
      imageMobile: '',
    },
    {
      imageUrl: '/assets/pages/contactus/Rick Went.webp',
      imageName: 'Rick Went',
      imageDesignation: 'uk/pages/contact/salesExecutive4',
      imageCentre: '',
      imageEmail: 'rick.went@volvo.com',
      imageMobile: '07976 095714',
    },
  ]

  salesTeamDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/Karl Love.webp',
      imageName: 'Karl Love',
      imageDesignation: 'uk/pages/contact/salesExecutive1',
      imageCentre: 'uk/pages/contact/salesCentre1',
      imageEmail: 'karl.love@volvo.com',
      imageMobile: '07808 884583',
   },
  {
    imageUrl: '/assets/pages/contactus/Kevin Bassindale.webp',
    imageName: 'Kevin Bassindale',
    imageDesignation: 'uk/pages/contact/salesExecutive1',
    imageCentre: 'uk/pages/contact/salesCentre2',
    imageEmail: 'kevin.bassindale@volvo.com',
    imageMobile: '07715 798422',
 },
{
  imageUrl: '/assets/pages/contactus/Phil Wheeler.webp',
  imageName: 'Phil Wheeler',
  imageDesignation: 'uk/pages/contact/salesExecutive1',
  imageCentre: 'uk/pages/contact/salesCentre3',
  imageEmail: 'phil.wheeler@volvo.com',
  imageMobile: '07771 946787',
},
{
  imageUrl: '/assets/pages/contactus/Tommy Stewart.webp',
  imageName: 'Tommy Stewart',
  imageDesignation: 'uk/pages/contact/salesExecutive1',
  imageCentre: 'uk/pages/contact/salesCentre4',
  imageEmail: 'tommy.stewart@volvo.com',
  imageMobile: '07526 505164',
}
];

  constructor(private route: ActivatedRoute, private router: Router,
    private regionSettingService:RegionalSettingsService,
    private breadCrumbService: BreadcrumbserviceService,) {
   
  }

  ngOnInit(): void {
    if (ScreenUtils.isTablet()) this.isTablet = true;
    this.path = this.route.snapshot.queryParamMap.get('path');
    this.regionSettingService.regionalSettings$.subscribe((res:any) => {
      this.region = res.regionsSettings[0].region;
    });
    if(this.region===regionEnum.NS)
    {
      this.salesTeamEconomyDetails= [
        {
          imageUrl: '/assets/pages/contactus/ns/Barry.webp',
          imageName: 'Barry Knight',
          imageDesignation: 'uk/pages/contact/salesExecutive6',
          imageCentre: '',
          imageEmail: 'barry.knight@volvo.com',
          imageMobile: '07843 803973',
        },
        {
          imageUrl: '/assets/pages/contactus/ns/Ian.webp',
          imageName: 'Ian Laverie',
          imageDesignation: 'uk/pages/contact/salesExecutive7',
          imageCentre: '',
          imageEmail: 'ian.laverie@volvo.com',
          imageMobile: '07845 124126',
        },
      ]
      this.salesTeamMangementDetails= [
        {
          imageUrl: '/assets/pages/contactus/ns/Michael J.webp',
          imageName: 'Michael Johnstone',
          imageDesignation: 'uk/pages/contact/salesExecutive8',
          imageCentre: '',
          imageEmail: 'michael.johnstone@vfsco.com',
          imageMobile: '07966 514311',
        }
      ]
      this.salesTeamDetails = [
        {
          imageUrl: '/assets/pages/contactus/ns/Jamie_Derrick.webp',
          imageName: 'Jamie Derrick',
          imageDesignation: 'uk/pages/contact/salesExecutive5',
          imageCentre: '',
          imageEmail: 'jamie.derrick@volvo.com',
          imageMobile: '07594 515174',
       }];
    }
    //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Meet the Team', HtmlRoutePages.contact);
  }
  ngAfterViewInit(): void {
    this.scrollToElement();
}
  scrollToElement(): void {
    if (this.path != '' || this.path != null) {
      const el = document.getElementById(this.path);
      if (el) {
        el.scrollIntoView({
          block: 'start',
        });
      }
    }
  }
}
