<div *ngIf="regionalSettings.site==='volvoselected'" class="inputDiv">
    <div>
        <input #minInput [ngClass]="invalidMinValue?'invalidMinValue':''" (blur)="onMinInputChange(minInput,options[aggregationTypeName].floor,maxInput)"
        (keyup.enter)="onMinInputChange(minInput,options[aggregationTypeName].floor,maxInput)"  (keydown.enter)="minInput.blur()" (value)="initMin"
        [value]="setMinValue()" [placeholder]="options[aggregationTypeName].floor" (focus)="minInput.select()">
        <span>{{ options[aggregationTypeName].ariaLabel}}</span>
    </div>
    <div>
        <input #maxInput [ngClass]="invalidMaxValue?'invalidMaxValue':''" (blur)="onMaxInputChange(maxInput,options[aggregationTypeName].ceil,minInput)" 
        (keyup.enter)="onMaxInputChange(maxInput,options[aggregationTypeName].ceil,minInput)" (keydown.enter)="maxInput.blur()" (value)="initMax"
        [value]="setMaxValue()"  [placeholder]="options[aggregationTypeName].ceil" (focus)="maxInput.select()"/>
        <span>{{ options[aggregationTypeName].ariaLabel}}</span> 
    </div>
</div>


<div *ngIf="regionalSettings.site==='volvoselected'" class="slider">
    <ngx-slider  [(value)]="initMin" [(highValue)]="initMax" (userChangeEnd)="onChange($event,minInput,maxInput)" [options]="options[aggregationTypeName]"></ngx-slider>
</div>

<div *ngIf="regionalSettings.site!=='volvoselected'" class="inputDiv" [ngClass]="aggregationTypeName === 'priceExclVatEuro'?'display-show':''">
    <div [ngClass]="aggregationTypeName === 'priceExclVatEuro'?'display-hide':'display'">
        <input class="font" #minInput [ngClass]="invalidMinValue?'invalidMinValue':''" (blur)="onMinInputChange(minInput,options[aggregationTypeName].floor,maxInput)"
        (keyup.enter)="onMinInputChange(minInput,options[aggregationTypeName].floor,maxInput)"  (keydown.enter)="minInput.blur()" (value)="initMin"
        [value]="setMinValue()" [placeholder]="options[aggregationTypeName].floor" (focus)="minInput.select()">
        <span>{{ options[aggregationTypeName].ariaLabel}}</span>
    </div>
    <div>
        <span [ngClass]="aggregationTypeName === 'priceExclVatEuro'?'display':'display-hide'">Max. £ </span>
        <input  class="font" #maxInput [ngClass]="invalidMaxValue?'invalidMaxValue':''" (blur)="onMaxInputChange(maxInput,options[aggregationTypeName].ceil,minInput)" 
        (keyup.enter)="onMaxInputChange(maxInput,options[aggregationTypeName].ceil,minInput)" (keydown.enter)="maxInput.blur()" (value)="initMax"
        [value]="setMaxValue()"  [placeholder]="options[aggregationTypeName].ceil" (focus)="maxInput.select()"/>
        <span [ngClass]="aggregationTypeName === 'priceExclVatEuro'?'display-hide':'display'">{{ options[aggregationTypeName].ariaLabel}}</span> 
    </div>
</div>
<div *ngIf="regionalSettings.site!=='volvoselected'" class="slider">
    <ngx-slider *ngIf="isBrowser && aggregationTypeName === 'priceExclVatEuro'" [(value)]="initMax" (userChangeEnd)="onChange($event,minInput,maxInput)" [options]="options[aggregationTypeName]" class="margin" ></ngx-slider>
    <ngx-slider *ngIf="isBrowser  && aggregationTypeName !== 'priceExclVatEuro'" [(value)]="initMin" [(highValue)]="initMax"  [options]="options[aggregationTypeName]" (userChangeEnd)="onChange($event,minInput,maxInput)"></ngx-slider>
</div>