<div *ngIf="data" class="PopupOLD addheight">
  <div class="VolvoLogo-wp">
      <div class="VolvoLogo-logo">
          <img class="VolvoLogo-logo-img" [src]="assetService.getAssetUrl('assets/logo.png')" arrowLazyImage>
          <span style="text-align: left"> 
            <b>{{ isSelectedSite ? 'Selected' : region === regionEnum.TX ? 'Trade & Export Trucks' : 'Used Truck Centres' }}</b>
            <span class="VolvoLogo-logo-ukSpan">
              {{ isSelectedSite ? 'Used Trucks' : (region === regionEnum.UK ? 'United Kingdom' : (region === regionEnum.NS ? 'North & Scotland' : 'UK & Worldwide')) }}
            </span>
          </span>
          
      </div>

      <img [src]="assetService.getAssetUrl('assets/pages/popups/Close.svg')" alt="Close" (click)="onClose()" class="closeImg"/>
  </div>

  <div class="Share-items">
      <div class="Share-item">
          <a href="mailto:?subject=You might be interested in this truck&body={{data.truckUrl}}" mat-button (click)="shareCount('Email')">
              <img [src]="assetService.getAssetUrl('assets/pages/popups/Mail.svg')" alt="Mail" class="mailImage"/>
          </a>
          <span class="Share-itemName">Email</span>
      </div>
      <div class="Share-item">
          <button (click)="shareFaceBook();shareCount('Facebook');" mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/Facebook.svg')" alt="Facebook" class="shareImage"/>
          </button>
          <span class="Share-itemName">Facebook</span>
      </div>
      <div class="Share-item">
          <button (click)="shareTwitter();shareCount('Twitter');" mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/Twitter.svg')" alt="Twitter" class="shareImage"/>
          </button>
          <span class="Share-itemName">Twitter</span>
      </div>
      <div class="Share-item">
          <button (click)="shareWhatsApp();shareCount('WhatsApp');" mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/WhatsApp.svg')" alt="WhatsApp" class="shareImage"/>
          </button>
          <span class="Share-itemName">WhatsApp</span>
      </div>
      <div class="Share-item">
          <button (click)="shareLinkedIn()" mat-button>
              <img [src]="assetService.getAssetUrl('assets/pages/popups/LinkedIn.svg')" alt="LinkedIn" class="shareImage"/>
          </button>
          <span class="Share-itemName">LinkedIn</span>
      </div>
  </div>
  <div (click)="copyToClipBoard()"  class="Share-itemName ff-medium addMargin">{{ 'popups/share/copyTruckUrl' | lowercase | translate }}</div>
</div> 
