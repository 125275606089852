
<volvo-contact-skeleton *ngIf="isLoading"></volvo-contact-skeleton>

<section class="truckCentre">
    <div class="truckCentre">
        <img [hidden]="isLoading" (load)="onImageLoad()" (error)="onError()" [ngClass]="region===regionEnum.TX?'tx':''" [src]="getImageSrc()" class="truckCentre-centreImg">
    </div>
</section>
<div [hidden]="isLoading">
    <section class="banburryCentre" id="tradeexport">
        <div class="banburryCentre-title">
            <h1>
                {{region===regionEnum.UK?("uk/pages/contact/banburyTruckCentre" | lowercase | translate) :region===regionEnum.NS?("uk/pages/contact/edinburghCentre" | lowercase | translate):("uk/pages/tradeexport/title1/text2" | lowercase | translate) }}
            </h1>
        </div>
        <div class="banburryCentre-text" [innerHTML]="region===regionEnum.UK?('uk/pages/contact/banburyText' | lowercase | translate):(region===regionEnum.NS)?('uk/pages/contact/edinburghCentreText' | lowercase | translate):('pages/contact/tradeandexportText'| lowercase | translate)">
        </div>
    </section>
    <section class="banburryCentre">
        <div class="banburryCentre-flex">
            <div *ngIf="region===regionEnum.TX">
                <div class="banburryCentre-salesTeam">
                    <div class="banburryCentre-text txteam">{{"pages/contact/tradeandexportteam2"| lowercase | translate}}</div>
                    <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
                </div>
                <div class="banburryCentre-salesTeam">
                    <div class="banburryCentre-text txteam">{{"pages/contact/tradeandexportteam"| lowercase | translate}}</div>
                    <volvo-sales-person [salesTeamData]="salesTeamMangementDetailsTX" class="marginTop"></volvo-sales-person>
                </div>
            </div>
            <div *ngIf="region!==regionEnum.TX">
                <div class="banburryCentre-salesTeam">
                    <volvo-sales-person [salesTeamData]="salesTeamMangementDetails"></volvo-sales-person>
                </div>
            </div>
            <div class="banburryCentre-map">
                <volvo-map-centre [centre]="mapCentre"></volvo-map-centre>
            </div>
        </div>
    </section>
</div>
<volvo-footer *ngIf="this.region!==regionEnum.TX"></volvo-footer>
