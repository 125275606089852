import { Component, OnInit } from '@angular/core';
import { HtmlRoutePages } from '../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalUtils } from '../../../core/utils/global-utils/global-utils';
import { SearchService } from '../../../core/store/search/search.service';
import { SearchStateFilter } from '../../../core/store/search/search.model';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { Subscription } from 'rxjs';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
@Component({
  selector: 'volvo-stock-link-page',
  templateUrl: './stock-link-page.component.html',
  styleUrls: ['./stock-link-page.component.scss']
})
export class StockLinkPageComponent implements OnInit {
  showWarrantyText: boolean = true;
  showSelectedText: boolean = true;
  showEconomyText: boolean = true;
  showText:boolean=true;
  rightImage:string='';
  mobileFlag:boolean = false;
  search: SearchStateFilter;
  private s1: Subscription;
  constructor(private route: ActivatedRoute, private router: Router,private globalUtils: GlobalUtils, private deviceService: DeviceDetectorService,private searchService: SearchService,private localizeRouterService: LocalizeRouterService) {
    this.initSubscriptions();
  }
  links = [
    { label: 'uk/pages/search/remainingWarranty', path: HtmlRoutePages.search, text:'uk/pages/search/remainingWarrantyText', image:'', offerType:'Remaining Warranty' },
    { label: 'uk/pages/search/selected', path: HtmlRoutePages.search, text:'uk/pages/search/selectedText', image:'/assets/offer-24-icon.webp', offerType:'Selected' },
    { label: 'uk/pages/search/approved', path: HtmlRoutePages.search, text:'uk/pages/search/approvedText', image:'/assets/offer-12-icon.webp', offerType:'Approved' },
    { label: 'uk/pages/search/economy', path: HtmlRoutePages.search, text:'uk/pages/search/economyText', image:'/assets/offer-6-icon.webp', offerType:'Economy' }
  ];
  routePage(link): void {
    localStorage.setItem('searchParam', '');
    // this.search.steps.offerAndWarranty.isSelected = true;
    // this.search.steps.offerAndWarranty.value = link.offerType;
    // this.search.steps.offerAndWarranty.isExpanded = true;
    // this.search.requestingFilter = "offerAndWarranty";
    // this.searchService.updateSearch(this.search);
    
    localStorage.setItem('offerType', link.offerType);
    this.router.navigate([this.localizeRouterService.translateRoute(link.path)], { relativeTo: this.route });
  }
  ngOnInit(): void {
    if (this.globalUtils.isBrowser() && (this.deviceService.isMobile() || this.deviceService.isTablet())) {
      this.mobileFlag = true;
    }
  }
  // showHideText(label: string) {
  //   switch (label) {
  //     case 'uk/pages/search/remainingWarranty':
  //       this.showWarrantyText = true;
  //       this.showSelectedText = false;
  //       this.showEconomyText = false;
  //       break;
  //     case 'uk/pages/search/approved':
  //       this.showWarrantyText = false;
  //       this.showSelectedText = true;
  //       this.showEconomyText = false;
  //       break;
  //     case 'uk/pages/search/economy':
  //       this.showWarrantyText = false;
  //       this.showSelectedText = false;
  //       this.showEconomyText = true;
  //       break;
  //   }
  // }
  private initSubscriptions(): void {
    this.s1 = this.searchService.filter$
      .subscribe((searchOptions: SearchStateFilter) => {
        this.search = cloneDeep(searchOptions);
      });
  }
}
