import { Component, OnInit } from '@angular/core';
import { CentreNavLinks } from '../contact-page.model';
import { HtmlRoutePages } from '../../../app-routing.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { AssetService } from '~/core/services/assets/asset.service';

@Component({
  selector: 'volvo-centre-navlink',
  templateUrl: './centre-navlink.component.html',
  styleUrls: ['./centre-navlink.component.scss']
})
export class CentreNavlinkComponent implements OnInit {
  region: string;
  regionEnum = regionEnum;
  truckCentrelinks: CentreNavLinks[] = [
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre1',
      path: HtmlRoutePages.centreBanbury,

    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre2',
      path: HtmlRoutePages.centreBardon,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre3',
      path: HtmlRoutePages.centreEly,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre4',
      path: HtmlRoutePages.centreEnfield,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre5',
      path: HtmlRoutePages.centreFelixStowe,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre6',
      path: HtmlRoutePages.centreHayes,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre7',
      path: HtmlRoutePages.centreReading,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre8',
      path: HtmlRoutePages.centreWellingBorough,
    },
  ];

  mobileCentrelinks: CentreNavLinks[] = [
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre1',
      path: HtmlRoutePages.ourCentreBanbury,

    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre2',
      path: HtmlRoutePages.ourCentreBardon,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre3',
      path: HtmlRoutePages.ourCentreEly,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre4',
      path: HtmlRoutePages.ourCentreEnfield,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre5',
      path: HtmlRoutePages.ourCentreFelixStowe,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre6',
      path: HtmlRoutePages.ourCentreHayes,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre7',
      path: HtmlRoutePages.ourCentreReading,
    },
    {
      label: 'uk/pages/contact/UserTruckCentre',
      name: 'uk/pages/contact/UserTruckCentre8',
      path: HtmlRoutePages.ourCentreWellingBorough,
    },
  ];

  constructor(private route: ActivatedRoute, 
    private router: Router, 
    private regionSettingService: RegionalSettingsService,
    private localizeRouterService: LocalizeRouterService,
    private breadCrumbService: BreadcrumbserviceService,
    public assetService: AssetService) { }

  ngOnInit(): void {
    this.regionSettingService.regionalSettings$.subscribe((res:any) => {
      this.region = res.regionsSettings[0].region;
    });
    if (this.region === regionEnum.NS) {
      this.truckCentrelinks = [
        {
          label: 'uk/pages/contact/UserTruckCentre',
          name: 'uk/pages/contact/UserTruckCentre9',
          path: HtmlRoutePages.centreEdinburgh,

        },
        {
          label: 'uk/pages/contact/UserTruckCentre',
          name: 'uk/pages/contact/UserTruckCentre10',
          path: HtmlRoutePages.centreGlasgowWest,
        },
        {
          label: 'uk/pages/contact/UserTruckCentre',
          name: 'uk/pages/contact/UserTruckCentre11',
          path: HtmlRoutePages.centreWashington,
        }
      ];
      this.mobileCentrelinks=[
        {
          label: 'uk/pages/contact/UserTruckCentre',
          name: 'uk/pages/contact/UserTruckCentre9',
          path: HtmlRoutePages.ourCentreEdinburgh,
    
        },
        {
          label: 'uk/pages/contact/UserTruckCentre',
          name: 'uk/pages/contact/UserTruckCentre10',
          path: HtmlRoutePages.ourCentreGlasgowWest,
        },
        {
          label: 'uk/pages/contact/UserTruckCentre',
          name: 'uk/pages/contact/UserTruckCentre11',
          path: HtmlRoutePages.ourCentreWashington,
        }
      ];
  }
  //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Our Used Truck Centre', HtmlRoutePages.contact);
}
routePage(link): void {
  this.router.navigate([this.localizeRouterService.translateRoute(link.path)], { relativeTo: this.route });
}

}
