import { UntypedFormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { Component, OnInit, ViewChild, SecurityContext } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { LocalesStateLang, LocalesStateLangList } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { MailApiService } from '~/core/api/mail-api/mail-api.service';
import { MatStepper } from '@angular/material/stepper';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';
import { HtmlRoutePages } from '~/app-routing.model';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SearchState } from '~/core/store/search/search.state';
import { LocalesStateCountryList } from '../../../core/store/locales/locales.model';
import { countries } from '../../../core/constants/country';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { AssetService } from '~/core/services/assets/asset.service';
@Component({
    selector: 'volvo-financing-page',
    templateUrl: './financing-page.component.html',
    styleUrls: ['./financing-page.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true }
        }
    ]
})
export class FinancingPageComponent implements OnInit {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

    public sendStatus = false;
    private s0: Subscription;
    region: string;
    curLang: LocalesStateLangList;
    checkbox: string;
    reCaptchaRes: string;
    showRecaptcha: boolean = false;
    recaptchaToken: string;
    autoSelectCountry: LocalesStateCountryList;
    formOne = this.fb.group({
        companyName: ['', [Validators.required]],
        address: ['', [Validators.required]],
        country: ['', [Validators.required]]
    });

    noSpecialCharRegEx = /^[\p{L}\- \.\?!'\r\n,0-9\(\)]+$/u;

    formTwo = this.fb.group({
        fullName: ['', [Validators.required]],
        phone: ['', [Validators.pattern(/\d/g), Validators.required]],
        email: ['', [Validators.email, Validators.required]],
        message: ['', [Validators.pattern(this.noSpecialCharRegEx)]],
        privacy: [false, Validators.pattern('true')],
        token: ['']
    });

    dealershipCountries = countries;

    constructor(
        private fb: UntypedFormBuilder,
        private http: HttpClient,
        private mailApiService: MailApiService,
        private sanitizer: DomSanitizer,
        public localesService: LocalesService,
        private titleService: Title,
        private metaTagService: Meta,
        private translateService: TranslateService,
        private router: Router,
        private searchState: SearchState,
        private breadCrumbService: BreadcrumbserviceService,
        public assetService: AssetService
    ) {
        this.autoSelectCountry = this.searchState.getCurrentCountry();

        this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
            this.curLang = lang.curLang;
            this.checkbox = '/' + this.curLang.id + '/privacy-policy';
        });
        this.recaptchaToken = environment.recaptchaSitekey;
        this.initSubscriptions();
    }

    ngOnInit(): void {
        this.router.events.subscribe(() => {
            this.titleService.setTitle(this.translateService.instant('financing/metaTitle'.toLowerCase()));
            this.metaTagService.updateTag({
                name: 'og:description',
                content: this.translateService.instant('financing/metaDescription'.toLowerCase())
            });
            this.metaTagService.updateTag({
                name: 'og:image',
                alt: 'alt',
                content: this.translateService.instant('financing/altImage'.toLowerCase())
            });
        });
        this.titleService.setTitle(this.translateService.instant('financing/metaTitle'.toLowerCase()));
        this.metaTagService.updateTag({
            name: 'og:description',
            content: this.translateService.instant('financing/metaDescription'.toLowerCase())
        });
        this.metaTagService.updateTag({
            name: 'og:image',
            alt: 'alt',
            content: this.translateService.instant('financing/altImage'.toLowerCase())
        });
        // this.dealershipCountries = [
        //   { label: 'Österreich', id: 'at' },
        //   { label: 'Belarus', id: 'blr' },
        //   { label: 'België', id: 'be-nl' },
        //   { label: 'Belgique', id: 'be' },
        //   { label: 'Bulgariya', id: 'bg' },
        //   { label: 'Hrvatska', id: 'hr' },
        //   { label: 'Česká republika', id: 'cz' },
        //   { label: 'Danmark', id: 'dk' },
        //   { label: 'Eesti', id: 'ee' },
        //   { label: 'Suomi', id: 'fi' },
        //   { label: 'France', id: 'fr' },
        //   { label: 'Deutschland', id: 'de' },
        //   { label: 'Magyarország', id: 'hu' },
        //   { label: 'Italia', id: 'it' },
        //   { label: 'Latvija', id: 'lv' },
        //   { label: 'Lietuva', id: 'lt' },
        //   { label: 'Makedonija', id: 'mk' },
        //   { label: 'Montenegro', id: 'cs' },
        //   { label: 'Nederland', id: 'nl' },
        //   { label: 'Norge', id: 'no' },
        //   { label: 'Polska', id: 'pl' },
        //   { label: 'România', id: 'ro' },
        //   { label: 'Russia', id: 'ru' },
        //   { label: 'Србија', id: 'cs' },
        //   { label: 'Slovensko', id: 'sk' },
        //   { label: 'Slovenija', id: 'si' },
        //   { label: 'South Africa', id: 'za' },
        //   { label: 'España', id: 'es' },
        //   { label: 'Sweden', id: 'se' },
        //   { label: 'Schweiz', id: 'ch' },
        //   { label: 'Türkiye', id: 'tr' }
        // ];
        //this.breadCrumbService.updateBreadcrumb('Services' , 'Financing', HtmlRoutePages.services);
    }

    resolved(captchaResponse: string, res) {
        this.reCaptchaRes = captchaResponse;
    }
    recaptcha() {
        this.showRecaptcha = !this.showRecaptcha;
    }

    async onSubmit(): Promise<void> {
        this.sendStatus = true;
        if (this.formOne.valid && this.formTwo.valid && this.reCaptchaRes) {
            const data = { ...this.formOne.value, ...this.formTwo.value };
            const body = this.transformDataForServer(data);
            const status = await this.mailApiService.postMailFinanceContact(body);
            this.sendStatus = false;

            
            if (status) {
                this.stepper.reset();
                this.formDirective.resetForm();
                this.showRecaptcha = false;
                this.reCaptchaRes = '';
            }

            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({
                event: 'FormsubmittedFinancing'
            });
        }
    }

    private transformDataForServer(data) {
        // TODO: ask someone to make nice API for this
        return {
            salesEmail: "", salesPersonName: "",
            dealershipcountry: data.country,
            name: this.sanitizer.sanitize(SecurityContext.HTML, data.fullName).trim(),
            email: data.email,
            phone: data.phone,
            message: this.sanitizer.sanitize(SecurityContext.HTML, data.message).trim(),
            token: this.reCaptchaRes,
            Address: data.address,
            CompanyName: data.companyName,
            privacy: data.privacy,
            region: this.region,
            site: "volvoselected"
        };
    }

    initSubscriptions() {
        this.s0 = this.localesService.region$.subscribe(results => {
            this.region = results.curRegion.id;
        })
    }
}
