import { Component, OnInit } from '@angular/core';
import { SalesTeam } from '../contact-page.model';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router,ActivatedRoute } from '@angular/router';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
@Component({
  selector: 'volvo-centre-ely',
  templateUrl: './centre-ely.component.html',
  styleUrls: ['../centre-banbury/centre-banbury.component.scss']
})
export class CentreElyComponent implements OnInit {
  mapCentre:string = 'Volvo Truck and Bus Centre Cambridge';
  regionEnum=regionEnum;
  region: string;
  public isLoading = true
  constructor(private router: Router,private titleService: Title,
    private translateService: TranslateService,
    private metaTagService: Meta,
    private regionSettingService:RegionalSettingsService,
    private activatedRoute:ActivatedRoute,
    private breadCrumbService: BreadcrumbserviceService,) { }

    onImageLoad() {
        this.isLoading = false;
    }
    onError(): void {
        console.error('Image failed to load.');
        this.isLoading = false; // You may want to handle this differently
    }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/contactus/elymetatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/contactus/elymetatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/contactus/elymetadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/contactus/elyaltImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/contactus/elymetatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/contactus/elymetatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/contactus/elymetadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/contactus/elyaltImage'.toLowerCase())
    });
    this.regionSettingService.regionalSettings$.subscribe((res:any) => {
      this.region = res.regionsSettings[0].region;
    });
    if(this.region===regionEnum.NS)
    {
      this.mapCentre='Volvo Truck and Bus Centre Washington';
      this.salesTeamMangementDetails = [{
        imageUrl: '/assets/pages/contactus/ns/Barry.webp',
        imageName: 'Barry Knight',
        imageDesignation: 'uk/pages/contact/salesExecutive6',
        imageCentre: '',
        imageEmail: 'barry.knight@volvo.com',
        imageMobile: '07843 803973',
      }]

      //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Washington Used Truck Centre', HtmlRoutePages.contact);
    }
    else{
      //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Cambridge Used Truck Centre', HtmlRoutePages.contact);
    }
  }
  salesTeamMangementDetails: SalesTeam[] = [
    {
      imageUrl: '/assets/pages/contactus/Karl Love.webp',
      imageName: 'Karl Love',
      imageDesignation: 'uk/pages/contact/salesExecutive1',
      imageCentre: 'uk/pages/contact/salesCentre1',
      imageEmail: 'karl.love@volvo.com',
      imageMobile: '07808 884583',
    },
    ]

}
