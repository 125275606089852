import { Component, OnInit } from '@angular/core';
import { SalesTeam } from '../contact-page.model';
import { Title,Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router,ActivatedRoute } from '@angular/router';
import { RegionalSettingsService } from '../../../core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '../../../core/declaration/declaration';
import {BreadcrumbserviceService} from '~/pages/breadcrumb/breadcrumbservice.service';
import { HtmlRoutePages } from '../../../app-routing.model';
@Component({
  selector: 'volvo-centre-banbury',
  templateUrl: './centre-banbury.component.html',
  styleUrls: ['./centre-banbury.component.scss']
})
export class CentreBanburyComponent implements OnInit {
  mapCentre:string = 'Volvo Truck and Bus Centre South & East Banbury';
  regionEnum=regionEnum;
  region:string;
    salesTeamMangementDetailsTX = [{}];
    public isLoading = true
  constructor(private router: Router,private titleService: Title,
    private translateService: TranslateService,
    private metaTagService: Meta,
    private regionSettingService:RegionalSettingsService,
    private activatedRoute:ActivatedRoute,
    private breadCrumbService: BreadcrumbserviceService,) { }

    onImageLoad() {        
        this.isLoading = false;
    }
    onError(): void {
        console.error('Image failed to load.');
        this.isLoading = false; // You may want to handle this differently
    }
    getImageSrc(): string {        
        switch (this.region) {
            case this.regionEnum.UK:
                return '../../../assets/pages/contactus/Banbury.webp';
            case this.regionEnum.NS:
                return '../../../assets/pages/contactus/ns/edinburgh.webp';
            default:
                return '../../../assets/pages/contactus/TE/contactus.webp';
        }
    }
    ngOnInit(): void {
        this.isLoading = true;
    this.router.events.subscribe(() => {
      this.titleService.setTitle(this.translateService.instant('uk/contactus/banburymetatitle'.toLowerCase()));
      this.metaTagService.updateTag({
        name: 'og:title',
        content: this.translateService.instant('uk/contactus/banburymetatitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('uk/contactus/banburymetadescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'alt',
        content: this.translateService.instant('uk/contactus/banburyaltImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('uk/contactus/banburymetatitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:title',
      content: this.translateService.instant('uk/contactus/banburymetatitle'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('uk/contactus/banburymetadescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'alt',
      content: this.translateService.instant('uk/contactus/banburyaltImage'.toLowerCase())
    });
    this.regionSettingService.regionalSettings$.subscribe((res:any) => {
      this.region = res.regionsSettings[0].region;
    });
    if(this.region===regionEnum.NS)
    {
      this.mapCentre='Volvo Truck and Bus Centre Edinburgh';
      this.salesTeamMangementDetails = [
        {
          imageUrl: '/assets/pages/contactus/ns/Ian.webp',
          imageName: 'Ian Laverie',
          imageDesignation: 'uk/pages/contact/salesExecutive7',
          imageCentre: '',
          imageEmail: 'ian.laverie@volvo.com',
          imageMobile: '07845 124126',
        }
        ];
        //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Edinburgh Used Truck Centre', HtmlRoutePages.contact);
    }
    else if(this.region===regionEnum.TX)
    {
      this.mapCentre='Trade & Export Trucks';
      this.salesTeamMangementDetails = [
        {
          imageUrl: '/assets/pages/contactus/TE/PaulFleet.webp',
          imageName: 'Paul Fleet',
          imageDesignation: 'uk/pages/tradeexport/title1/text1',
          imageCentre: '',
          imageEmail: 'paul.fleet@volvo.com',
          imageMobile: '+44 (0)7989 351886',
          imageLinkedin:'https://www.linkedin.com/in/paul-fleet-733662219/'
        }
        ];
        this.salesTeamMangementDetailsTX =[{
          imageUrl: '/assets/pages/contactus/TE/CC.webp',
          imageName: 'Chris Cann',
          imageDesignation: 'pages/contact/salesexecutive8',
          imageCentre: '',
          imageEmail: 'chris.cann@volvo.com ',
          imageMobile: '',
          imageLinkedin:'https://www.linkedin.com/in/chris-cann-73b00165/'
        }];
    }
    else{
      //this.breadCrumbService.updateBreadcrumb('Contact Us' , 'Banbury Used Truck Centre', HtmlRoutePages.contact);
    }

  }
  salesTeamMangementDetails: SalesTeam[] = [
  {
    imageUrl: '/assets/pages/contactus/Kevin Bassindale.webp',
    imageName: 'Kevin Bassindale',
    imageDesignation: 'uk/pages/contact/salesExecutive1',
    imageCentre: 'uk/pages/contact/salesCentre2',
    imageEmail: 'kevin.bassindale@volvo.com',
    imageMobile: '07715 798422',
  },
  ]
  
}
