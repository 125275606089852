import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { PostMailBody, PostMailRes, PostSubscribeBody, PostSubscribeRes, PostMailBodyMobile, PostMailOutOfStock, PostMailContact } from '~/core/api/mail-api/mail-api.model';
import { LocalesStateCountry, LocalesStateCountryList, LocalesStateLang, LocalesStateLangList } from '~/core/store/locales/locales.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class MailApiService {
    private curCountry: LocalesStateCountryList;
    private curLang: LocalesStateLangList;

    private mailPathPhone = 'mail/:langId/truckMailContact/';
    private vfsContactPath = 'mail/:langId/vfsMailContact/';
    private mailPathMail = 'mail/:langId/truckMailContact/';
    private makeTruckOffer = 'mail/:langId/truckpage-make-offer/';
    private financeContact = 'mail/:langId/financeContact/';
    private mailOutOfStock = 'mail/:langId/searchpage-moretrucks-contact/';
    private mailContact = 'mail/:langId/genericContact/';
    private mailPathUKMail = 'mail/:langId/ukTruckMailContact/';
    // old?
    private subscribePath = 'mail/:langId/newsletter-mail-confirmation/';
    private sellYourTruckPath = 'mail/:langId/sell-your-trucks';
    private mailConfirmationPath = 'mail/:langId/newsletter-confirmation';



    constructor(
        private http: HttpClient,

        public localesService: LocalesService
    ) {
        this.localesService.country$
            .subscribe((country: LocalesStateCountry) => {
                this.curCountry = country.curCountry;
            });
            this.localesService.lang$
            .subscribe((country: LocalesStateLang) => {
                this.curLang = country.curLang;
            });
    }

    async postSubscribe(body: PostSubscribeBody): Promise<PostSubscribeRes> {
        return await lastValueFrom(this.http.post(this.countryUrl(this.subscribePath), body)) as any;
    }

    async postMail(body: PostMailBody): Promise<PostMailRes> {
        
        return await lastValueFrom(this.http.post(this.countryUrl(this.mailPathMail), body)) as any;
    }
    async postMailEmail(body: PostMailBody): Promise<PostMailRes> {
        return await lastValueFrom(this.http.post(this.langUrl(this.mailPathMail), body)) as any;
    }
    async postMailUKEmail(body: PostMailBody): Promise<PostMailRes> {
        return await lastValueFrom(this.http.post(this.countryUrl(this.mailPathUKMail), body)) as any;
    }
    async postMailVfs(body: PostMailBody): Promise<PostMailRes> {
        return await lastValueFrom(this.http.post(this.countryUrl(this.vfsContactPath), body)) as any;
    }
    async postMailMobile(body: PostMailBodyMobile): Promise<PostMailRes> {

        return await lastValueFrom(this.http.post(this.countryUrl(this.mailPathPhone), body)) as any;
    }
    async postMailMakeOffer(body: PostMailBody): Promise<PostMailRes> {

        return await lastValueFrom(this.http.post(this.countryUrl(this.makeTruckOffer), body)) as any;
    }
    async postMailFinanceContact(body: PostMailBody): Promise<PostMailRes> {

        return await lastValueFrom(this.http.post(this.countryUrl(this.financeContact), body)) as any;
    }
    async postMailOutOfStock(body: PostMailOutOfStock): Promise<PostMailRes> {

        return await lastValueFrom(this.http.post(this.countryUrl(this.mailOutOfStock), body)) as any;
    }
    async postMailContact(body: PostMailContact): Promise<PostMailRes> {

        return await lastValueFrom(this.http.post(this.countryUrl(this.mailContact), body)) as any;
    }

    async postSellYourTruck(body: any): Promise<PostMailRes> {
        return await lastValueFrom(this.http.post(this.countryUrl(this.sellYourTruckPath), body)) as any;
    }

    async postMailConfirmation(body: any): Promise<PostMailRes> {
        return await lastValueFrom(this.http.post(this.countryUrl(this.mailConfirmationPath), body)) as any;
    }

    private countryUrl(path: string): string {
        //Passing country id instead of language id as regional approach is introduced.
        let countryId = this.curCountry.id;
        if (!countryId) countryId = "en";
        const pathWithCountry = path.replace(':langId', countryId);
        return `${environment.apiUrl}${pathWithCountry}`;
    }

    private langUrl(path: string): string {
        let langId = this.curLang.id;

        if (!langId) langId = "en";
        const pathWithLang = path.replace(':langId', langId);
        return `${environment.apiUrl}${pathWithLang}`;
    }
}

