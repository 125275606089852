import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LocalesService } from '~/core/store/locales/locales.service';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { regionEnum } from '~/core/declaration/declaration';
import { HtmlRoutePages } from '~/app-routing.model';
import { CentreNavLinks } from '../contact-page/contact-page.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AssetService } from '~/core/services/assets/asset.service';

@AutoUnsubscribe()
@Component({
  selector: 'volvo-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit, OnDestroy {
  isTablet: boolean = false;
  region:string;
  imageUrl:string;
  regionEnum=regionEnum;
  site:string;
  isSelectedSite: boolean;
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private translateService: TranslateService,
    private router: Router,
    private store: Store,
    private regionSettingService:RegionalSettingsService,
    private route: ActivatedRoute,
    private localizeRouterService: LocalizeRouterService,
    public assetService: AssetService
  ) {
    this.regionSettingService.regionalSettings$.subscribe(r => {
      this.region = r.regionsSettings[0].region;
      this.site = r.regionsSettings[0].site;
      if(this.site === 'volvoselected')
        this.isSelectedSite = true;
      else
        this.isSelectedSite = false;
    });
  }

  ngOnInit(): void {
      if (ScreenUtils.isTablet()) this.isTablet = true;
      this.router.events.subscribe(() => {
          this.titleService.setTitle(this.translateService.instant('aboutus/metaTitle'.toLowerCase()));
          this.metaTagService.updateTag({
              name: 'og:description',
              content: this.translateService.instant('aboutus/metaDescription'.toLowerCase())
          });
          this.metaTagService.updateTag({
              name: 'og:image',
              alt: 'alt',
              content: this.translateService.instant('aboutus/altImage'.toLowerCase())
          });
          // Additional traditional meta tags for search engines
          this.metaTagService.updateTag({ name: 'description', content: this.translateService.instant('aboutus/metaDescription'.toLowerCase()) });
          this.metaTagService.updateTag({ name: 'keywords', content: this.translateService.instant('aboutus/metakeywords'.toLowerCase()) });
      });
      this.titleService.setTitle(this.translateService.instant('aboutus/metaTitle'.toLowerCase()));
      this.metaTagService.updateTag({
          name: 'og:description',
          content: this.translateService.instant('aboutus/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
          name: 'og:image',
          alt: 'alt',
          content: this.translateService.instant('aboutus/altImage'.toLowerCase())
      });
      // Additional traditional meta tags for search engines
      this.metaTagService.updateTag({ name: 'description', content: this.translateService.instant('aboutus/metaDescription'.toLowerCase()) });
      this.metaTagService.updateTag({ name: 'keywords', content: this.translateService.instant('aboutus/metakeywords'.toLowerCase()) });
    if(this.isSelectedSite){
      //do nothing
    }
    else{
      switch(this.region)
      {
        case this.regionEnum.UK:this.imageUrl =  "assets/pages/aboutUs/About us image.webp";;break;
        case this.regionEnum.NS:this.imageUrl = "assets/pages/aboutUs/About us image NS.webp";;break;
        case this.regionEnum.TX:this.imageUrl = "assets/pages/aboutUs/AboutusimageTX.webp";
        this.navlinks=[{
          label: 'uk/pages/readMore',
          name: 'uk/pages/aboutUs/whoweare',
          path: HtmlRoutePages.whoweare,
    
        }];break;
      }
    }
  }

  ngOnDestroy(): void { }

  routePage(link): void {
    this.router.navigate([this.localizeRouterService.translateRoute(link.path)], { relativeTo: this.route });
  }

  navlinks: CentreNavLinks[] = [
    {
        label: 'uk/pages/readMore',
        name: 'uk/pages/aboutUs/whoweare',
        path: HtmlRoutePages.whoweare,
  
    },
    // {
    //   label: 'uk/pages/readMore',
    //   name: 'uk/pages/aboutUs/whatwehave',
    //   path: HtmlRoutePages.ourCentres,
    // },
    {
      label: 'uk/pages/readMore',
      name: 'uk/pages/aboutUs/whatcustomersay',
      path: HtmlRoutePages.whatcustomersay,
    },
  ];
}
