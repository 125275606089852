<section class="truckModel">
    <div class="truckModel-div">
        <div class="truckModel-div-wrapper">
            <ng-container *ngFor="let list of truckmodelObject; let i = index">
                
                <div [ngClass]="(i % 2) === 0 ? 'truckModel-div-wrapper-paddingRight' : 'truckModel-div-wrapper-paddingleft'">
                    <volvo-truck-model-home-skeleton *ngIf="isLoading[i]"></volvo-truck-model-home-skeleton>
                    
                    <div [hidden]="isLoading[i]">
                        <img [src]="assetService.getAssetUrl(list.url)" class="volvo-imgDiv-volvoImg" [routerLink]="list.path | localize" *ngIf="i !== 4 && i !== 5" (load)="onImageLoad(i)" (click)="logTruckModelButtonClick(list.name)" />
                        <a [href]="list.path" *ngIf="i === 4 || i === 5" target="_blank">
                            <img [src]="assetService.getAssetUrl(list.url)" class="volvo-imgDiv-volvoImg" (load)="onImageLoad(i)" (click)="logTruckModelButtonClick(list.name)" />
                        </a>
                        <div class="truckModel-div-wrapper-title">
                            <span>{{ list.title | lowercase | translate }}</span>
                        </div>
                        <div class="truckModel-div-wrapper-text" [ngClass]="(i === 4 || i === 5) ? 'truckModel-div-wrapper-text-height' : ''">
                            <span>{{ list.text | lowercase | translate }}</span>
                        </div>
                        <div class="truckModel-div-wrapper-buttonDiv">
                            <a [routerLink]="list.path | localize" class="truckModel-div-wrapper-button" *ngIf="i !== 4 && i !== 5" (click)="logTruckModelButtonClick(list.name)">
                                <div style="display: inline-block;">
                                    <span class="truckModel-div-wrapper-button-text" *ngIf="!isTablet">{{ 'pages/truckModel/readmore' | lowercase | translate }}</span>
                                    <span class="truckModel-div-wrapper-button-text" *ngIf="isTablet">{{ list.title | lowercase | translate }}</span>
                                    <i class="fal fa-chevron-right"></i>
                                </div>
                            </a>
                            <a [href]="list.path" class="truckModel-div-wrapper-button" *ngIf="i === 4 || i === 5" target="_blank" (click)="logTruckModelButtonClick(list.name)">
                                <div style="display: inline-block;">
                                    <span class="truckModel-div-wrapper-button-text" *ngIf="!isTablet">{{ 'pages/truckModel/readmore' | lowercase | translate }}</span>
                                    <span class="truckModel-div-wrapper-button-text" *ngIf="isTablet">{{ list.title | lowercase | translate }}</span>
                                    <i class="fal fa-chevron-right"></i>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</section>
<volvo-footer></volvo-footer>
