import { Component, OnDestroy, OnInit } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import * as cloneDeep from 'lodash/fp/cloneDeep';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { SearchApiService } from '~/core/api/search-api/search-api.service';
import { BucketsService } from '~/core/store/buckets/buckets.service';
import { SearchStateFilter, SearchStateParams } from '~/core/store/search/search.model';
import { SearchService } from '~/core/store/search/search.service';
import { TrucksService } from '~/core/store/trucks/trucks.service';
import { ApiUtils } from '~/core/utils/api-utils/api-utils';
import { Truck } from '~/core/store/trucks/trucks.model';
import { HtmlRoutePages } from '~/app-routing.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { LocalesStateCountry, LocalesStateLang, LocalesStateLangList, LocalesStateRegion } from '~/core/store/locales/locales.model';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SearchQuery } from '~/core/models/search-query.model';
import { TranslationService } from '~/core/services/translations/translation-service';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CountrySettings, CountrySettingsStateInterface } from '~/core/store/regionalSettings/regionalSettings.model';
import { RegionalSettingsService } from '~/core/store/regionalSettings/regionalSettings.service';
import { CountrySettingsState } from '~/core/store/regionalSettings/regionalSettings.state';
import { regionEnum } from '../../core/declaration/declaration'
import { ToggleFeatureService } from '~/core/services/togglefeature/togglefeature.service';
import { defaultRegionList, langList } from '~/core/constants/constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppService } from '~/app.service';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AssetService } from '~/core/services/assets/asset.service';
@AutoUnsubscribe()
@Component({
  selector: 'volvo-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  HtmlRoutePages = HtmlRoutePages;
  directToContactForFinance: Boolean;
//linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("/assets/pages/home/NS/main.webp") center 40% no-repeat
  bgImageStyles = {
    UK: `url(${this.assetService.getAssetUrl('assets/pages/home/UK/main.webp')}) center 55% / cover no-repeat`,
    NS: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${this.assetService.getAssetUrl('assets/pages/home/NS/main.webp')}) center 40% / cover no-repeat`,
    TX: `url(${this.assetService.getAssetUrl('assets/pages/home/TX/main.webp')}) center 44% / cover no-repeat`,
    default: `url(${this.assetService.getAssetUrl('assets/pages/home/main.webp')}) center 44% / cover no-repeat`
  };
  
  search: SearchStateFilter;
  newInStockTrucks: Truck[];
  highlightedTrucks: Truck[];
  private curLang: LocalesStateLang;
  private searchQuery: SearchStateParams;
  private truckCountry: String;
  private currentCountry: String;
  isTablet: boolean;
  errorMessage;

  private s0: Subscription;
  private s1: Subscription;
  private s2: Subscription;
  private s3: Subscription;
  isImgLoaded = false;
  isMainImgLoaded = false;
  public region: string;
  private site: string;
  public volvoSelectedProEnabled: boolean;
  isSelectedSite: boolean = false;
  regionEnum = regionEnum;
  defaultRegionList = defaultRegionList;
  link:string="";
  isMobile: boolean = false;
  regionalSettings: RegionSettings;
  constructor(
    private trucksService: TrucksService,
    public localesService: LocalesService,
    public regionService: RegionalSettingsService,
    private searchService: SearchService,
    public translationService: TranslationService,
    private http: HttpClient,
    private router: Router,
    private metaTagService: Meta,
    private titleService: Title,
    private translateService: TranslateService,
    private featureToggleService: ToggleFeatureService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private appService: AppService,
    private localizeRouterService: LocalizeRouterService,
    public assetService: AssetService
  ) {
    //this.bgImage = this.assetService.getAssetUrl(this.bgImage);
    this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.directToContactForFinance = lang.curLang.id == 'ba';
    });

    if(this.deviceService.isMobile()){
      this.isMobile = true;
    }
    if (ScreenUtils.isTablet()) this.isTablet = true;
    
    this.regionalSettings = appService.getRegionalSettings();
    if(this.regionalSettings){
      this.region = this.regionalSettings?.region;
      this.site = this.regionalSettings?.site;
      if(this.regionalSettings?.site === "volvoselected")
        this.isSelectedSite = true;
    }

    this.router.events.subscribe(() => {
      const metaTitle = this.translateService.instant('home/metatitle'.toLowerCase());
      this.titleService.setTitle(metaTitle);
      this.metaTagService.updateTag({
        name: 'og:description',
        content: this.translateService.instant('home/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'og:image',
        alt: 'image description',
        content: this.translateService.instant('home/altImage'.toLowerCase())
      });
    });
    this.titleService.setTitle(this.translateService.instant('home/metaTitle'.toLowerCase()));
    this.metaTagService.updateTag({
      name: 'og:description',
      content: this.translateService.instant('home/metaDescription'.toLowerCase())
    });
    this.metaTagService.updateTag({
      name: 'og:image',
      alt: 'image description',
      content: this.translateService.instant('home/altImage'.toLowerCase())
    });
    this.initSubscriptions();

    // const homeNavLinks = [
    //   { label: 'home/sectionC/itemA/title',path: HtmlRoutePages.search, text:'globalMiss/bestChoice', image:'' },
    //   { label: 'home/sectionC/itemB/title', path: (this.directToContactForFinance ? HtmlRoutePages.contact : HtmlRoutePages.finance), text:'globalMiss/smarterInvestment', image:'' },
    //   { label: 'home/sectionC/itemC/title', path: HtmlRoutePages.search, text:'globalMiss/qualityAssured', image:'' },
    // ];
  }
  
  getBackgroundStyle(): { [key: string]: string } {
    const style = this.bgImageStyles[this.region] || this.bgImageStyles.default;
    return { 'background': style };
  }

  // onRegionChange(region) {
  //   var langObj = JSON.parse(localStorage.getItem('language'));
  //   langObj.region.curRegion = { label: region.value, id: region.value };

  //   var langCode = "en";
  //   var country = null;

  //   //langCode = region.value === "UK" ? "en-gb" : langCode === "TX" ? "en-tx" : langCode === "NS" ? "en-ns" : langCode;
  //   switch (region.value) {
  //     case "UK":
  //       langCode = "en-gb";
  //       break;
  //     case "TX":
  //       langCode = "en-tx";
  //       break;
  //     case "NS":
  //       langCode = "en-ns";
  //       break;
  //     case "ZA":{
  //       langCode = "en-za";
  //       country = langObj.country.list.find(country => country.id === "za");
  //       break;
  //     }
  //     default:
  //       langCode = "en";
  //       break;
  //   }

  //   langObj.lang.curLang = langObj.lang.list.find(langItem => langItem.id === langCode);
  //   if(country){
  //     langObj.country.curCountry = {label: null, id: null};
  //   }
  //   localStorage.setItem('language', JSON.stringify(langObj));
  //   window.location.reload();
  // }

  ngOnInit(): void {
    this.loadNewInStockAndHighlightedTrucks();
  }

  ngOnDestroy(): void {
    this.searchService.resetSearch();
  }

  onSearch(type: string): void {
    this.searchService.updateStep({
      key: 'majorCategory',
      value: {
        ...this.search.steps.majorCategory,
        isSelected: true,
        value: type,
      },
      expandingFilter: '',
      reloadFlag: false
    });
  }

  private loadNewInStockAndHighlightedTrucks(): void {
    this.loadBestTrucks();
    //this.searchService.getSearch(this.searchQuery);
  }

  private async loadBestTrucks(): Promise<void> {
    this.trucksService.getLatestTrucks();
  }

  private initSubscriptions(): void {
    this.s0 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
      this.search = cloneDeep(searchOptions);

      // TODO: find better solution for home page
      this.search.steps.majorCategory.value = '';
      this.search.steps.make.value = 'Volvo';
      this.search.steps.make.isSelected = true;
      this.search.steps.model.value = '';
    });

    this.errorMessage = '';
    this.localesService.country$.subscribe((lang) => {
      if (lang) {
        this.currentCountry = lang.curCountry.label;

        this.s3 = this.regionService.countrySettings$.subscribe((settings: CountrySettingsStateInterface) => {
          const currentCountryHasSettings = settings.countrysSettings.filter(c => c.country === lang.curCountry.id)[0] ? true : false;
          this.volvoSelectedProEnabled = currentCountryHasSettings ? settings.countrysSettings.filter(c => c.country === lang.curCountry.id)[0].selectedProEnabled : true;
        });

        const newestUrl = environment.apiUrl + 'Collections/newest?country=' + this.currentCountry + '&region=' + this.region + '&site=' + this.site;
        this.s1 = this.http.get(newestUrl).subscribe(
          (trucks: any) => {
            if (trucks) {
              this.newInStockTrucks = trucks.hits;
            }
          },
          (error) => {
            this.newInStockTrucks = [];
            this.errorMessage = error;
            throw error;
          }
        );

        this.s2 = this.http.get(environment.apiUrl + 'Collections/highlighted?country=' + this.currentCountry + '&region=' + this.region + '&site=' + this.site).subscribe(
          (trucks: any) => {

            this.highlightedTrucks = trucks.hits;
          },
          (error) => {

            this.highlightedTrucks = [];
            this.errorMessage = error;
            throw error;
          }
        );
      }
    });

  }

  private isImageLoaded(): void {
    this.isImgLoaded = true;
  }

  routePage(link): void {
    this.router.navigate([this.localizeRouterService.translateRoute(link)], { relativeTo: this.route });
  }
}
