import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, HostListener, Renderer2, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription, combineLatest, lastValueFrom } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { SuperstructureDetailsPopupComponent } from '~/popups/superstructure-details-popup/superstructure-details-popup.component';
import { LocalesStateLang, LocalesStateLangList } from '~/core/store/locales/locales.model';
import { TruckCollationService } from '~/core/store/truck-collation/truck-collation.service';
import { SharePopupComponent } from '~/popups/share-popup/share-popup.component';
import { CurrencyCode, Truck } from '~/core/store/trucks/trucks.model';
import { LocalesService } from '~/core/store/locales/locales.service';
import { GlobalUtils } from '~/core/utils/global-utils/global-utils';
import { ScreenUtils } from '~/core/utils/screen-utils/screen-utils';
import { SearchStateFilter, SearchStateParams } from '~/core/store/search/search.model';
import { SearchService } from '~/core/store/search/search.service';
import { TrucksService } from '~/core/store/trucks/trucks.service';
import { HtmlRoutePages } from '~/app-routing.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { SalesSliderComponent } from '../stock-page/sales-slider/sales-slider.component';
import { Breadcrumb, BreadcrumbService } from 'angular-crumbs-2';
import { PrintService } from '../../core/services/print/print.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment.dev';
import { TranslationService } from '../../core/services/translations/translation-service';
import { resolve } from 'dns';
import { CounterService } from '~/core/services/counter/counter-service';
import { RegionSettings } from '~/core/store/regionalSettings/regionalSettings.model';
import { Store } from '@ngxs/store';
import { AppState } from '~/core/store';
import { SoldPopupComponent } from '~/popups/sold-popup/sold-popup.component';
import { pairwise, take } from 'rxjs/operators';
import { NgDynamicBreadcrumbService } from 'ng-dynamic-breadcrumb';
import { BreadCrumbUrlService } from '../../core/services/breadcrumb/breadcrumbUrl.service'
import { regionEnum } from '../../core/declaration/declaration';
import { EmailUsPopupComponent } from 'src/app/popups/email-us-popup/email-us-popup.component';
import { AssetService } from '~/core/services/assets/asset.service';
export class SalesUser {
  name: string;
  info: string;
  adress: string;
  city: string;
  postalcode: string;
  phone: string;
  email: string;
  locationName: string;
  isExpanded = false;
}
export interface SalesUsers { }

@AutoUnsubscribe()
@Component({
  selector: 'volvo-truck-page',
  templateUrl: './truck-page.component.html',
  styleUrls: ['./truck-page.component.scss']
})
export class TruckPageComponent implements OnInit, OnDestroy, AfterViewInit {
  isPage = true;
  salesPersons = undefined;
  hasEmptyTyreTread: boolean;
  hasEmptyDimensions: boolean;
  haschassisNumber: boolean = false;
  hasTranslate: boolean = false;
  translatedText: string = '';
  showProgress: boolean = false;
  comments: any = [];
  langName: string;
  advertisementTitle: string = '';
  breadCrumbUrl: string;
  truckMakeModel: string;
  emailFlag: boolean = false;
  disableVFS: boolean = false;
  isShow: boolean = true;

  @ViewChild('salesSlider', { static: true }) salesSlider: SalesSliderComponent;
  screenWidth: number;

  @Input() set id(id: string) {
    this.isPage = false;
    this.truck = null;
    this.isNotFound = false;
    this.trucksService.getTruckById(id);

  }
  isPhone: boolean = false;
  HtmlRoutePages = HtmlRoutePages;
  CurrencyCode = CurrencyCode;

  sales: SalesUser[] = [];
  salesUsers: SalesUsers[] = [];
  selectedLangCode: string;
  faq = [
    { title: 'truck.faq.itemA.title', text: 'truck.faq.itemA.text' },
    { title: 'truck.faq.itemB.title', text: 'truck.faq.itemB.text' },
    { title: 'truck.faq.itemC.title', text: 'truck.faq.itemC.text' },
    { title: 'truck.faq.itemD.title', text: 'truck.faq.itemD.text' },
    { title: 'truck.faq.itemE.title', text: 'truck.faq.itemE.text' },
    { title: 'truck.faq.itemF.title', text: 'truck.faq.itemF.text' }
  ];

  langCodes = [
    {
      name: 'Bosnian',
      iso1: 'bs',
      iso3: 'bos'
    },
    {
      name: 'Bulgarian',
      iso1: 'bg',
      iso3: 'bul'
    },
    {
      name: 'Croatian',
      iso1: 'hr',
      iso3: 'hrv'
    },
    {
      name: 'Czech',
      iso1: 'cs',
      iso3: 'cze'
    },
    {
      name: 'Danish',
      iso1: 'da',
      iso3: 'dan'
    },
    {
      name: 'Dutch',
      iso1: 'nl',
      iso3: 'dut'
    },
    {
      name: 'English',
      iso1: 'en',
      iso3: 'eng'
    },
    {
      name: 'Finnish',
      iso1: 'fi',
      iso3: 'fin'
    },
    {
      name: 'French',
      iso1: 'fr',
      iso3: 'fre'
    },
    {
      name: 'German',
      iso1: 'de',
      iso3: 'ger'
    },
    {
      name: 'Hungarian',
      iso1: 'hu',
      iso3: 'hun'
    },
    {
      name: 'Italian',
      iso1: 'it',
      iso3: 'ita'
    },
    {
      name: 'Latvian',
      iso1: 'lv',
      iso3: 'lav'
    },
    {
      name: 'Lithuanian',
      iso1: 'lt',
      iso3: 'lit'
    },
    {
      name: 'Macedonian',
      iso1: 'mk',
      iso3: 'mac'
    },
    {
      name: 'Norwegian',
      iso1: 'no',
      iso3: 'nor'
    },
    {
      name: 'Polish',
      iso1: 'pl',
      iso3: 'pol'
    },
    {
      name: 'Romanian',
      iso1: 'ro',
      iso3: 'rum'
    },
    {
      name: 'Russian',
      iso1: 'ru',
      iso3: 'rus'
    },
    {
      name: 'Serbian',
      iso1: 'rs',
      iso3: 'srp'
    },
    {
      name: 'Slovak',
      iso1: 'sk',
      iso3: 'slo'
    },
    {
      name: 'Slovenian',
      iso1: 'si',
      iso3: 'slv'
    },
    {
      name: 'Spanish',
      iso1: 'es',
      iso3: 'spa'
    },
    {
      name: 'Swedish',
      iso1: 'sv',
      iso3: 'swe'
    }
  ];

  truckUrl: string;
  isTrailer = true;
  truckAlreadyAddedToCollation = true;
  isTablet = false;
  truckHasValidAge = false;
  fromHomePage: boolean;
  isNotFound = false;
  soldDialogRef: MatDialogRef<SoldPopupComponent, any>;

  truck: Truck;
  truckid: string;
  errorMessage: string;
  curLang: LocalesStateLangList;
  country: string;

  private s0: Subscription;
  private s1: Subscription;
  private s2: Subscription;
  private s3: Subscription;
  private s4: Subscription;
  vfsValues: number[] = [0, 0, 0];
  regionalSettings: RegionSettings;
  isSelectedSite: boolean = false;
  region: string;
  regionEnum=regionEnum;
  originalUrl: string; // variable to store the original URL
  isOtherCategory: boolean = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private globalUtils: GlobalUtils,
    private location: Location,
    private titleService: Title,
    private metaTagService: Meta,
    private trucksService: TrucksService,
    private searchService: SearchService,
    private localesService: LocalesService,
    private truckCollationService: TruckCollationService,
    private localizeRouterService: LocalizeRouterService,
    private translateService: TranslateService,
    private meta: Meta,
    private breadcrumbService: BreadcrumbService,
    private printService: PrintService,
    private http: HttpClient,
    private trans: TranslationService,
    private counterService: CounterService,
    store: Store,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,
    private breadCrumbUrlService: BreadCrumbUrlService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public assetService: AssetService
  ) {
    //log route data

    try {
      this.fromHomePage = this.router.getCurrentNavigation().extras.state.fromHome;
    } catch (error) {
      this.fromHomePage = true;
    }
    const region = store.selectSnapshot<string>((state: AppState) => state.language.region.curRegion.id);
    this.regionalSettings = store.selectSnapshot<RegionSettings>((state: AppState) => state.regionalSettings.regionsSettings.filter(r => r.region === region)[0]);
    this.region = this.regionalSettings?.region;
    if(this.regionalSettings?.site === 'volvoselected') 
      this.isSelectedSite = true;
    this.checkUrlParams();
    this.initSubscriptions();
    if (this.globalUtils.isBrowser()) {
      this.isTablet = ScreenUtils.isTablet();
    }

    // Store the original URL when the component is initialized
    this.originalUrl = this.router.url;
  }
  ngAfterViewInit(): void {
    this.truck = undefined;
    this.isNotFound = false;
  }
  ngOnInit(): void {
    this.updateBreadcrumb('');
    this.isPhone = ScreenUtils.isPhone() || ScreenUtils.isTablet();
    try {
      this.fromHomePage = history.state.fromHome;
    } catch (error) {
      this.fromHomePage = true;
      
    }

    this.meta.addTags([
      {
        name: 'keywords',
        content: 'Angular SEO Integration, Music CRUD, Angular Universal'
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Digamber Singh' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1'
      },
      { name: 'date', content: '2019-10-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
    this.getScreenSize();
  }

  ngOnDestroy(): void {
    this.trucksService.setActiveTruck(null);
    const breadcrumbsConts = [
      {
        label: 'Our Stock',
        url: ''
      },

    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbsConts);
  }

  openedDriversGuide() {
    this.counterService.postDriverGuide(this.truck.specification.chassisNumber);
  }

  goTo() {

    if (this.fromHomePage) {
      this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)]);
    } else {
      let searchParam = localStorage.getItem('searchParam');

      this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)]);
      this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)], {
        queryParams: { qf: searchParam }
      });
    }
  }


  onDetailsClick(): void {
    this.dialog.open(SuperstructureDetailsPopupComponent, {
      panelClass: 'volvo-popup',
      data: { product: this.truck }
    });
  }

  onPrintPage(): void {
    if (this.globalUtils.isBrowser()) {
      this.counterService.postPrintButton(this.truck.specification.chassisNumber);
      //window.print();
      this.printService.getTruckUrl(this.router.url, this.truckUrl.split("?")[1],this.originalUrl);
      this.printService.printDocument('printTruck', this.truck, this.curLang.id, this.hasEmptyTyreTread, this.hasEmptyDimensions, this.vfsValues, this.truckMakeModel, this.country);
      //this.addBreadCrumbClick(true,this.truckMakeModel);
    }
  }

  onShareTruck(): void {
    this.dialog.open(SharePopupComponent, {
      panelClass: 'volvo-popup',
      data: { truckUrl: this.truckUrl }
    });
  }

  // Feature disabled until new instructions
  addTruckToCollation(): void {
    this.truckCollationService.addTruck(this.truck);
  }

  private checkTruckAge(truck: Truck): void {
    const truckValidAgeInYears = 7;
    const productRegYear = new Date(truck.specification.dateFirstRegistration).getFullYear();
    const curYear = new Date().getFullYear();

    if (curYear - productRegYear <= truckValidAgeInYears) {
      this.truckHasValidAge = true;
    }
  }

  private setTruckSalesRep(truck: Truck): void {
    const cityLoc = truck.stockLocation.city;

    const phoneNumber =
      truck.salesperson && truck.salesperson.telephoneNumber
        ? truck.salesperson.telephoneNumber
        : truck.stockLocation.phoneNumber;

    if (truck.salesperson) {
      const phoneNumber =
        truck.salesperson && truck.salesperson.telephoneNumber
          ? truck.salesperson.telephoneNumber
          : truck.stockLocation.phoneNumber;

      this.sales = [
        {
          name: truck.salesperson ? truck.salesperson.salespersonName : truck.stockLocation.stockLocationName,
          info: this.translateService.instant('truck/salesRepTitle'.toLowerCase()),
          adress: truck.stockLocation.address,
          city: truck.stockLocation.city,
          email: truck.stockLocation.email,
          locationName: truck.stockLocation.stockLocationName,
          phone: phoneNumber,
          postalcode: truck.stockLocation.postalCode,
          isExpanded: true
        }
      ];
      this.salesPersons = this.sales;
      return;
    }

    this.salesUsers = [];

    if (truck.salespersons) {
      let counter = 0;
      truck.salespersons.forEach(item => {
        const phoneNumber =
          item.salespersonName && item.telephoneNumber ? item.telephoneNumber : truck.stockLocation.phoneNumber;

        if (!item.salespersonName) {
          return;
        }
        const salesPerson = {
          name: item.salespersonName ? item.salespersonName : truck.stockLocation.stockLocationName,
          info: this.translateService.instant('truck/salesRepTitle'.toLowerCase()),
          adress: truck.stockLocation.address,
          city: truck.stockLocation.city,
          email: item.email === '' ? truck.stockLocation.email : item.email,
          locationName: truck.stockLocation.stockLocationName,
          phone: phoneNumber,
          postalcode: truck.stockLocation.postalCode,
          isExpanded: counter === 0
        };
        counter++;
        this.salesUsers.push(salesPerson);
      });
      this.salesPersons = this.salesUsers;
      return;
    }
  }

  private initSubscriptions(): void {
    this.errorMessage = '';
    this.s0 = this.localesService.lang$.subscribe((lang: LocalesStateLang) => {
      this.curLang = lang.curLang;

      switch (this.curLang.id) {
        case 'be-fr':
        case 'ch-fr':
        case 'be-nl':
        case 'at':
        case 'ch-de':
        case 'ch-it':
        case 'ba':
        case 'rs':
        case 'dk':
        case 'cz':
        case 'se': this.selectedLangCode = this.curLang.apiResLang; break;
        case 'ee': this.selectedLangCode = 'en'; break;
        default: this.selectedLangCode = this.curLang.id;
      }
    });

    this.s1 = this.activatedRoute.params.subscribe(params => {
      if (params && params.id) {
        this.truckid = params.id;
        this.trucksService.getTruckById(params.id);
      }
    });

    this.s2 = this.searchService.filter$.subscribe((searchOptions: SearchStateFilter) => {
    });

    this.s3 = this.trucksService.truckById$.pipe(pairwise()).subscribe(
      ([truckById_previous, truckById]) => {
        if (truckById) {
          this.addBreadCrumbClick();
          this.truck = new Truck(truckById);
          this.hasEmptyTyreTread = this.checkEmptyTyreTread();
          this.hasEmptyDimensions = this.checkEmptyTyreDimensions();
          
          
          this.checkTruckAge(this.truck);
          this.setTruckSalesRep(this.truck);
          this.setTruckUrl();
          const CategorySemiTrailer = 'semi-trailer';
          const CategoryTrailer = 'trailer';
          const CategoryValue = this.truck.specification.category;
          this.checkVfsStatus(this.truck);

          this.isTrailer =
            CategoryValue.toLowerCase() === CategorySemiTrailer.toLowerCase() ||
            CategoryValue.toLowerCase() === CategoryTrailer.toLowerCase();
        }

        this.updateMetaData();
        if (this.truck) {
          this.openSoldPopup();
          const majorCategorySemiTrailer = 'semi-trailer';
          const majorCategoryTrailer = 'trailer';
          const majorCategoryValue = this.truck.specification.category.toLowerCase();
          const isTrailer =
            majorCategoryValue === majorCategorySemiTrailer || majorCategoryValue === majorCategoryTrailer;

          this.truckMakeModel = isTrailer
            ? `${this.truck.specification.make}`
            : `${this.truck.specification.make} ${this.truck.specification.model}` +
            ' ' +
            (`${this.truck.specification.horsePower}` === '0' ? '' : (`${this.truck.specification.make}` === 'Mercedes-Benz' || `${this.truck.specification.make}` === 'MAN') ? '' : `${this.truck.specification.horsePower}`);

          this.addBreadCrumbClick(true, this.truckMakeModel);
          if (this.truck.specification.advertisementTitle) {
            this.advertisementTitle = this.truck.specification.advertisementTitle;
          }
          if (
            (this.truck.specification.chassisNumber != null || this.truck.specification.chassisNumber != undefined) &&
            this.truck.specification.chassisNumber.replace(/\D/g, '').length >= 6 &&
            this.truck.specification.make.toLowerCase() === 'volvo' &&
            this.truck.specification.chassisNumber.replace(/[0-9]/g, '').length <= 2
          ) {
            let regx = /^[A-Z]+$/;
            if (this.truck.specification.chassisNumber.replace(/[0-9]/g, '').length === 1) {
              if (this.truck.specification.chassisNumber.substring(1, 0).match(regx)) {
                this.haschassisNumber = true;
              }
            } else if (this.truck.specification.chassisNumber.replace(/[0-9]/g, '').length === 2) {
              if (
                this.truck.specification.chassisNumber.substring(1, 0).match(regx) &&
                this.truck.specification.chassisNumber.substring(1, 2).match(regx)
              ) {
                this.haschassisNumber = true;
              }
            }

            if (this.truck.specification.chassisNumber.includes('-') && this.haschassisNumber) {
              this.truck.specification.chassisNumber = this.truck.specification.chassisNumber.replace('-', '');
            }
          } else {
            this.haschassisNumber = false;
          }
          if (this.truck.specification.comment) {
            this.comments = [];
            if (this.truck.specification.comment.translation) {
              if (this.truck.specification.comment.translation.length !== 0) {
                for (let i = 0; i <= this.truck.specification.comment.translation.length - 1; i++) {
                  var object = {
                    languageCodeIso3: this.getLangCode(
                      this.truck.specification.comment.translation[i].languageCodeIso3,
                    ),
                    name: this.langName,
                    comment: this.truck.specification.comment.translation[i].value
                  };
                  this.comments.push(object);
                }
                if (this.truck.specification.comment.engPhrase) {
                  var eng = {
                    languageCodeIso3: 'en',
                    name: 'English',
                    comment: this.truck.specification.comment.engPhrase
                  };
                  this.comments.push(eng);
                }
                let index = this.comments.findIndex(
                  x =>
                    x.languageCodeIso3 === this.truck.specification.licensePlateCountryCode.toLowerCase() ||
                    x.languageCodeIso3 === 'en'
                );
                if (index != -1 && index != 0) {
                  this.arraymove(this.comments, index, 0);
                }
                if (this.curLang.id !== 'en') {
                  for (let i = 0; i <= this.comments.length - 1; i++) {
                    this.getLangName(i);
                  }
                }
              }
            } else if (this.truck.specification.comment.engPhrase) {
              var eng1 = {
                languageCodeIso3: 'en',
                name: 'English',
                comment: this.truck.specification.comment.engPhrase
              };
              this.comments.push(eng1);
              if (this.curLang.id !== 'en') {
                this.getLangName(0);
              }
            }
            this.selectedLangCode = this.comments[0].languageCodeIso3;
          }
          
          if(this.truck.specification.category === 'Other'){
            this.isOtherCategory = true;
          }
            
        }
      }
    );

    this.s4 = this.localesService.country$.subscribe(res => {
      this.country = res.curCountry.id;
    });

  }

  checkVfsStatus(truck: Truck): void {
    if (truck) {
      var RegDate =
        this.truck.specification.dateFirstRegistration !== undefined
          ? new Date(this.truck.specification.dateFirstRegistration).getFullYear()
          : 100;
      if (this.truck.specification.make !== 'Volvo') {
        this.disableVFS = true;
      } else if (!this.truck.ShowPrice) {
        this.disableVFS = true;
      } else if (new Date().getFullYear() - RegDate >= 7) {
        this.disableVFS = true;
      } else if (this.isSelectedSite && (this.country === 'ba' || this.country === 'ru')) {
        this.disableVFS = true; // The country condition to be checked only for selected site
      } else if(this.truck.stockLocation?.countryCodeISO2?.toLowerCase() === 'ba' || this.truck.stockLocation?.countryCodeISO2?.toLowerCase() === 'ru'){
        this.disableVFS = true;
      } else {
        if (this.truck.pricing && this.truck.pricing.priceExclVatEuro < 1000) {
          this.disableVFS = true;
        }
      }

    }
  }

  checkEmptyTyreTread(): boolean {
    if (!this.truck.specification.tyreTread) {
      return true;
    }
    var result = this.truck.specification.tyreTread
      .map(
        r =>
          (r.WheelNo1 === 0 || r.WheelNo1 == undefined) &&
          (r.WheelNo2 === 0 || r.WheelNo2 == undefined) &&
          (r.WheelNo3 === 0 || r.WheelNo3 == undefined) &&
          (r.WheelNo4 === 0 || r.WheelNo4 == undefined)
      )
      .some(r => r === true);

    return result;
  }

  checkEmptyTyreDimensions(): boolean {
    if (!this.truck.specification.tyreTread) {
      return true;
    }

    var result = this.truck.specification.tyreTread
      .map(
        r =>
          (r.TyreDimension === 0 || r.TyreDimension === undefined) &&
          (r.TyreWidthMm === 0 || r.TyreWidthMm === undefined)
      )
      .some(r => r === true);
    return result;
  }

  updateMetaData() {
    if (this.truck) {
      this.titleService.setTitle(
        this.truck.MetaTitle + this.translateService.instant('truckpage/metaTitle'.toLowerCase())
      );
      this.metaTagService.updateTag({
        name: 'title',
        content: this.truck.MetaTitle + this.translateService.instant('truckpage/metaTitle'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'description',
        content: this.truck.MetaDescription + this.translateService.instant('truckpage/metaDescription'.toLowerCase())
      });
      this.metaTagService.updateTag({
        name: 'image',
        alt: 'alt',
        content: this.truck.MetaAltImage + this.translateService.instant('truckpage/altImage'.toLowerCase())
      });
    }
  }

  private setTruckUrl(): void {
    if (this.globalUtils.isBrowser() && this.truck) {
      const majorCategorySemiTrailer = 'semi-trailer';
      const majorCategoryTrailer = 'trailer';
      const majorCategoryValue = this.truck.specification.category.toLowerCase();
      const isTrailer = majorCategoryValue === majorCategorySemiTrailer || majorCategoryValue === majorCategoryTrailer;

      const params = isTrailer
        ? `${this.truck.specification.make}/trailer`
        : `${this.truck.specification.make}/${this.truck.specification.model}/${this.truck.specification.isElectric ? '' : this.truck.specification.horsePower}`;
      this.truckUrl = `${document.location.origin}${HtmlRoutePages.truck}/${this.truck.stockNumber
        }?make=${encodeURIComponent(params)}`;
      const shortUrl = `${this.curLang.id}${HtmlRoutePages.truck}/${this.truck.stockNumber}?make=${params}`;
      this.location.replaceState(shortUrl.replace(' ', '-'));

    }
  }

  getLangCode(id: string) {
    let code = this.langCodes.filter(e => e.iso1 === id || e.iso3 === id);
    this.langName = code[0].name;
    return code[0].iso1;
  }

  getLangName(i: number) {
    return new Promise(async (resolve, reject) => {
      const res = await lastValueFrom(this.trans.translateText('en', this.selectedLangCode, this.comments[i].name)) as any;
      this.comments[i].name = res.body;
      resolve(null);
    });
  }

  translateText(text: string, fromLang: string) {
    if (this.translatedText === '') {
      this.showProgress = true;

      this.trans.translateText(fromLang, this.selectedLangCode, text).subscribe(res => {
        this.translatedText = res.body;
        this.showProgress = false;
        this.counterService.postGoogleTranslateButton(fromLang, this.selectedLangCode);
      });
    }

    this.hasTranslate = true;
  }

  OnDropDownChange(text: string, fromLang: string) {
    this.translatedText = '';
    this.hasTranslate = false;
    this.translateText(text, fromLang);
  }

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  onSliderChange(vfsVal: any) {
    this.vfsValues[0] = vfsVal[0];
    this.vfsValues[1] = vfsVal[1];
    this.vfsValues[2] = vfsVal[2];
    this.vfsValues[3] = vfsVal[3];
  }

  private openSoldPopup(): void {
    if (this.truck.isDeleted) {
      this.soldDialogRef = this.dialog.open(SoldPopupComponent, {
        panelClass: 'volvo-popup-sold',
        closeOnNavigation: true,
        disableClose: true,
        data: { truckRemoved: false }
      });
    }
  }
  updateBreadcrumb(label: string): void {
    this.breadCrumbUrl = this.breadCrumbUrlService.getUrl();
    const breadcrumbsConts = [
      {
        label: 'Our Stock',
        url: this.localizeRouterService.translateRoute(HtmlRoutePages.search) as string
      },
      {
        label: '',
        url: ''
      }
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbsConts);
  }
  changeUrl() {
    if (this.breadCrumbUrl) {
      let queryString = this.breadCrumbUrl.split('=');
      if (queryString.length > 1) {
        let replacedString = queryString[1].replace(/%3D/gi, '=');
        this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)], {
          relativeTo: this.activatedRoute,
          queryParams: { qf: replacedString }
        });
      }
    }
    else {
      this.router.navigate([this.localizeRouterService.translateRoute(HtmlRoutePages.search)], {
        relativeTo: this.activatedRoute
      });
    }
  }
  addBreadCrumbClick(isSpan: boolean = false, text: string = '') {
    var result = document.getElementsByClassName("custom-bread-crumb");
    if (!isSpan) {
      var anchortag: any = result[0].getElementsByTagName('a');
      if (anchortag.length != 0) {
        anchortag[0].outerHTML = '<a _ngcontent-thl-c238="" class="ng-star-inserted" style="cursor:pointer;" onclick="return false;">Our Stock</a>'
        anchortag[0].addEventListener(
          'click',
          this.changeUrl.bind(this));
      }
    }
    else {
      var span = result[0].children[1].getElementsByTagName('span');
      if (span.length != 0) {
        span[0].outerHTML = text != "null" ? text : '';
      }
    }
  }
  private checkUrlParams(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams as SearchStateParams;
    const emailQueryParams = this.activatedRoute.snapshot.queryParams;
    if (emailQueryParams && emailQueryParams.email) {
      this.emailFlag = emailQueryParams.email;
      this.trucksService.setEmailFlag({ emailFlag: this.emailFlag });
    }
  }

  ngDoCheck() {
    // Set the initial container height
    this.adjustContainerHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenSize();
  }

  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('click', ['$event'])
  adjustContainerHeight() {
    const mainContainer = this.elementRef.nativeElement.querySelector('#ContentWp-left');
    const container = this.elementRef.nativeElement.querySelector('#inline-gallery-container');
    const image = this.elementRef.nativeElement.querySelector('img.lg-object.lg-image');
    
    if (image && mainContainer) {
      const renderHeight = mainContainer.offsetWidth / image.width * image.height + 175;
      this.renderer.setStyle(mainContainer, 'height', renderHeight + 'px');
      if (this.isPhone) {
        const renderHeight = mainContainer.offsetWidth / image.width * image.height + 100;
        this.renderer.setStyle(mainContainer, 'height', renderHeight + 'px');
      }
    }
  }

  onShareTruckMobile(): void{
    this.isShow = !this.isShow;
  }
  shareFaceBook(): void {
    if (this.globalUtils.isBrowser()) {
        const win = window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.truckUrl}`, '_blank');
        win.focus();
    }
  }
  shareLinkedIn(): void {
    if (this.globalUtils.isBrowser()) {
      const win = window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${this.truckUrl}`, '_blank');
      win.focus();
    }
  }
  shareWhatsApp(): void {
    if (this.globalUtils.isBrowser()) {
      const win = window.open(`https://api.whatsapp.com/send?phone=whatsappphonenumber&text=You%20might%20be%20interested%20in%20this%20truck%20I%20found%20on%20Volvo%20UK%20Used%20Trucks!%20%20%20${this.truckUrl}`, '_blank');
      win.focus();
    }
  }

  shareTwitter(): void {
    if (this.globalUtils.isBrowser()) {
        const win = window.open(`https://www.twitter.com/share?url=${this.truckUrl}`, '_blank');
      win.focus();
    }
  }

  logButtonClick(buttonType: string): void {
    var buttonClick = this.counterService.returnButtonClickObj(buttonType, this.truck.truckId);
    this.counterService.postEmailPhoneButtonClicks("Truck Ad", buttonClick);
  }

  onContactsClick(): void {
    this.logButtonClick("Send Message");
    this.dialog.open(EmailUsPopupComponent, {
      panelClass: 'volvo-popup-email',
      data: { truck: this.truck },
    });
  }

  togglePhone(): void {
    if (document.getElementById("phId")) {
      let phoneDiv = document.getElementById("phId");
      if (phoneDiv.className === "itemPhone showNumber")
        phoneDiv.className = "itemPhone hideNumber";
      else {
        phoneDiv.className = "itemPhone showNumber";
        this.logButtonClick("Phone number");
      }
    }
    if (this.isPhone)
      this.logButtonClick("Phone number");
  }
}
