<div *ngIf="isSelectedSite; else others">
  <i (click)="onClose()" class="Vfs-close material-icons">close</i>
  <div class="vfsPopUp">
    <div class="VolvoLogo vfsPopUp-logo">
      <img alt="Volvo Selected" [src]="assetService.getAssetUrl('assets/logo.png')" />
      <span class="vfsPopUp-logo-text">
            Selected
        
        <span class="vfsPopUp-logo-subText">
          Used Trucks
        </span>
      </span>
    </div>
    <div class="vfsPopUp-helpText">
      Let us help you to finance your next investment!
    </div>

    <div class="setDisplay">
      <div class="vfsPopUp-vfsCalc">
        <div style="display: flex;">
          <div class="vfsPopUp-vfsCalc-Image" *ngIf="data.truck?.pictures && data.truck.pictures[0]">
              <img [src]="data.truck.pictures[0].filenameSmall | volvoCdn | escapeUrl" arrowLazyImage
                [alt]="data.truck?.make" />
          </div>
          <div class="vfsPopUp-vfsCalc-textDetails">
            <div>
              {{ data.truck.specification.make | hasValue }}  {{ data.truck.specification.model | hasValue }}
            </div>
            <div style="display: flex;">
              <span>{{ data.truck?.specification.category === 'Trailer' ? ('specification/trailerId' | lowercase | translate) : ('specification/truckId' | lowercase | translate) }}: </span>
              <span>&nbsp;{{ data.truck.specification.licensePlate || '----' }}</span>
            </div>
            <div *ngIf="data.truck?.stockLocation?.region === 'ZA';else nonZA">
              <h5 *ngIf="data.truck?.pricing?.priceExclVat.value" class="vfs-EmailUs-SubTitle paddingTop5">
                {{ data.truck?.pricing?.priceExclVat.value | number: '1.0-0':'de' }} R
                <span *ngIf="data.truck.ShowPrice" class="MessageUs-infoVat ng-star-inserted CPrimary">{{ ' ' }}{{ 'truck/vat' | lowercase | translate }}
                </span>
              </h5>
            </div>
            <ng-template #nonZA>
              <div *ngIf="data.truck?.pricing?.priceExclVatEuro" class="email-EmailUs-SubTitle paddingTop5">
                {{ data.truck?.pricing?.priceExclVatEuro | number: '1.0-0':'de' }} €
                <span *ngIf="data.truck.ShowPrice" class="MessageUs-infoVat ng-star-inserted CPrimary">{{ ' ' }}{{ 'truck/vat' | lowercase | translate }}
                </span>
              </div>
            </ng-template>
            
            <div *ngIf="!data.truck?.ShowPrice" class="vfs-EmailUs-SubTitle CPrimary paddingTop5" style="font-size: 18px">
              {{ 'truck/askPrice' | lowercase | translate }}
            </div>
    
            <div *ngIf="data.truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.EUR && data.truck.pricing?.priceExclVat?.currencyIsoCode3 !== CurrencyCode.ZAR"
              class="vfsPopUp-vfsCalc-localPrice">
              <span *ngIf="data.truck.pricing?.priceExclVat?.value">
                {{ 'globalMiss/priceLocalCurrency' | lowercase | translate }}: {{ data.truck.pricing?.priceExclVat?.value | number: '1.0-0':'de'
                }} {{ data.truck.pricing?.priceExclVat?.currencyIsoCode3 }}
              </span>
            </div>
          </div>
        </div>

        <div class="vfsPopUp-vfsCalcDetails">
          <div class="marginBottom8 volvofontmedium1 CPrimary">{{ 'components/truckpagelease/title' | lowercase | translate }}</div>
          <div class="marginBottom8"style="display: flex;">
            <span> {{ 'components/truckPageLease/slider/titleA' | lowercase | translate }}:</span>
            <span class="CPrimary" style="white-space: nowrap;"> &nbsp; {{ leaseSliders.downPayment.value | number: '1.0-0':'de' }} {{ currencySymbol }} </span>
          </div>
          <div class="marginBottom8"style="display: flex;">
            <span> {{ 'components/truckPageLease/slider/titleB' | lowercase | translate }}:</span>
            <span class="CPrimary"> &nbsp; {{ leaseSliders.contractDuration.value }} {{ 'component/truckPageLease/months' | lowercase | translate }} </span>
          </div>
          <div style="display: flex;">
            <span> {{ 'components/truckPageLease/lease/title' | lowercase | translate }}:</span>
            <span class="CPrimary"> &nbsp; {{ totalFinancePayment | number: '1.0-0':'de' }} {{currencySymbol}}* </span>
          </div>
        </div>
        
        <div class="vfsPopUp-vfsCalcDisclaimer" *ngIf="!isPhone">
          *{{ 'components/truckpagelease/lease/info' | lowercase | translate }}
        </div>
        <div class="vfsPopUp-vfsCalcDisclaimer" *ngIf="isPhone">
          <p>
            <span *ngIf="!showFullText">*{{ 'components/truckpagelease/lease/inforeadless' | lowercase | translate }}</span>
            <span *ngIf="showFullText">
              *{{ 'components/truckpagelease/lease/info' | lowercase | translate }}
            </span>
            <a (click)="toggleReadMore()" class="read-more-less">
              {{ showFullText ? 'Read less' : 'Read more' }}
            </a>
          </p>
        </div>
      </div>
      <div class="vfsPopUp-company">
        <div class="vfsPopUp-title mobileMargin">{{ 'form/companyinformation' | lowercase | translate }}</div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="Vfs-input">
            <div>
              <div >
                <h6 class="Vfs-input-title">{{ 'form/companyName' | lowercase | translate }}</h6>
                <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch MatInputSearchVfs">
                  <input formControlName="companyName" matInput />
                </mat-form-field>
                <span class="inputerror" *ngIf="form.get('companyName').invalid && (form.get('companyName').touched || form.get('companyName').dirty || invalidInputs)">
                  *Required field
                </span>
              </div>

              <div>
                <h6 class="Vfs-input-title">{{ 'form/companyaddress' | lowercase | translate }} </h6>
                  <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch MatInputSearchVfs">
                    <input formControlName="companyAddress" matInput />
                  </mat-form-field>
                  <span class="inputerror" *ngIf="form.get('companyAddress').invalid && (form.get('companyAddress').touched || form.get('companyAddress').dirty || invalidInputs)">
                    *Required field
                  </span>
              </div>

              <div>
                <h6 class="Vfs-input-title">{{ 'form/country' | lowercase | translate }} </h6>
                <div class="Vfs-Input1">
                  <div class="Vfs-input-title-display" >
                    <div class="Vfs-Input1-countryDD">
                      <mat-select [(ngModel)]="currentCountry" formControlName="country" [placeholder]="'search/notification/country' | lowercase | translate">
                        <mat-option class="CPrimary" *ngFor="let country of countryDropDown" [value]="country.id">{{ country.label }} 
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="vfsPopUp-title marginTop20">{{ 'form/contactinformation' | lowercase | translate }}</div>
          <div class="Vfs-input">
            <div >
              <h6 class="Vfs-input-title">{{ 'form/fullname' | lowercase | translate }}</h6>
              <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch MatInputSearchVfs">
                <input formControlName="name" matInput />
              </mat-form-field>
              <span class="inputerror" *ngIf="form.get('name').invalid && (form.get('name').touched || form.get('name').dirty || invalidInputs)">
                *Required field
              </span>
            </div>

            <div>
              <h6 class="Vfs-input-title">{{ 'form/email' | lowercase | translate }} </h6>
                <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch MatInputSearchVfs">
                  <input formControlName="email" matInput />
                </mat-form-field>
                <span class="inputerror" *ngIf="form.get('email').invalid && (form.get('email').touched || form.get('email').dirty || invalidInputs)">
                  *Required field
                </span>
            </div>

            <div>
              <h6 class="Vfs-input-title">{{ 'form/phonenumber' | lowercase | translate }} </h6>
                <mat-form-field floatLabel="never" appearance="outline" class="MatInputSearch MatInputSearchVfs">
                  <input formControlName="phone" matInput />
                </mat-form-field>
                <span class="inputerror" *ngIf="form.get('phone').invalid && (form.get('phone').touched || form.get('phone').dirty || invalidInputs)">
                  *Required field
                </span>
            </div>
          </div>
          <div class="MatInputSearchVfs checkboxPadding" *ngIf = "isSelectedSite; else nonSelectedSites">
            <mat-checkbox (click)="recaptcha()" formControlName="privacy" [ngClass]="{'mat-checkbox-invalid': form.get('privacy').invalid && (form.get('privacy').touched || invalidInputs)}">
              <div class="Vfs-privacy tabPrivacy">
                {{ 'form/contactingVolvoPrivacyPolicy' | lowercase | translate }}
                <a class="privacyPolicy regularFont" href="https://www.volvogroup.com/en-en/privacy.html" target="_blank">
                  <span>{{ 'form/privacyPolicy' | lowercase | translate }}</span>
                </a>
              </div>
            </mat-checkbox>
          </div>
          <div class="recaptcha">
            <div class="Vfs-submit">
              <div class="submitdiv">
                <div *ngIf="showRecaptcha" style="display: inline-block">
                  <re-captcha (resolved)="resolved($event)" siteKey="{{ recaptchaToken }}" required></re-captcha>
                </div>
              </div>
              <button (click)="checkFormValidation()" type="submit" [ngClass]="(!form.invalid && reCaptchaRes) ? 'Vfs-submit-btnColor' : ''">
                {{ 'components/truckPageLease/requestQuote' | lowercase | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ng-template #others>
  <i (click)="onClose()" class="PopupOLD-close ReadMePopup-close material-icons paddingTop">close</i>

  <div class="ReadMePopup">
    Your interest and any related payments would be determined individually at which point you may choose to accept or reject. As a general rule for financing, a vehicle cannot be more than 8 years old at the end of the financing contract.
    <br><br>Finance for business users only. Any Finance subsequently provided shall be provided by Volvo Financial Services. Finance is subject to credit acceptance. Terms and conditions shall apply. 
    <br><br>Volvo Financial Services is a trading style of VFS Financial Services Limited, which is registered in Scotland with registration number SC092026 with its registered office at 9 Fifty Pitches Place, Cardonald Business Park, Glasgow G51 4GA. VFS Financial Services Limited is authorised and regulated by the FCA.
  </div>

</ng-template>
